//import getip from './useGetIP_API'   //アクセスするAPI
//import useGoAPI from './useGoapi'
import seisei_back from "./useGoapiV2";
import seisei_back_extra from "./useGoapiExtra";
import eneosPatorl from "./eneosPatorl";
const repositories = {
  //ここにリポジトリのリストを格納
  //api1: getip,
  //api2: useGoAPI,
  seisei_back: seisei_back,
  seisei_back_extra: seisei_back_extra,
  eneosPatorl: eneosPatorl,
  // gets :athrtRepository
};

export const RepositoryFactory = {
  get: (name) => repositories[name],
};

export default RepositoryFactory;
