<template>
  <div class="home">
    <p>在庫マスタ登録・新規</p>
    <v-row>
      <v-col align="right"><v-btn @click.once="back">戻る</v-btn></v-col>
    </v-row>
    <v-container cols="12">
      <div v-if="isLoading" align="center">
        <v-progress-circular
          :size="250"
          color="primary"
          indeterminate
          align="center"
        ></v-progress-circular>
      </div>
      <div v-else>
        <v-form ref="form" @submit.prevent>
          <v-row>
            <v-col cols="6" sm="6" md="4" lg="4">
              <v-menu
                v-model="data.Recodes.FID11488.modal"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="対象年月"
                    v-model="data.Recodes.FID11488.value"
                    :rules="[rules.required]"
                    background-color="white"
                    id="FID11488"
                    class="inputTime11488"
                    append-icon="mdi-calendar"
                    outlined
                    v-on="on"
                    readonly
                    hide-details="auto"
                  >
                  </v-text-field> </template
                ><v-date-picker
                  v-model="data.Recodes.FID11488.value"
                  no-title
                  scrollable
                  locale="ja-jp"
                  :day-format="(date) => new Date(date).getDate()"
                  type="month"
                  @input="data.Recodes.FID11488.modal = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" sm="6" md="4" lg="4">
              <v-text-field
                label="SSコード"
                v-model="data.Recodes.FID11486.value"
                :rules="[rules.required]"
                background-color="white"
                id="FID11486"
                class="textfield11486"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="4" lg="4">
              <v-text-field
                label="SS名"
                v-model="data.Recodes.FID11489.value"
                :rules="[rules.required]"
                background-color="white"
                id="FID11489"
                class="textfield11489"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row> </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <subPageStockProduct
                label=""
                background-color="white"
                id="FID11493"
                class="tableSubPage11493"
                ref="subPage11493"
                :set-sub-reports="data.Recodes.FID11493.subReports"
                :max="100"
                :min="1"
                @update:subReports="
                  (newData) => (data.Recodes.FID11493.subReports = newData)
                "
                hide-details="auto"
              >
              </subPageStockProduct>
            </v-col>
          </v-row>

          <v-row>
            <v-col align="left">
              <v-btn
                @click="deleteModal = true"
                :disabled="data.ReportID == null || disabled"
                color="red white--text"
                >削除
              </v-btn>
              <v-dialog v-model="deleteModal" max-width="400">
                <v-card>
                  <v-card-title class="text-h5"> 削除しますか？ </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="mr-3"
                      color="green darken-1 white--text"
                      @click="deleteModal = false"
                      large
                    >
                      いいえ
                    </v-btn>
                    <v-btn color="red darken-1 white--text" @click="del" large>
                      はい
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col v-if="showTemplate11501">
              <v-btn
                @click="save11501()"
                color="primary"
                fixed
                bottom
                :style="'right:+50px;'"
                >送信</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-container>
  </div>
</template>

<script>
import store from "../store/vuex.js";
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");

import subPageStockProduct from "../views/subPageStockProduct";

export default {
  name: "Home",
  components: {
    subPageStockProduct,
  },
  data() {
    return {
      isLoading: false,
      escapeValidation: false,
      showChild: true,
      subInit: false,
      disabled: false,
      data: {
        PageID: 1665,
        ReportID: null, //新規ならnull、更新ならidがある
        Recodes: {
          FID11488: { value: "", formType: 3, disabled: false, modal: false },
          FID11486: { value: "", formType: 1, disabled: false },
          FID11489: { value: "", formType: 1, disabled: false },
          FID11502: { value: "", formType: 1, disabled: false },
          FID11493: {
            value: "PID1666",
            formType: 17,
            disabled: false,
            subReports: [],
          },
          FID11501: { value: "", formType: 20, disabled: false },
        },
        DeleteSubReports: [],
      },
      duplicateCheckFID11488: "",
      duplicateCheckFID11486: "",

      rules: {
        required: (value) => !!value || "必須項目です",
      },
      deleteModal: false,
      copyFlag: false,
      sendCopyFlag: false,
      noBack: false,
      backCount: -1,
    };
  },
  async created() {
    await this.getInit();
  },

  computed: {
    showTemplate11501() {
      return true;
    },
  },
  watch: {
    $route() {
      this.getInit();
    },
  },
  methods: {
    async getInit() {
      try {
        //初期処理
        this.isLoading = true;
        //
        if (this.$route.query.copyFlag) {
          this.reportCopy();
        } else if (this.$route.params.report_id != null) {
          this.data.ReportID = Number(this.$route.params.report_id);
          //データ取得
          let data = {
            PageID: this.data.PageID,
            ReportID: Number(this.data.ReportID),
          };
          const result = await api.ReportDetailV2(data);
          this.RecodeCopy(this.data, result.Recodes);
          //初期処理
        }
        //データ取得処理

        //init処理
        this.initSetDeleteFlagSubPage11493();
        this.setDuplicateCheckFID11488();
        this.setDuplicateCheckFID11486();

        //読み込み終了
        this.isLoading = false;
      } catch (e) {
        console.log(e);
        alert("読み込みに失敗しました");
        this.back();
      }
    },
    RecodeCopy(data, response) {
      for (const [FID] of Object.entries(data.Recodes)) {
        if (typeof response[FID] != "undefined") {
          data.Recodes[FID].value = response[FID].value;
        }
        if (
          typeof response[FID] != "undefined" &&
          typeof response[FID].subReports != "undefined" &&
          response[FID].subReports != null
        ) {
          data.Recodes[FID].subReports = response[FID].subReports;
        }
      }
    },
    getDateTime() {
      let date = new Date();
      let years = date.getFullYear().toString();
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let days = date.getDate().toString().padStart(2, "0");
      let hours = date.getHours().toString().padStart(2, "0");
      let minutes = date.getMinutes().toString().padStart(2, "0");
      let seconds = date.getSeconds().toString().padStart(2, "0");
      //
      let value =
        years +
        "-" +
        month +
        "-" +
        days +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds;
      if (value == " ") {
        return "";
      }
      return value;
    },
    async save11501() {
      this.data.Recodes.FID11501.value = this.getDateTime();
      this.data.Recodes.FID11502.value = this.data.Recodes.FID11501.value;

      const duplicateCheckFlag = await this.searchReportData11501();
      if (
        (this.$route.params.report_id != null &&
          duplicateCheckFlag &&
          (this.duplicateCheckFID11488 != this.data.Recodes.FID11488.value ||
            this.duplicateCheckFID11486 != this.data.Recodes.FID11486.value)) ||
        (this.$route.params.report_id == null && duplicateCheckFlag)
      ) {
        alert("既に入力されています");
        return;
      }
      await this.send();
    },
    async searchReportData11501() {
      //
      let d = { PageID: this.data.PageID, orderBy: "", search: [] };
      //
      let isNullOrMatch11488 =
        this.data.Recodes.FID11488.value == "" ? "isNull" : "match";
      let isNullOrMatch11486 =
        this.data.Recodes.FID11486.value == "" ? "isNull" : "match";

      if (this.data.Recodes.FID11488.value != null) {
        d.search.push({
          form_id: 11488,
          value: this.data.Recodes.FID11488.value,
          option: isNullOrMatch11488,
        });
      }
      if (this.data.Recodes.FID11486.value != null) {
        d.search.push({
          form_id: 11486,
          value: this.data.Recodes.FID11486.value,
          option: isNullOrMatch11486,
        });
      }

      //apiからマスタデータの取得
      if (
        this.data.Recodes.FID11488.value != null &&
        this.data.Recodes.FID11486.value != null
      ) {
        try {
          let response = await api.ReportSearch(d);
          if (response.length !== 0) {
            return true;
          } else {
            return false;
          }
        } catch (e) {
          console.log("e", e);
          alert("通信エラーが発生しました");
        }
      }
      return true;
    },
    initSetDeleteFlagSubPage11493() {
      const subPageFID = "FID11493";
      if (
        typeof this.data.Recodes[subPageFID] == "undefined" ||
        !this.data.Recodes[subPageFID].subReports
      ) {
        return;
      }
      this.data.Recodes[subPageFID].subReports.forEach((item) => {
        if ("DeleteFlag" in item) return;
        this.$set(item, "DeleteFlag", false);
      });
    },
    setDuplicateCheckFID11488() {
      if (
        this.data.ReportID != null &&
        this.data.Recodes.FID11488.value != ""
      ) {
        this.duplicateCheckFID11488 = this.data.Recodes.FID11488.value;
      }
    },
    setDuplicateCheckFID11486() {
      if (
        this.data.ReportID != null &&
        this.data.Recodes.FID11486.value != ""
      ) {
        this.duplicateCheckFID11486 = this.data.Recodes.FID11486.value;
      }
    },
    sendBeforeDeleteSubPage11493() {
      const subPageFID = "FID11493";
      this.data.Recodes[subPageFID].subReports.forEach((item) => {
        if (item.DeleteFlag && item.ReportID) {
          this.data.DeleteSubReports.push(item);
        }
      });
      this.data.Recodes[subPageFID].subReports = this.data.Recodes[
        subPageFID
      ].subReports.filter((item) => !item.DeleteFlag);
    },

    checkValidation: async function () {
      let form = this.$refs.form;
      let check = form.validate();
      if (!check) {
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        return check;
      }

      return check;
    },
    async send() {
      const result = this.escapeValidation
        ? true
        : await this.checkValidation();

      if (result) {
        this.isLoading = true;
        this.sendBeforeDeleteSubPage11493();

        const save = await api.SendReport(this.data);
        if (save == false || save.result == "Fail") {
          alert("登録失敗[Error0830-1]");
          this.isLoading = false;
          return;
        }

        if (this.sendCopyFlag) {
          this.sendCopy();
          return;
        }
        alert("登録完了");
        if (!this.noBack) {
          this.back();
        } else {
          this.$set(this.$route.params, "report_id", save.reportID);
          this.$set(this.data, "ReportID", save.reportID);
          await this.getInit();
          this.isLoading = false;
          this.noBack = false;
        }
      }
    },
    back() {
      store.commit("clearreports");
      if (this.copyFlag) {
        history.go(this.backCount);
      } else {
        history.back();
      }
    },
    async del() {
      this.isLoading = true;
      await api.DelReport(this.data);
      alert("削除完了");
      this.back();
    },
  },
};
</script>
<style></style>
