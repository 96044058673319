<template>
  <v-card class="subPage" color="white">
    <div class="ma-1">
      <p></p>
      <div v-if="isLoading" align="center">
        <v-progress-circular
          :size="250"
          color="primary"
          indeterminate
          align="center"
        ></v-progress-circular>
      </div>
      <div v-else>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-textarea
                label="テキスト"
                v-model="data.Recodes.FID11980.value"
                :disabled="allDisabled11969"
                :background-color="inputColor(data.Recodes.FID11980)"
                id="FID11980"
                class="textfield11980"
                outlined
                rows="1"
                auto-grow
                hide-details="auto"
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="4" lg="4">
              <subPagePatrolReportCheckSpecialInputDate
                label=""
                background-color="white"
                id="FID11994"
                class=""
                :data="data"
                :mainRecodes="mainRecodes"
                :FIDData="data.Recodes.FID11981"
                @changeValue="(value) => (data.Recodes.FID11981.value = value)"
                hide-details="auto"
              >
              </subPagePatrolReportCheckSpecialInputDate>
            </v-col>
          </v-row>
          <v-row> </v-row>
          <v-row> </v-row>
        </v-form>
      </div>
    </div>
  </v-card>
</template>

<script>
import { RepositoryFactory } from "../api/RepositoryFactory";
import { spInputColor } from "./specialMethod";
const api = RepositoryFactory.get("seisei_back");

import subPagePatrolReportCheckSpecialInputDate from "../views/subPagePatrolReportCheckSpecialInputDate";

export default {
  name: "Home",
  components: {
    subPagePatrolReportCheckSpecialInputDate,
  },
  props: {
    //値
    Report: Object,
    subindex: Number,
    parentData: {
      type: Object,
      default: () => {},
      required: false,
    },
    parentSelectBoxDatas: {
      type: Object,
      default: () => {},
    },
    myInit: Boolean,
    FID: String,
    mainRecodes: { default: () => {} },
  },
  data() {
    return {
      isLoading: false,
      escapeValidation: false,
      showChild: true,
      subInit: false,
      disabled: false,
      data: {
        PageID: 1678,
        ReportID: null, //新規ならnull、更新ならidがある
        Recodes: {
          FID11980: { value: "", formType: 8, disabled: false },
          FID11981: { value: "", formType: 3, disabled: false, modal: false },
          FID12026: { value: "", formType: 1, disabled: false },
          FID11982: { value: "", formType: 1, disabled: false },
          FID11983: { value: "", formType: 1, disabled: false },
          FID12027: { value: "", formType: 1, disabled: false },
        },
        DeleteSubReports: [],
      },

      rules: {
        toDayAfter: (value) => {
          const today = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate(),
            new Date().getHours() + 9
          )
            .toISOString()
            .match(/^\d{4}-\d{2}-\d{2}/g)
            .pop();
          if (value <= today) {
            return "明日以降のみ";
          }
          return true;
        },
      },
      copyFlag: false,
      noBack: false,
    };
  },
  async created() {
    await this.getInit();
  },
  computed: {
    hint11981: function () {
      return "※1ヶ月以内のみ指定可能";
    },
    allDisabled11969() {
      let Recodes = this.mainRecodes;
      if (
        (Recodes.FID11772.value == "" || Recodes.FID11772.value == null ) &&
        (this.$store.state.role == 1 || this.$store.state.role == 2)
      ) {
        return false;
      }
      return true;
    },
  },
  watch: {
    $route() {
      this.getInit();
    },
    data: {
      handler: async function (data) {
        //
        if (
          data.Recodes.FID11983.value != "" ||
          (this.$store.state.role != 1 && this.$store.state.role != 2)
        ) {
          this.data.Recodes.FID11981.disabled = true;
        } else {
          this.data.Recodes.FID11981.disabled = false;
        }

        //
        this.$emit("clickSubmit", this.FID, this.subindex, data);
      },
      deep: true,
    },
  },
  methods: {
    async getInit() {
      try {
        //初期処理
        this.isLoading = true;
        //
        //データが存在する場合
        if (this.$route.query.copyFlag) {
          this.reportCopy();
        } else if (this.Report.ReportID != null || this.myInit) {
          //サブレポートの値代入
          this.data.ReportID = this.Report.ReportID;
          this.data.Recodes = api.OptionCopy(
            this.data.Recodes,
            this.Report.Recodes
          );
        } else {
          //初期値設定
        }
        //データ取得処理

        //init処理

        //初回データ反映
        this.$emit("clickSubmit", this.FID, this.subindex, this.data);
        //読み込み終了
        this.isLoading = false;
      } catch (e) {
        console.log(e);
        alert("読み込みに失敗しました");
        this.back();
      }
    },
    RecodeCopy(data, response) {
      for (const [FID] of Object.entries(data.Recodes)) {
        if (typeof response[FID] != "undefined") {
          data.Recodes[FID].value = response[FID].value;
        }
      }
    },
    async send() {
      const result = this.escapeValidation
        ? true
        : await this.checkValidation();
      if (result) {
        console.log("SendPage1678");
      }
    },

    checkValidation: async function () {
      let form = this.$refs.form;
      let check = form.validate();
      if (this.escapeValidation) {
        return true;
      }
      if (!check) {
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        return check;
      }
      return check;
    },
    async del() {
      await api.DelReport(this.data);
      alert("削除完了");
      this.back();
    },
    inputColor(field) {
      let data = {
        Recodes: this.mainRecodes
      }
      return spInputColor(data, field, this.allDisabled11969)
    },
  },
};
</script>
<style></style>
