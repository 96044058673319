<template>
  <v-row>
    <v-col>
      <v-data-table
        :headers="headers"
        :items="subReports"
        :items-per-page="itemsPerPage"
        :footer-props="{
          'items-per-page-text': '行/ページ:',
        }"
        disable-sort
        no-data-text="データが存在しません"
        hide-default-footer
        fixed-header
        mobile-breakpoint="0"
        class="fixed-column"
      >
        <template v-if="true" v-slot:footer>
          <div class="v-data-footer">
            <v-row align="center" justify="space-between">
              <v-col cols="2">
                <v-btn
                  @click="subPageAdd()"
                  :disabled="allDisabled11679"
                  :color="addBtnColor"
                  class="ma-2"
                  >{{ addBtnText }}
                </v-btn>
              </v-col>
              <v-col v-if="true" cols="2" class="my-3">
                {{ `登録件数: ${subPageCount}件` }}
              </v-col>
            </v-row>
          </div>
        </template>
        <template v-slot:item="{ item }" v-ripple>
          <tr>
            <!-- 商品コード -->
            <td v-if="typeof item.Recodes.FID11739 != 'undefined'" :style="getRowStyle(item)">
              <v-text-field
                v-if="item.Recodes.FID12221.value != ''"
                v-model="item.Recodes.FID11739.value"
                :disabled="item.DeleteFlag"
                readonly
                id="FID11739"
                class="textfield11739"
                hide-details="auto"
              ></v-text-field>
              <v-select
                v-else
                :value="item.Recodes.FID11739.value"
                :items="selectableProductCodes(item.Recodes.FID11739.value)"
                :disabled="item.DeleteFlag"
                id="FID11739"
                class="textfield11739"
                hide-details="auto"
                @change="changeProductCode($event, item)"
              ></v-select>
            </td>
            <td v-else></td>
            <!-- 商品名 -->
            <td v-if="typeof item.Recodes.FID11740 != 'undefined'" :style="getRowStyle(item)">
              <v-text-field
                v-if="item.Recodes.FID12221.value != ''"
                v-model="item.Recodes.FID11740.value"
                :disabled="item.DeleteFlag"
                readonly
                id="FID11740"
                class="textfield11740"
                hide-details="auto"
              ></v-text-field>
              <v-select
                v-else
                :value="item.Recodes.FID11740.value"
                :items="selectableProductNames(item.Recodes.FID11740.value)"
                :disabled="item.DeleteFlag"
                id="FID11740"
                class="textfield11740"
                hide-details="auto"
                @change="changeProductName($event, item)"
              ></v-select>
            </td>
            <td v-else></td>
            <!-- 前月繰越 -->
            <td v-if="typeof item.Recodes.FID11747 != 'undefined'" :style="getRowStyle(item)">
              <v-text-field
                v-model="item.Recodes.FID11747.value"
                :disabled="item.DeleteFlag"
                :readonly="item.Recodes.FID12221.value != ''"
                id="FID11747"
                class="textfield11747"
                hide-details="auto"
              ></v-text-field>
            </td>
            <td v-else></td>
            <!-- 入荷累計 -->
            <td v-if="typeof item.Recodes.FID11748 != 'undefined'" :style="getRowStyle(item)">
              <v-text-field
                v-if="item.Recodes.FID12221.value != ''"
                :value="addition11748(item)"
                :disabled="item.DeleteFlag"
                :readonly="item.Recodes.FID12221.value != ''"
                id="FID11748"
                class="textfield11748"
                hide-details="auto"
              ></v-text-field>
              <v-text-field
                v-else
                v-model="item.Recodes.FID11748.value"
                :disabled="item.DeleteFlag"
                :readonly="item.Recodes.FID12221.value != ''"
                id="FID11748"
                class="textfield11748"
                hide-details="auto"
              ></v-text-field>
            </td>
            <td v-else></td>
            <!-- 出荷累計 -->
            <td v-if="typeof item.Recodes.FID11749 != 'undefined'" :style="getRowStyle(item)">
              <v-text-field
                v-if="item.Recodes.FID12221.value != ''"
                :value="addition11749(item)"
                :disabled="item.DeleteFlag"
                :readonly="item.Recodes.FID12221.value != ''"
                id="FID11749"
                class="textfield11749"
                hide-details="auto"
              ></v-text-field>
              <v-text-field
                v-else
                v-model="item.Recodes.FID11749.value"
                :disabled="item.DeleteFlag"
                :readonly="item.Recodes.FID12221.value != ''"
                id="FID11749"
                class="textfield11749"
                hide-details="auto"
              ></v-text-field>
            </td>
            <td v-else></td>
            <!-- 現時点在庫 -->
            <td v-if="typeof item.Recodes.FID11741 != 'undefined'" :style="getRowStyle(item)">
              <v-text-field
                v-if="item.Recodes.FID12221.value != ''"
                :value="addition11741(item)"
                :disabled="item.DeleteFlag"
                :readonly="item.Recodes.FID12221.value != ''"
                id="FID11741"
                class="textfield11741"
                hide-details="auto"
              ></v-text-field>
              <v-text-field
                v-else
                v-model="item.Recodes.FID11741.value"
                :disabled="item.DeleteFlag"
                :readonly="item.Recodes.FID12221.value != ''"
                id="FID11741"
                class="textfield11741"
                hide-details="auto"
              ></v-text-field>
            </td>
            <td v-else></td>
            <!-- 実在庫 -->
            <td v-if="typeof item.Recodes.FID11742 != 'undefined'" style="background: #99FFCC !important;">
              <v-dialog
                v-model="item.Recodes.FID11742.modal"
                :max-width="resizeWidth"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs">
                    <v-text-field
                      v-model="item.Recodes.FID11742.value"
                      :disabled="item.DeleteFlag || allDisabled11679"
                      :rules="[rules.smallNum]"
                      id="FID11742"
                      class="textfield11742"
                      append-icon="mdi-calculator"
                      @click:append="on.click"
                      hide-details="auto"
                    ></v-text-field>
                  </div>
                </template>
                <input-Number
                  v-if="item.Recodes.FID11742.modal"
                  title="実在庫"
                  :value="item.Recodes.FID11742.value"
                  :rules="[rules.smallNum]"
                  v-on:ok="
                    (item.Recodes.FID11742.modal = false),
                    $set(item.Recodes.FID11742, 'value', $event)
                  "
                  type="calculator"
                  AC
                ></input-Number>
              </v-dialog>
            </td>
            <td v-else></td>
             <!-- 差異 -->
             <td v-if="typeof item.Recodes.FID11750 != 'undefined'" :style="getRowStyle(item)">
              <v-text-field
                :value="subtraction11750(item)"
                :disabled="item.DeleteFlag"
                id="FID11750"
                class="textfield11750"
                readonly
                hide-details="auto"
              ></v-text-field>
            </td>
            <td v-else></td>
            <!-- 規定内数量（8%±1L） -->
            <td v-if="typeof item.Recodes.FID11751 != 'undefined'" :style="getRowStyle(item)">
              <v-text-field
                :value="FID11751value(item)"
                :disabled="item.DeleteFlag"
                id="FID11751"
                class="textfield11751"
                hide-details="auto"
                readonly
              ></v-text-field>
            </td>
            <td v-else></td>
            <!-- 規定内数量（15L） -->
            <td v-if="typeof item.Recodes.FID11752 != 'undefined'" :style="getRowStyle(item)">
              <v-text-field
                :value="FID11752value(item)"
                :disabled="item.DeleteFlag"
                id="FID11752"
                class="textfield11752"
                hide-details="auto"
                readonly
              ></v-text-field>
            </td>
            <td v-else></td>
            <!-- 判定 -->
            <td v-if="typeof item.Recodes.FID11743 != 'undefined'" :style="getRowStyle(item)">
              <v-text-field
                :value="FID11743value(item)"
                :disabled="item.DeleteFlag"
                id="FID11743"
                class="textfield11743"
                hide-details="auto"
                readonly
              ></v-text-field>
            </td>
            <td v-else></td>
            <!-- 入荷 -->
            <td v-if="typeof item.Recodes.FID11744 != 'undefined'" style="background: #99FFCC !important;">
              <v-dialog
                v-model="item.Recodes.FID11744.modal"
                :max-width="resizeWidth"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs">
                    <v-text-field
                      v-model="item.Recodes.FID11744.value"
                      :disabled="item.DeleteFlag || allDisabled11679"
                      :rules="[rules.smallNum]"
                      id="FID11744"
                      class="textfield11744"
                      append-icon="mdi-calculator"
                      @click:append="on.click"
                      hide-details="auto"
                    ></v-text-field>
                  </div>
                </template>
                <input-Number
                  v-if="item.Recodes.FID11744.modal"
                  title="入荷"
                  :value="item.Recodes.FID11744.value"
                  :rules="[rules.smallNum]"
                  v-on:ok="
                    (item.Recodes.FID11744.modal = false),
                    $set(item.Recodes.FID11744, 'value', $event)
                  "
                  type="calculator"
                  AC
                ></input-Number>
              </v-dialog>
            </td>
            <td v-else></td>
            <!-- 出荷 -->
            <td v-if="typeof item.Recodes.FID11745 != 'undefined'" style="background: #99FFCC !important;">
              <v-dialog
                v-model="item.Recodes.FID11745.modal"
                :max-width="resizeWidth"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs">
                    <v-text-field
                      v-model="item.Recodes.FID11745.value"
                      :disabled="item.DeleteFlag || allDisabled11679"
                      :rules="[rules.smallNum]"
                      id="FID11745"
                      class="textfield11745"
                      append-icon="mdi-calculator"
                      @click:append="on.click"
                      hide-details="auto"
                    ></v-text-field>
                  </div>
                </template>
                <input-Number
                  v-if="item.Recodes.FID11745.modal"
                  title="出荷"
                  :value="item.Recodes.FID11745.value"
                  :rules="[rules.smallNum]"
                  v-on:ok="
                    (item.Recodes.FID11745.modal = false),
                    $set(item.Recodes.FID11745, 'value', $event)
                  "
                  type="calculator"
                  AC
                ></input-Number>
              </v-dialog>
            </td>
            <td v-else></td>
            <!-- 備考 -->
            <td v-if="typeof item.Recodes.FID11746 != 'undefined'" :style="getRowStyle(item)">
              <v-text-field
                v-model="item.Recodes.FID11746.value"
                :disabled="item.DeleteFlag"
                id="FID11746"
                class="textfield11746"
                hide-details="auto"
              ></v-text-field>
            </td>
            <td v-else></td>
            <!-- OTB（非表示） -->
            <!-- <td v-if="typeof item.Recodes.FID11820 != 'undefined'">
              <v-text-field
                v-model="item.Recodes.FID11820.value"
                :disabled="item.DeleteFlag"
                id="FID11820"
                class="textfield11820"
                readonly
                hide-details="auto"
              ></v-text-field>
            </td>
            <td v-else></td> -->
            <!-- 判定数値（非表示） -->
            <!-- <td v-if="typeof item.Recodes.FID11821 != 'undefined'">
              <v-text-field
                :value="FID11821value(item)"
                :disabled="item.DeleteFlag"
                id="FID11821"
                class="textfield11821"
                readonly
                hide-details="auto"
              ></v-text-field>
            </td>
            <td v-else></td> -->

            <td v-if="buttonShow" :style="getRowStyle(item)">
              <v-btn
                @click="subPageDel(item)"
                :color="item.DeleteFlag ? restoreBtnColor : deleteBtnColor"
                :disabled="(!item.DeleteFlag && min >= subPageCount) || !!(item.Recodes.FID12221.value)"
                >{{ item.DeleteFlag ? restoreBtnText : deleteBtnText }}
              </v-btn>
            </td>
            <td v-else></td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import inputNumber from "../views/inputNumber";
import { getMasterData } from "./specialMethod";

export default {
  name: "subPageTable",
  components: {
    inputNumber,
  },
  props: {
    setSubReports: {
      type: Array,
      required: true,
    },
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      data: {
        PageID: 1670,
        ReportID: null,
        Recodes: {
          FID11739: { value: "", formType: 1, disabled: false }, //商品コード
          FID11740: { value: "", formType: 1, disabled: false }, //商品名
          FID11741: { value: "0", formType: 1, disabled: false }, //現時点在庫
          FID11742: { value: "0", formType: 1, disabled: false, modal: false }, //実在庫
          FID11743: { value: "", formType: 1, disabled: false }, //判定
          FID11744: { value: "0", formType: 1, disabled: false, modal: false }, //入荷
          FID11745: { value: "0", formType: 1, disabled: false, modal: false }, //出荷
          FID11746: { value: "", formType: 1, disabled: false }, //備考
          FID11747: { value: "0", formType: 1, disabled: false }, //前月繰越
          FID11748: { value: "0", formType: 1, disabled: false }, //入荷累計
          FID11749: { value: "0", formType: 1, disabled: false }, //出荷累計
          FID11750: { value: "0", formType: 1, disabled: false }, //差異
          FID11751: { value: "0", formType: 1, disabled: false }, //規定内数量（8%±1L）
          FID11752: { value: "0", formType: 1, disabled: false }, //規定内数量（15L）
          FID11820: { value: "", formType: 1, disabled: false }, //OTB（非表示）
          FID11821: { value: "", formType: 1, disabled: false }, //判定数値（非表示）
          FID12221: { value: "", formType: 1, disabled: false }, // マスタ取得判定フラグ
        },
        DeleteFlag: false,
        DeleteSubReports: [],
      },
      products: [],
      PageID: 1670,
      subReports: [],
      buttonShow: true,
      addBtnText: "追加",
      addBtnColor: "primary",
      deleteBtnText: "削除",
      deleteBtnColor: "red white--text",
      restoreBtnText: "復元",
      restoreBtnColor: "green white--text",
      itemsPerPage: -1,
      rules: {
        smallNum: (value) => {
          return /^(\d*.?\d+)*$/.test(value) || "数値のみ入力可能です。";
        },
      },
    };
  },
  async created() {
    await this.getInit();
  },
  computed: {
    headers() {
      return [
        {
          text: "商品ｺｰﾄﾞ",
          value: "Recodes.FID11739.value",
          align: "start",
          sortable: false,
          width: window.innerWidth<800?"40":"60",
        },
        {
          text: "商品名",
          value: "Recodes.FID11740.value",
          align: "start",
          sortable: false,
          width: window.innerWidth<800?"130":"200",
        },
        {
          text: "前月繰越",
          value: "Recodes.FID11747.value",
          align: "start",
          sortable: false,
          width: window.innerWidth<800?"40":"60",
        },
        {
          text: "入荷累計",
          value: "Recodes.FID11748.value",
          align: "start",
          sortable: false,
          width:"60",
        },
        {
          text: "出荷累計",
          value: "Recodes.FID11749.value",
          align: "start",
          sortable: false,
          width:"60",
        },
        {
          text: "現時点在庫",
          value: "Recodes.FID11741.value",
          align: "start",
          sortable: false,
          width:"70",
        },
        {
          text: "実在庫",
          value: "Recodes.FID11742.value",
          align: "start",
          sortable: false,
          width:"80",
        },
        {
          text: "差異",
          value: "Recodes.FID11750.value",
          align: "start",
          sortable: false,
          width:"70",
        },
        {
          text: "規定内数量（8%±1L）",
          value: "Recodes.FID11751.value",
          align: "start",
          sortable: false,
          width:"70",
        },
        {
          text: "規定内数量（15L）",
          value: "Recodes.FID11752.value",
          align: "start",
          sortable: false,
          width:"70",
        },
        {
          text: "判定",
          value: "Recodes.FID11743.value",
          align: "start",
          sortable: false,
          width:"20",
        },
        {
          text: "入荷",
          value: "Recodes.FID11744.value",
          align: "start",
          sortable: false,
          width:"80",
        },
        {
          text: "出荷",
          value: "Recodes.FID11745.value",
          align: "start",
          sortable: false,
          width:"80",
        },
        {
          text: "備考",
          value: "Recodes.FID11746.value",
          align: "start",
          sortable: false,
          width:"120",
        },
        // {
        //   text: "OTB（非表示）",
        //   value: "Recodes.FID11820.value",
        //   align: "start",
        //   sortable: false,
        //   width:"20",
        // },
        // {
        //   text: "判定数値（非表示）",
        //   value: "Recodes.FID11821.value",
        //   align: "start",
        //   sortable: false,
        //   width:"20",
        // },
        { text: "", value: "Del", sortable: false },
      ];
    },
    //商品情報のサブページ登録数取得
    subPageCount: {
      cache: false,
      get() {
        return this.subReports.filter((item) => !item.DeleteFlag).length;
      },
    },
    //サイズ変更
    resizeWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "270px";
        case "sm":
          return "500px";
        case "md":
          return "600px";
        case "lg":
          return "500px";
        case "xl":
          return "500px";
        default:
          return "500px";
      }
    },
    //選択可能な商品
    selectableProducts() {
      let selectableProducts = this.products.filter((product) => {
        // すでに登録されている商品は選択できないようにする
        return !this.subReports.some(
          (subReport) => subReport.Recodes.FID11739.value === product.code
        );
      });
      selectableProducts = selectableProducts.filter((product) => {
        // OTBがタイヤ,バッテリーのみ選択可
        return product.otb == "タイヤ" || product.otb == "バッテリー";
      });
      return selectableProducts;
    },
    allDisabled11679() {
      // 管理者とグループ管理者は入力可能
      if (this.$store.state.role == 1 || this.$store.state.role == 2) {
        return false;
      }
      return true;
    },
  },
  watch: {
    $route() {
      this.getInit();
    },
    subReports: {
      handler: async function (newData) {
        this.setColumnWidths();
        this.$emit("update:subReports", newData);
      },
      deep: true,
    },
  },
  methods: {
    async getInit() {
      this.initSubPage();
      const productReports = await getMasterData.productReports();
      this.products = productReports.map((report) => {
        return {
          code: report.Recodes.FID11896.value,
          name: report.Recodes.FID11897.value,
          otb: report.Recodes.FID11898.value,
        };
      });
      this.setColumnWidths();
    },
    initSubPage() {
      this.setSubReports.forEach((item) => this.subReports.push(item));
      if (this.subReports.length < this.min) {
        for (let i = 0; i < this.min; i++) {
          if (!this.subReports[i]) {
            this.subReports.push(JSON.parse(JSON.stringify(this.data)));
          }
        }
      }
    },
    subPageAdd(index) {
      if (typeof index == "undefined") {
        index = this.subReports.length;
      }
      if (this.subReports.length < this.max) {
        this.subReports.splice(index, 0, JSON.parse(JSON.stringify(this.data)));
      } else {
        alert(`追加は最大${this.max}件までです`);
      }
    },
    subPageDel(item) {
      this.buttonShow = false;
      item.DeleteFlag = !item.DeleteFlag;
      this.buttonShow = true;
    },
    //入荷累計
    addition11748(item) {
      const nowArrival = Number(item.originArrival)
      const arrival =
        typeof item.Recodes.FID11744.value == "undefined" ||
        isNaN(item.Recodes.FID11744.value)
          ? 0
          : Number(item.Recodes.FID11744.value);
      const total = nowArrival + arrival;
      this.total11748(total, item);
      return total;
    },
    total11748(value, item) {
      item.Recodes.FID11748.value = String(value);
    },
    //出荷累計
    addition11749(item) {
      const nowShipping = Number(item.originShipping)
      const shipping =
        typeof item.Recodes.FID11745.value == "undefined" ||
        isNaN(item.Recodes.FID11745.value)
          ? 0
          : Number(item.Recodes.FID11745.value);
      const total = nowShipping + shipping;
      this.total11749(total, item);
      return total;
    },
    total11749(value, item) {
      item.Recodes.FID11749.value = String(value);
    },
    //現時点在庫（前月繰越＋入荷累計ー出荷累計）
    addition11741(item) {
      // 前月繰越
      const carryOver =
          typeof item.Recodes.FID11747.value == "undefined" ||
          isNaN(item.Recodes.FID11747.value)
            ? 0
            : Number(item.Recodes.FID11747.value);
      // 入荷累積
      const arrival =
        typeof item.Recodes.FID11748.value == "undefined" ||
        isNaN(item.Recodes.FID11748.value)
          ? 0
          : Number(item.Recodes.FID11748.value);
      // 出荷累積
      const shippment =
        typeof item.Recodes.FID11749.value == "undefined" ||
        isNaN(item.Recodes.FID11749.value)
          ? 0
          : Number(item.Recodes.FID11749.value);
      const total = carryOver + arrival - shippment;
      this.total11741(total, item);
      return total;
    },
    total11741(value, item) {
      item.Recodes.FID11741.value = String(value);
    },
    //差異
    subtraction11750(item) {
      const nowStock =
        typeof item.Recodes.FID11742.value == "undefined" ||
        isNaN(item.Recodes.FID11742.value)
          ? 0
          : Number(item.Recodes.FID11742.value);
      const stock =
        typeof item.Recodes.FID11741.value == "undefined" ||
        isNaN(item.Recodes.FID11741.value)
          ? 0
          : Number(item.Recodes.FID11741.value);
      const total = nowStock - stock;
      this.total11750(total, item);
      return total;
    },
    total11750(value, item) {
      item.Recodes.FID11750.value = String(value);
    },
    //規定内数量（8%±1L）
    FID11751value(item) {
      let num = 0
      if (item.Recodes.FID11820.value === "オイル") {
        num = Number(item.Recodes.FID11749.value) * 0.08 + 1
      }
      item.Recodes.FID11751.value = String(num);
      return item.Recodes.FID11751.value
    },
    //規定内数量（15L）
    FID11752value(item) {
      item.Recodes.FID11752.value = item.Recodes.FID11820.value === "オイル" ? "15" : "0"
      return item.Recodes.FID11752.value
    },
    //判定数値
    FID11821value(item) {
      let a = 0
      let b = 0
      let total = 0
      // 差異が規定内数量（8%±1L）より大きい場合
      let sai = Number(item.Recodes.FID11750.value)
      if (sai < 0) {
        sai = sai * -1
      }
      if (sai > Number(item.Recodes.FID11751.value)) {
        a = 1
      }
      // 差異が規定内数量（15L）より大きい場合
      if (sai > Number(item.Recodes.FID11752.value)) {
        b = 1
      }
      if (item.Recodes.FID11820.value === "オイル") {
        total = a + 10 * b
      } else {
        const c = (a + b) > 0 ? 1 : 0
        total = 1 * c
      }
      item.Recodes.FID11821.value = String(total);
      return item.Recodes.FID11821.value
    },
    //判定
    FID11743value(item) {
      let value = "〇"
      if (item.Recodes.FID11821.value === "11") {
        value = "×2"
      } else if (item.Recodes.FID11821.value === "10") {
        value = "×1"
      } else if (
        item.Recodes.FID11821.value === "1" ||
        item.Recodes.FID11750.value !== "0"
      ) {
        value = "×"
      }
      item.Recodes.FID11743.value = value;
      return item.Recodes.FID11743.value
    },
    // 各列の幅を設定
    setColumnWidths() {
      // v-selectで横幅が小さいと中の文字が...で省略されるので横幅を追加
      const addWidthVSelect = (item) => {
        // 追加されたサブレポートは横幅を追加
        return (!item.Recodes || !item.Recodes.FID12221 || !item.Recodes.FID12221.value) ? 25 : 0;
      };
      // 電卓アイコンの幅を追加
      const addWidthCalculator = () => {
        return 20;
      };
      this.setColumnWidth("FID11739", this.headers[0], addWidthVSelect); //商品コード
      // this.setColumnWidth("FID11740", this.headers[1], addWidthVSelect); //商品名
      this.setColumnWidth("FID11747", this.headers[2]); //前月繰越
      this.setColumnWidth("FID11748", this.headers[3]); //入荷累計
      this.setColumnWidth("FID11749", this.headers[4]); //出荷累計
      this.setColumnWidth("FID11741", this.headers[5]); //現時点在庫
      this.setColumnWidth("FID11742", this.headers[6], addWidthCalculator); //実在庫
      this.setColumnWidth("FID11750", this.headers[7]); //差異
      this.setColumnWidth("FID11751", this.headers[8]); //規定内数量（8%±1L）
      this.setColumnWidth("FID11752", this.headers[9]); //規定内数量（15L）
      this.setColumnWidth("FID11743", this.headers[10]); //判定
      this.setColumnWidth("FID11744", this.headers[11], addWidthCalculator); //入荷
      this.setColumnWidth("FID11745", this.headers[12], addWidthCalculator); //出荷
      this.setColumnWidth("FID11746", this.headers[13]); //備考
      // this.setColumnWidth("FID11820", this.headers[14]); //OTB（非表示）
      // this.setColumnWidth("FID11821", this.headers[15]); //判定数値（非表示）
    },
    // 列の最大幅を計算し、列の幅を設定
    setColumnWidth(FID, header, addWidthFunction = () => { return 0 }) {
      let ctx = document.createElement('canvas').getContext('2d');
      let maxwidth = 0; //列の最大幅
      this.subReports.forEach(subReport => {
        const addWidth = addWidthFunction(subReport, maxwidth); //フォームによっては追加幅を設定
        const width = ctx.measureText(subReport.Recodes[FID].value).width + addWidth;
        if (maxwidth < width) {
          maxwidth = width;
        }
      })
      switch (true) {
        case maxwidth < 12:
          header.width = 60;
          break;
        case maxwidth < 21:
          header.width = maxwidth * 5;
          break;
        case 21 <= maxwidth && maxwidth < 31:
          header.width = maxwidth * 3;
          break;
        case 31 <= maxwidth && maxwidth < 41:
          header.width = maxwidth * 2.5;
          break;
        case 41 <= maxwidth && maxwidth < 71:
          header.width = maxwidth * 2.3;
          break;
        case 71 <= maxwidth && maxwidth < 170:
          header.width = maxwidth * 2;
          break;
        case 170 <= maxwidth:
          header.width = maxwidth * 1.8;
          break;
        default:
          break;
      }
    },
    selectableProductCodes(value) {
      const codes = this.selectableProducts.map((product) => product.code);
      if (value) {
        codes.push(value);
      }
      return codes;
    },
    selectableProductNames(value) {
      const names = this.selectableProducts.map((product) => product.name);
      if (value) {
        names.push(value);
      }
      return names;
    },
    setProduct(product, item) {
      let code = "";
      let name = "";
      let otb = "";
      if (product) {
        code = product.code;
        name = product.name;
        otb = product.otb;
      }
      item.Recodes.FID11739.value = code;
      item.Recodes.FID11740.value = name;
      item.Recodes.FID11820.value = otb;
    },
    // 商品コードを選択した場合に商品情報をセット
    changeProductCode(code, item) {
      const product = code ? this.products.find((product) => product.code == code) : null;
      this.setProduct(product, item);
    },
    // 商品名を選択した場合に商品情報をセット
    changeProductName(name, item) {
      const product = name ? this.products.find((product) => product.name == name) : null;
      this.setProduct(product, item);
    },
    getRowStyle(item) {
      if (item.Recodes.FID11743.value != '〇') {
        return "background: pink !important;";
      } else {
        return "";
      }
    },
  },
};
</script>
<style scoped>
.title-v-text-field.v-text-field >>> .v-input__slot::before {
  border-style: none !important;
}
.title-v-text-field.v-text-field >>> .v-input__slot::after {
  border-style: none !important;
}

.fixed-column ::v-deep td,
th {
  min-width: 100px;
}
.fixed-column ::v-deep th:nth-child(1) {
  position: sticky;
  left: 0;
  z-index: 3;
  /* background-color: white; */
  max-width: 100px;
  word-break: break-all;
}
.fixed-column ::v-deep td:nth-child(1) {
  position: sticky;
  left: 0;
  z-index: 1;
  /* background-color: white; */
  max-width: 100px;
  word-break: break-all;
}
.fixed-column ::v-deep th:nth-child(2) {
  position: sticky;
  left: 100px;
  z-index: 3;
  /* background-color: white; */
  max-width: 200px;
  word-break: break-all;
}
.fixed-column ::v-deep td:nth-child(2) {
  position: sticky;
  left: 100px;
  z-index: 1;
  /* background-color: white; */
  max-width: 200px;
  word-break: break-all;
}
.fixed-column ::v-deep th:nth-child(3) {
  position: sticky;
  left: 300px;
  z-index: 3;
  /* background-color: white; */
  max-width: 100px;
  word-break: break-all;
}
.fixed-column ::v-deep td:nth-child(3) {
  position: sticky;
  left: 300px;
  z-index: 1;
  /* background-color: white; */
  max-width: 100px;
  word-break: break-all;
}
@media (max-width: 800px) {
  .fixed-column ::v-deep td,
  th {
    min-width: 40px;
  }
  .fixed-column ::v-deep th:nth-child(1) {
    position: sticky;
    left: 0;
    z-index: 3;
    /* background-color: white; */
    max-width: 40px;
    word-break: break-all;
  }
  .fixed-column ::v-deep td:nth-child(1) {
    position: sticky;
    left: 0;
    z-index: 1;
    /* background-color: white; */
    max-width: 40px;
    word-break: break-all;
  }
  .fixed-column ::v-deep th:nth-child(2) {
    position: sticky;
    left: 40px;
    z-index: 3;
    /* background-color: white; */
    max-width: 110px;
    width: 130px !important;
    word-break: break-all;
  }
  .fixed-column ::v-deep td:nth-child(2) {
    position: sticky;
    left: 40px;
    z-index: 1;
    /* background-color: white; */
    max-width: 110px;
    width: 130px !important;
    word-break: break-all;
  }
  .fixed-column ::v-deep th:nth-child(3) {
    position: sticky;
    left: 150px;
    z-index: 3;
    /* background-color: white; */
    max-width: 40px;
    word-break: break-all;
  }
  .fixed-column ::v-deep td:nth-child(3) {
    position: sticky;
    left: 150px;
    z-index: 1;
    /* background-color: white; */
    max-width: 40px;
    word-break: break-all;
  }
  .v-text-field >>> input {
    font-size: 0.85rem;
    padding: 0 !important;
  }
  .v-select >>> input {
    font-size: 0.85rem;
    padding: 0 !important;
  }
  .v-input {
    font-size: 0.85rem;
    padding: 0 !important;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    padding: 0 5px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    padding: 0 5px;
  }
}

/* ホバーエフェクトを削除 */
.v-data-table tr:hover td {
  background-color: white;
}
.v-data-table td {
  background-color: white;
}
</style>
<style>
@media (max-width: 800px) {
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    padding: 0 5px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    padding: 0 5px;
  }
}
</style>