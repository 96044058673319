<template>
  <div class="home">
    <p>商品マスタ登録・更新</p>
    <v-row>
      <v-col align="right"><v-btn @click.once="back">戻る</v-btn></v-col>
    </v-row>
    <v-container cols="12">
      <div v-if="isLoading" align="center">
        <v-progress-circular
          :size="250"
          color="primary"
          indeterminate
          align="center"
        ></v-progress-circular>
      </div>
      <div v-else>
        <v-form ref="form" @submit.prevent>
          <v-row>
            <v-col cols="4" sm="4" md="4" lg="4">
              <v-text-field
                label="商品コード"
                v-model="data.Recodes.FID11896.value"
                :rules="[rules.required]"
                background-color="white"
                id="FID11896"
                class="textfield11896"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4" sm="4" md="4" lg="4">
              <v-text-field
                label="商品名"
                v-model="data.Recodes.FID11897.value"
                :rules="[rules.required]"
                background-color="white"
                id="FID11897"
                class="textfield11897"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="4" lg="4">
              <v-dialog
                v-model="data.Recodes.FID12213.modal"
                :max-width="resizeWidth"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs">
                    <v-text-field
                      label="売上分類"
                      v-model="data.Recodes.FID12213.value"
                      :rules="[
                        rules.required,
                        rules.smallNum,
                        rules.customValidation12213,
                      ]"
                      background-color="white"
                      id="FID12213"
                      class="textfield12213"
                      append-icon="mdi-calculator"
                      @click:append="on.click"
                      outlined
                      hide-details="auto"
                    >
                    </v-text-field></div></template
                ><input-Number
                  v-if="data.Recodes.FID12213.modal"
                  title="売上分類"
                  :value="data.Recodes.FID12213.value"
                  :rules="[rules.required, rules.smallNum]"
                  v-on:ok="
                    (data.Recodes.FID12213.modal = false),
                      $set(data.Recodes.FID12213, 'value', $event)
                  "
                  type="calculator"
                  AC
                ></input-Number>
              </v-dialog>
            </v-col>
            <v-col cols="4" sm="4" md="4" lg="4">
              <v-autocomplete
                label="OTB"
                v-model="data.Recodes.FID11898.value"
                :rules="[rules.required, rules.containBox11898]"
                background-color="white"
                id="FID11898"
                class="selectBox11898"
                outlined
                no-data-text="データが見つかりません"
                clearable
                :items="selectBoxFID11898"
                hide-details="auto"
              >
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col align="left">
              <v-btn
                @click="deleteModal = true"
                :disabled="data.ReportID == null || disabled"
                color="red white--text"
                >削除
              </v-btn>
              <v-dialog v-model="deleteModal" max-width="400">
                <v-card>
                  <v-card-title class="text-h5"> 削除しますか？ </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="mr-3"
                      color="green darken-1 white--text"
                      @click="deleteModal = false"
                      large
                    >
                      いいえ
                    </v-btn>
                    <v-btn color="red darken-1 white--text" @click="del" large>
                      はい
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col v-if="showTemplate11899">
              <v-btn
                @click="save11899()"
                color="primary"
                fixed
                bottom
                :style="'right:+50px;'"
                >送信</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-container>
  </div>
</template>

<script>
import store from "../store/vuex.js";
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");

import inputNumber from "../views/inputNumber";

export default {
  name: "Home",
  components: {
    inputNumber,
  },
  data() {
    return {
      isLoading: false,
      escapeValidation: false,
      showChild: true,
      subInit: false,
      disabled: false,
      data: {
        PageID: 1676,
        ReportID: null, //新規ならnull、更新ならidがある
        Recodes: {
          FID11896: { value: "", formType: 1, disabled: false },
          FID11897: { value: "", formType: 1, disabled: false },
          FID12213: { value: "", formType: 1, disabled: false, modal: false },
          FID11898: { value: "", formType: 2, disabled: false },
          FID11899: { value: "", formType: 20, disabled: false },
        },
        DeleteSubReports: [],
      },
      selectBoxFID11898: ["オイル", "タイヤ", "バッテリー", "その他"],
      duplicateCheckFID11896: "",

      rules: {
        required: (value) => !!value || "必須項目です",
        smallNum: (value) => {
          return /^(\d*.?\d+)*$/.test(value) || "数値のみ入力可能です。";
        },

        customValidation12213: (value) => {
          if (typeof value != "undefined" && value.length > 2) {
            return "文字数2桁以下で入力してください";
          }
          return true;
        },
        containBox11898: (value) => {
          if (typeof this.selectBoxFID11898 == "undefined") {
            return true;
          }
          return this.selectBoxFID11898.includes(value) ||
            value == "" ||
            value == null
            ? true
            : "選択肢にない項目が入力されています";
        },
      },
      deleteModal: false,
      copyFlag: false,
      sendCopyFlag: false,
      noBack: false,
      backCount: -1,
    };
  },
  async created() {
    await this.getInit();
  },

  computed: {
    resizeWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "270px";
        case "sm":
          return "500px";
        case "md":
          return "600px";
        case "lg":
          return "500px";
        case "xl":
          return "500px";
        default:
          return "500px";
      }
    },
    showTemplate11899() {
      return true;
    },
  },
  watch: {
    $route() {
      this.getInit();
    },
  },
  methods: {
    async getInit() {
      try {
        //初期処理
        this.isLoading = true;
        //
        if (this.$route.query.copyFlag) {
          this.reportCopy();
        } else if (this.$route.params.report_id != null) {
          this.data.ReportID = Number(this.$route.params.report_id);
          //データ取得
          let data = {
            PageID: this.data.PageID,
            ReportID: Number(this.data.ReportID),
          };
          const result = await api.ReportDetailV2(data);
          this.RecodeCopy(this.data, result.Recodes);
          //初期処理
        }
        //データ取得処理

        //init処理
        this.setDuplicateCheckFID11896();

        //読み込み終了
        this.isLoading = false;
      } catch (e) {
        console.log(e);
        alert("読み込みに失敗しました");
        this.back();
      }
    },
    RecodeCopy(data, response) {
      for (const [FID] of Object.entries(data.Recodes)) {
        if (typeof response[FID] != "undefined") {
          data.Recodes[FID].value = response[FID].value;
        }
        if (
          typeof response[FID] != "undefined" &&
          typeof response[FID].subReports != "undefined" &&
          response[FID].subReports != null
        ) {
          data.Recodes[FID].subReports = response[FID].subReports;
        }
      }
    },
    getDateTime() {
      let date = new Date();
      let years = date.getFullYear().toString();
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let days = date.getDate().toString().padStart(2, "0");
      let hours = date.getHours().toString().padStart(2, "0");
      let minutes = date.getMinutes().toString().padStart(2, "0");
      let seconds = date.getSeconds().toString().padStart(2, "0");
      //
      let value =
        years +
        "-" +
        month +
        "-" +
        days +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds;
      if (value == " ") {
        return "";
      }
      return value;
    },
    async save11899() {
      this.data.Recodes.FID11899.value = this.getDateTime();

      var duplicateCheck0 = await api.FormDetail(11896);
      duplicateCheck0 = duplicateCheck0.filter((v) => v !== "");
      const count0 = duplicateCheck0.reduce((acc, val) => {
        return val === this.data.Recodes.FID11896.value ? acc + 1 : acc;
      }, 0);
      if (
        (this.$route.params.report_id != null &&
          count0 > 0 &&
          this.duplicateCheckFID11896 != this.data.Recodes.FID11896.value) ||
        (this.$route.params.report_id == null && count0 > 0)
      ) {
        alert("既に入力されています");
        return;
      }
      await this.send();
    },
    setDuplicateCheckFID11896() {
      if (
        this.data.ReportID != null &&
        this.data.Recodes.FID11896.value != ""
      ) {
        this.duplicateCheckFID11896 = this.data.Recodes.FID11896.value;
      }
    },

    checkValidation: async function () {
      let form = this.$refs.form;
      let check = form.validate();
      if (!check) {
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        return check;
      }

      return check;
    },
    async send() {
      const result = this.escapeValidation
        ? true
        : await this.checkValidation();

      if (result) {
        this.isLoading = true;

        const save = await api.SendReport(this.data);
        if (save == false || save.result == "Fail") {
          alert("登録失敗[Error0830-1]");
          this.isLoading = false;
          return;
        }
        console.log("SendPage1676");

        if (this.sendCopyFlag) {
          this.sendCopy();
          return;
        }
        alert("登録完了");
        if (!this.noBack) {
          this.back();
        } else {
          this.$set(this.$route.params, "report_id", save.reportID);
          this.$set(this.data, "ReportID", save.reportID);
          await this.getInit();
          this.isLoading = false;
          this.noBack = false;
        }
      }
    },
    back() {
      store.commit("clearreports");
      if (this.copyFlag) {
        history.go(this.backCount);
      } else {
        history.back();
      }
    },
    async del() {
      this.isLoading = true;
      await api.DelReport(this.data);
      alert("削除完了");
      this.back();
    },
  },
};
</script>
<style></style>
