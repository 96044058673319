<template>
  <v-app class="base">
    <!-- ヘッダー-->
    <v-app-bar app :color="headerColor" clipped-left dark>
      <v-container fluid :class="`d-flex justify-space-between`">
        <div v-if="false">
          <v-app-bar-nav-icon
            color="grey"
            @click="drawer = !drawer"
          ></v-app-bar-nav-icon>
        </div>
        <div v-else></div>
        <!-- スマホ画面--->
        <div
          text-align="left"
          class="white--text d-flex d-sm-none d-md-none"
          style="font-size:10.5pt"
        >
          {{ title }} <br />{{ version }}
        </div>
        <!-- タブレット画面--->
        <div
          text-align="left"
          class="white--text d-none d-sm-flex d-md-none"
          style="font-size:14pt"
        >
          {{ title }}<br />{{ version }}
        </div>
        <!-- ブラウザ画面 -->
        <div
          text-align="left"
          class="white--text d-none d-md-flex d-md-none"
          style="font-size:18pt"
        >
          {{ title }}{{ version }}
        </div>
        <!-- スマホ画面--->
        <div
          v-if="$store.state.auth"
          @click="drawer = false"
          class="white--text d-flex d-sm-none d-md-none"
          style="font-size:10.5pt"
        >
          <!--<amplify-sign-out />-->
          <div></div>
          <v-btn @click="isSignOut = true">サインアウト</v-btn>
        </div>
        <!-- タブレット画面--->
        <div
          v-if="$store.state.auth"
          @click="drawer = false"
          class="white--text d-none d-sm-flex d-md-none"
          style="font-size:14pt"
        >
          <!--<amplify-sign-out />-->
          <div></div>
          <v-btn @click="isSignOut = true">サインアウト</v-btn>
        </div>
        <!-- ブラウザ画面--->
        <div
          v-if="$store.state.auth"
          @click="drawer = false"
          class="white--text d-none d-md-flex d-md-none"
          style="font-size:18pt"
        >
          <div></div>
          <!--<amplify-sign-out />-->
          <v-btn @click="isSignOut = true">サインアウト</v-btn>
        </div>
        <div v-else></div>
        <v-dialog v-model="isSignOut" max-width="400">
          <v-card>
            <v-card-title class="text-h5">
              サインアウトしますか？
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="signOut()">
                はい
              </v-btn>
              <v-btn color="green darken-1" text @click="isSignOut = false">
                いいえ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-app-bar>
    <!-- ボディ -->
    <div>
      <v-main class="home2">
        <!-- <v-navigation-drawer app clipped v-model="drawer"> -->
        <MainMenu />
        <!--
        <v-bottom-navigation app>
          <div class="left">
            {{ version }}
          </div>
        </v-bottom-navigation>-->
        <!-- </v-navigation-drawer> -->
        <v-container fluid>
          <router-view />
          <!-- 更新画面 -->
          <v-dialog
            v-if="isChanged"
            v-model="isChanged"
            max-width="400"
            max-height="600"
          >
            <reload-Dialog
              ref="reloadDialog"
              v-on:click-Submit="modalClose"
            ></reload-Dialog>
          </v-dialog>
        </v-container>
      </v-main>
    </div>
    <div>
      <!--フッター
      <v-footer class="base"> aaaa</v-footer>
      -->
    </div>
  </v-app>
</template>

<script>
import MainMenu from "./components/MainMenu";
import reloadDialog from "./components/Reload/reloadDialog";
export default {
  name: "App",
  components: {
    MainMenu,
    reloadDialog,
  },
  data: () => ({
    title: "GM巡回レポート",
    version: "",
    drawer: false,
    isChanged: false,
    headerColor: "#37bdd2",
    isSignOut: false,
  }),
  mounted() {
    if (!this.$store.state.auth) {
      this.drawer = false;
    }
    this.init();
  },
  methods: {
    init() {
      //this.fetchServer();
      //定期的なWebページの更新確認(無効)
      //setInterval(this.fetchServer, 1000 * 60 * 60 * 3);
      if (process.env.NODE_ENV == "development") {
        this.title += " (デモ)";
        this.headerColor = "#3758d2";
      }
      this.version =
        typeof process.env.VUE_APP_VERSION != "undefined"
          ? process.env.VUE_APP_VERSION
          : this.version;
    },
    async signOut() {
      try {
        this.isSignOut = false;
        this.$store.commit("logOut");
        this.$router.push("/login");
        this.drawer = false;
      } catch (error) {
        console.log(error);
      }
    },
    //SPAの更新確認
    fetchServer() {
      fetch(this.normalizedUrl)
        .then((res) => {
          if (res.status !== 200) {
            throw Error("Cannot access server.");
          }
          return res.text();
        })
        .then((html) => {
          const hash = this.createHash(html);
          this.judgeHash(hash);
        })
        .catch((err) => console.log(err));
    },
    createHash(str) {
      const len = str.length;
      let hash = 0;
      if (len === 0) return hash;
      let i;
      for (i = 0; i < len; i++) {
        hash = (hash << 5) - hash + str.charCodeAt(i);
        hash |= 0; // Convert to 32bit integer
      }
      return hash;
    },
    judgeHash(hash) {
      if (!this.previousHash) {
        this.previousHash = hash;
        return;
      }
      if (this.previousHash !== hash) {
        //this.isChanged = true;
      }
    },
    modalClose() {
      this.isChanged = false;
    },
  },
};
</script>

<style>
@import "../css/styles.css";
</style>
