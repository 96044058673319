var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"ma-2 mt-4"},[(_vm.isLoading)?_c('div',{attrs:{"align":"center"}},[_c('v-progress-circular',{attrs:{"size":250,"color":"primary","indeterminate":"","align":"center"}})],1):_c('v-container',{staticClass:"login-form"},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"ma-0"},[_c('v-text-field',{key:"username",attrs:{"label":"社員番号","required":"","outlined":"","background-color":"white","hint":_vm.hint,"persistent-placeholder":"","persistent-hint":"","disabled":_vm.usernameDisabled},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1),_c('v-row',{staticClass:"ma-0"},[_c('v-text-field',{key:"password",attrs:{"label":typeof _vm.authState == 'undefined' ||
            _vm.authState == '' ||
            _vm.authState == 'challenge'
              ? 'パスワード'
              : '新しいパスワードを入力',"rules":[
            _vm.rules.required,
            _vm.rules.password,
            _vm.rules.isConditionMet,
            _vm.rules.isLengthValid,
          ],"outlined":"","background-color":"white","hint":_vm.hint,"persistent-placeholder":"","persistent-hint":"","type":_vm.passwordShow ? 'text' : 'password',"append-icon":_vm.passwordShow ? 'mdi-eye' : 'mdi-eye-off',"disabled":_vm.passwordDisabled},on:{"click:append":function($event){_vm.passwordShow = !_vm.passwordShow}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('v-row',[_c('div',{key:"passwordText",attrs:{"label":_vm.authState === 'newPassword' ? 'パスワード条件' : '',"rules":[
            _vm.rules.required,
            _vm.rules.password,
            _vm.rules.isConditionMet,
            _vm.rules.isLengthValid,
          ],"outlined":"","background-color":"white"}},[(_vm.authState === 'newPassword')?[_vm._v(" パスワード条件"),_c('br'),_c('span',{style:({ color: _vm.isConditionMet ? 'black' : 'red' })},[_vm._v(" ■「数字」「英大文字」「英小文字」「記号」全て含む ")]),_c('br'),_c('span',{style:({ color: _vm.isLengthValid ? 'black' : 'red' })},[_vm._v(" ■ 8文字以上 ")]),_c('br')]:_vm._e()],2)]),(_vm.authState == 'mail_register')?_c('v-row',{staticClass:"ma-0"},[_c('v-text-field',{key:"email",attrs:{"label":"メールアドレスを登録","rules":[_vm.rules.required, _vm.rules.mail],"outlined":"","background-color":"white","hint":_vm.hint,"persistent-placeholder":"","persistent-hint":"","disabled":_vm.emailDisabled},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1):_vm._e(),_c('v-row',{staticClass:"ma-0"},[_c('v-col',{attrs:{"align":"left","cols":"6","sm":"6","md":"6","lg":"6"}},[(!_vm.usernameDisabled)?_c('v-btn',{staticClass:"text-caption",attrs:{"large":"","color":"white--text pink lighten-4"},on:{"click":_vm.forgotPassword}},[_vm._v("パスワードを忘れた場合 ")]):_vm._e()],1),_c('v-col',{attrs:{"align":"right","cols":"6","sm":"6","md":"6","lg":"6"}},[(!_vm.usernameDisabled)?_c('v-btn',{attrs:{"large":"","color":"primary"},on:{"click":_vm.signin}},[_vm._v("ログイン")]):_vm._e(),(_vm.authState == 'mail_register')?_c('v-btn',{attrs:{"large":"","color":"primary"},on:{"click":_vm.register}},[_vm._v("登録 ")]):_vm._e(),(_vm.authState == 'newPassword')?_c('v-btn',{attrs:{"large":"","color":"primary"},on:{"click":_vm.newPassword}},[_vm._v("パスワード設定 ")]):_vm._e()],1)],1),(
          _vm.authState === 'challenge' ||
          _vm.authState == 'email_verified' ||
          _vm.authState == 'forgotPassword' ||
          _vm.authState == 'first_verify_email' ||
          _vm.authState == 'codeResend'
        )?_c('div',[_c('v-row',{staticClass:"ma-0"},[_c('v-text-field',{key:"verificationCode",attrs:{"label":"検証コード","required":"","outlined":"","background-color":"white","hint":_vm.hint,"persistent-placeholder":"","persistent-hint":"","disabled":_vm.verficationCodeDisabled,"rules":[_vm.rules.required, _vm.rules.smallNum]},model:{value:(_vm.verficationCode),callback:function ($$v) {_vm.verficationCode=$$v},expression:"verficationCode"}})],1),_c('v-row',{staticClass:"ma-0"},[(false)?_c('v-col',{attrs:{"align":"left"}},[_c('v-btn',{attrs:{"large":"","color":"white--text green"},on:{"click":_vm.resend}},[_vm._v(" コード再送 ")])],1):_vm._e(),_c('v-col',{attrs:{"align":"right"}},[(_vm.authState == 'forgotPassword')?_c('v-btn',{attrs:{"large":"","color":"primary"},on:{"click":_vm.passwordReset}},[_vm._v("リセット ")]):(_vm.authState == 'first_verify_email')?_c('v-btn',{attrs:{"large":"","color":"primary"},on:{"click":_vm.firstVerifyMail}},[_vm._v("初回検証 ")]):_c('v-btn',{attrs:{"large":"","color":"primary"},on:{"click":_vm.verify}},[_vm._v("検証")])],1)],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('v-btn',{class:_vm.viewStatus ? '' : 'white--text white',attrs:{"depressed":""},on:{"click":function($event){_vm.viewStatus = !_vm.viewStatus}}},[_vm._v(" login status ")])],1),_c('v-col',[_c('div',{class:_vm.viewStatus ? 'black--text' : 'white--text'},[_vm._v(" "+_vm._s(_vm.authState)+" ")])])],1)],1)],1),(_vm.authState === 'signedin')?_c('v-container',[(false)?_c('v-btn',{staticClass:"mr-4",on:{"click":_vm.signout}},[_vm._v("Sign Out")]):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }