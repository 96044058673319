<template>
  <v-card class="subPage" color="white">
    <div class="ma-1">
      <p>
        <strong>{{ subindex || subindex == 0 ? subindex + 1 : "" }}</strong>
      </p>
      <div v-if="isLoading" align="center">
        <v-progress-circular
          :size="250"
          color="primary"
          indeterminate
          align="center"
        ></v-progress-circular>
      </div>
      <div v-else>
        <v-form ref="form">
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              class="text-center align-self-center"
            >
              <div
                label=""
                background-color="white"
                id="FID13727"
                class="plainText13727 text-body-1"
                hide-details="auto"
              >
                画像アップロード（Before）
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-file-input
                label="ファイルアップロード"
                v-model="uploadfile13728.file"
                :disabled="data.Recodes.FID13728.disabled"
                :rules="[rules.imageExtension]"
                background-color="white"
                id="FID13728"
                class="fileInput13728"
                @change="changeFile13728"
                outlined
                prepend-icon="mdi-image"
                :clearable="false"
                accept="image/*"
                hide-details="auto"
              >
              </v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-img
                label="画像表示欄"
                background-color="white"
                id="FID13729"
                class="viewImage13729"
                :src="data.Recodes.FID13729.url"
                v-if="uploadfile13728.file != undefined"
                hide-details="auto"
              >
              </v-img>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              class="text-center align-self-center"
            >
              <div
                label=""
                background-color="white"
                id="FID13730"
                class="plainText13730 text-body-1"
                hide-details="auto"
              >
                画像アップロード（After）
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-file-input
                label="ファイルアップロード"
                v-model="uploadfile13731.file"
                :disabled="data.Recodes.FID13731.disabled"
                :rules="[rules.imageExtension]"
                background-color="white"
                id="FID13731"
                class="fileInput13731"
                @change="changeFile13731"
                outlined
                prepend-icon="mdi-image"
                :clearable="false"
                accept="image/*"
                hide-details="auto"
              >
              </v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-img
                label="画像表示欄"
                background-color="white"
                id="FID13732"
                class="viewImage13732"
                :src="data.Recodes.FID13732.url"
                v-if="uploadfile13731.file != undefined"
                hide-details="auto"
              >
              </v-img>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
  </v-card>
</template>

<script>
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");

export default {
  name: "Home",
  components: {},
  props: {
    //値
    Report: Object,
    subindex: Number,
    parentData: {
      type: Object,
      default: () => {},
      required: false,
    },
    parentSelectBoxDatas: {
      type: Object,
      default: () => {},
    },
    myInit: Boolean,
    FID: String,
  },
  data() {
    return {
      isLoading: false,
      escapeValidation: false,
      showChild: true,
      subInit: false,
      disabled: false,
      data: {
        PageID: 1910,
        ReportID: null, //新規ならnull、更新ならidがある
        Recodes: {
          FID13728: {
            value: "",
            formType: 9,
            disabled: false,
            form_id: 13728,
            url: "",
            view: true,
          },
          FID13729: { value: "", formType: 10, disabled: false, url: "" },
          FID13731: {
            value: "",
            formType: 9,
            disabled: false,
            form_id: 13731,
            url: "",
            view: true,
          },
          FID13732: { value: "", formType: 10, disabled: false, url: "" },
        },
        DeleteSubReports: [],
      },
      uploadfile13728: {
        file: null,
        url: "",
        data: { name: "", size: 0, type: "" },
      },
      uploadfile13731: {
        file: null,
        url: "",
        data: { name: "", size: 0, type: "" },
      },
      rules: {
        imageExtension: (value) => {
          if (
            value == null ||
            typeof value !== "object" ||
            typeof value.name !== "string"
          )
            return true;
          const splitValue = value.name.split(".");
          const extension = splitValue[splitValue.length - 1];
          return extension == "jpeg" || extension == "jpg" || extension == "png"
            ? true
            : "対応していないファイルです。対応可能ファイル:JPEG、JPG、PNG";
        },
      },
      copyFlag: false,
      noBack: false,
    };
  },
  async created() {
    await this.getInit();
  },
  computed: {},
  watch: {
    $route() {
      this.getInit();
    },
    data: {
      handler: async function (data) {
        //

        //
        this.$emit("clickSubmit", this.FID, this.subindex, data);
      },
      deep: true,
    },
    uploadfile13728: {
      deep: true,
      handler: async function () {
        if (
          this.uploadfile13728.file == undefined ||
          this.data.Recodes.FID13728.value != ""
        )
          return;
        let blob;
        if (!(this.uploadfile13728.file instanceof File)) {
          blob = new Blob([this.uploadfile13728.file], {
            type: "application/octet-stream",
          });
        } else {
          blob = this.uploadfile13728.file;
        }
        try {
          this.data.Recodes.FID13729.url = URL.createObjectURL(blob);
        } catch (error) {
          console.error("Failed to create object URL:", error);
        }
      },
    },
    uploadfile13731: {
      deep: true,
      handler: async function () {
        if (
          this.uploadfile13731.file == undefined ||
          this.data.Recodes.FID13731.value != ""
        )
          return;
        let blob;
        if (!(this.uploadfile13731.file instanceof File)) {
          blob = new Blob([this.uploadfile13731.file], {
            type: "application/octet-stream",
          });
        } else {
          blob = this.uploadfile13731.file;
        }
        try {
          this.data.Recodes.FID13732.url = URL.createObjectURL(blob);
        } catch (error) {
          console.error("Failed to create object URL:", error);
        }
      },
    },
  },
  methods: {
    async getInit() {
      try {
        //初期処理
        this.isLoading = true;
        //
        //データが存在する場合
        if (this.$route.query.copyFlag) {
          this.reportCopy();
        } else if (this.Report.ReportID != null || this.myInit) {
          //サブレポートの値代入
          this.data.ReportID = this.Report.ReportID;
          this.data.Recodes = api.OptionCopy(
            this.data.Recodes,
            this.Report.Recodes
          );
          this.initFile13728();
          this.initFile13731();
        } else {
          //初期値設定
          this.initFile13728();
          this.initFile13731();
        }
        //データ取得処理

        //init処理
        await this.viewInitProcess13729();
        await this.viewInitProcess13732();

        //初回データ反映
        this.$emit("clickSubmit", this.FID, this.subindex, this.data);
        //読み込み終了
        this.isLoading = false;
      } catch (e) {
        console.log(e);
        alert("読み込みに失敗しました");
        this.back();
      }
    },
    RecodeCopy(data, response) {
      for (const [FID] of Object.entries(data.Recodes)) {
        if (typeof response[FID] != "undefined") {
          data.Recodes[FID].value = response[FID].value;
        }
      }
    },
    async send() {
      const result = this.escapeValidation
        ? true
        : await this.checkValidation();
      if (result) {
        await this.sendFile13728();
        this.viewSendProcess13729();
        await this.sendFile13731();
        this.viewSendProcess13732();
      }
    },
    changeFile13728(file) {
      if (file !== undefined && file !== null) {
        this.data.Recodes.FID13728.view = false;
        this.uploadfile13728.file = file;
        this.uploadfile13728.data.size = this.uploadfile13728.file.size;
        this.uploadfile13728.data.type = this.uploadfile13728.file.type;
        this.uploadfile13728.data.form_id = 13728;
        this.data.Recodes.FID13728.file_type = this.uploadfile13728.file.type;
        this.data.Recodes.FID13728.url = URL.createObjectURL(file);
        this.$nextTick(() => {
          this.data.Recodes.FID13728.view = true;
        });
      } else {
        this.uploadfile13728 = {
          file: null,
          url: "",
          data: { name: "", size: 0, type: "" },
        };
        this.data.Recodes.FID13728.file_type = "";
        this.data.Recodes.FID13728.url = null;
      }
    },
    changeFile13731(file) {
      if (file !== undefined && file !== null) {
        this.data.Recodes.FID13731.view = false;
        this.uploadfile13731.file = file;
        this.uploadfile13731.data.size = this.uploadfile13731.file.size;
        this.uploadfile13731.data.type = this.uploadfile13731.file.type;
        this.uploadfile13731.data.form_id = 13731;
        this.data.Recodes.FID13731.file_type = this.uploadfile13731.file.type;
        this.data.Recodes.FID13731.url = URL.createObjectURL(file);
        this.$nextTick(() => {
          this.data.Recodes.FID13731.view = true;
        });
      } else {
        this.uploadfile13731 = {
          file: null,
          url: "",
          data: { name: "", size: 0, type: "" },
        };
        this.data.Recodes.FID13731.file_type = "";
        this.data.Recodes.FID13731.url = null;
      }
    },
    async viewInitProcess13729() {
      if (this.data.Recodes.FID13728.value != "") {
        this.data.Recodes.FID13729.url = await api.FileAccess(
          this.data.Recodes.FID13728
        );
      }
    },
    async viewInitProcess13732() {
      if (this.data.Recodes.FID13731.value != "") {
        this.data.Recodes.FID13732.url = await api.FileAccess(
          this.data.Recodes.FID13731
        );
      }
    },
    initFile13728() {
      this.uploadfile13728.file = {};
    },
    initFile13731() {
      this.uploadfile13731.file = {};
    },
    async sendFile13728() {
      try {
        if (
          this.uploadfile13728.file !== undefined &&
          this.uploadfile13728.file !== null &&
          this.uploadfile13728.data.type != ""
        ) {
          this.data.Recodes.FID13728.value = await api.UploadFile(
            this.uploadfile13728.data,
            this.uploadfile13728.file
          );
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    viewSendProcess13729() {
      if (this.data.Recodes.FID13728.value != "") {
        this.data.Recodes.FID13729.value = this.data.Recodes.FID13728.value;
      }
    },
    async sendFile13731() {
      try {
        if (
          this.uploadfile13731.file !== undefined &&
          this.uploadfile13731.file !== null &&
          this.uploadfile13731.data.type != ""
        ) {
          this.data.Recodes.FID13731.value = await api.UploadFile(
            this.uploadfile13731.data,
            this.uploadfile13731.file
          );
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    viewSendProcess13732() {
      if (this.data.Recodes.FID13731.value != "") {
        this.data.Recodes.FID13732.value = this.data.Recodes.FID13731.value;
      }
    },

    checkValidation: async function () {
      let form = this.$refs.form;
      let check = form.validate();
      if (this.escapeValidation) {
        return true;
      }
      if (!check) {
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        return check;
      }
      return check;
    },
    async del() {
      await api.DelReport(this.data);
      alert("削除完了");
      this.back();
    },
  },
};
</script>
<style></style>
