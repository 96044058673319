<template>
  <div class="home">
    <p>人事情報・SS情報エクセルデータ取り込み</p>
    <v-col align="right"><v-btn @click.once="back" :loading="isLoading">戻る</v-btn></v-col>
    <div v-if="isLoading" align="center">
      <v-progress-circular :size="250" color="primary" indeterminate align="center"></v-progress-circular>
    </div>
    <div v-else>
      <v-container cols="12">
        <v-form ref="form">
          <v-card class="mb-2">
            <v-card-title>SS情報取込</v-card-title>
            <v-card-text>
              <v-row>
                <v-checkbox v-model="uploadfileSsData.headerFlag" label="ヘッダー有">
                </v-checkbox>
                <v-file-input v-model="uploadfileSsData.file" type="file" label="SS情報取込(エクセル)" prepend-icon="mdi-image"
                  ref="refFileSS" accept=".xlsx,.xlsm,.xls" @change="chargeExcelSS" :rules="[rules.fileSize]" show-size>
                </v-file-input>
              </v-row>
              <v-row class="ma-0 mt-3">
                <v-col class="ma-0 pa-0">※同じ「SSコード」が複数存在する場合は取込不可</v-col>
              </v-row>
              <v-row class="ma-0 mt-3">
                <v-col cols="12" class="ma-0 pa-0">★取込ファイルのヘッダー情報</v-col>
                <v-col cols="12" class="ma-0 pa-0">
                  【A列】SSコード,【B列】事業部コード,【C列】事業部名,【D列】SS名,【E列】住所,【F列】事業部役職,【G列】担当GM名,【H列】社員コード,【I列】GMメールアドレス,【J列】SSメールアドレス,【K列】Gr
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card class="mb-2">
            <v-card-title>人事情報取込</v-card-title>
            <v-card-text>
              <v-row>
                <v-checkbox v-model="uploadfileHumanResources.headerFlag" label="ヘッダー有">
                </v-checkbox>
                <v-file-input v-model="uploadfileHumanResources.file" type="file" label="人事情報取込(エクセル)"
                  prepend-icon="mdi-image" ref="refFileHumanResources" accept=".xlsx,.xlsm,.xls"
                  @change="chargeExcelHumanResources" :rules="[rules.fileSize]" show-size
                  :disabled="!(uploadfileSsData.data.name) || isImportSsError">
                </v-file-input>
              </v-row>
              <v-row class="ma-0 mt-3">
                <v-col cols="12" class="ma-0 pa-0">※同じ「社員番号」が複数存在する場合は取込不可</v-col>
                <v-col cols="12" class="ma-0 pa-0">※同じ「SSコード」に複数の「MG」「GM」が存在する場合は取込不可</v-col>
                <v-col cols="12" class="ma-0 pa-0">※SS情報と異なる「SSコード」「GM」の組み合わせが存在する場合は取込不可</v-col>
              </v-row>
              <v-row class="ma-0 mt-3">
                <v-col cols="12" class="ma-0 pa-0">★取込ファイルのヘッダー情報</v-col>
                <v-col cols="12" class="ma-0 pa-0">
                  【A列】事業部コード,【B列】SSコード,【C列】現在組織正式名称4,【D列】現在組織正式名称,【E列】社員コード,【F列】氏名漢字,【G列】現在役職正式名称
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-row>
            <v-col align="right">
              <v-btn @click="send" color="primary"
                :disabled="!sendFlag || !isAllImport || isImportSsError || isImportHumanResourcesError">送信</v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="pb-0"><strong>SSマスタ登録情報</strong></v-col>
            <v-col cols="12" class="pb-0 pt-0">※取込ファイルにないSSマスタデータは削除されます</v-col>
          </v-row>
          <v-row>
            <v-col cols="">
              <v-data-table :headers="headers" :items="importSSDatas" :item-ky="importSSDatas.ID" :header-props="{
                  'sort-by-text': '並び順',
                }" :footer-props="{
                  'items-per-page-text': '行/ページ:',
                }" no-data-text="データが存在しません">
                <template v-slot:item.Recodes.FID13326="{ item }">
                  {{ getSubReportSMGNames(item.Recodes.FID13326.subReports) }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="pb-0"><strong>ユーザーマスタ登録情報</strong></v-col>
            <v-col cols="12" class="pb-0 pt-0">※新規登録されるユーザーのログイン時の初期パスワードは『{{ initialPassword }}』です</v-col>
            <v-col cols="12" class="pb-0 pt-0">※取込ファイルにないユーザーマスタデータは削除されます</v-col>
            <v-col cols="12" class="pb-0 pt-0">※ユーザー設定で「更新・削除不可」設定が有効になっているユーザーは更新・削除されません</v-col>
          </v-row>
          <v-row>
            <v-col cols="">
              <v-data-table :headers="headerUsers" :items="importUserDatas" :item-ky="importUserDatas.ID" :header-props="{
                  'sort-by-text': '並び順',
                }" :footer-props="{
                  'items-per-page-text': '行/ページ:',
                }" no-data-text="データが存在しません">
                <!-- <template v-slot:item.Recodes.FID13326="{ item }">
                  {{ getSubReportSMGNames(item.Recodes.FID13326.subReports) }}
                </template> -->
              </v-data-table>
            </v-col>
          </v-row>
<!--
          <v-row>
            <v-col cols="12" class="mt-3 pb-0">取込ファイル情報（SS情報）読込データ ※データは保持されません</v-col>
          </v-row>
          <v-row>
            <v-col cols="">
              <v-data-table :headers="headersSsDatas" :items="ssDatas" :item-ky="ssDatas.ID" :header-props="{
                  'sort-by-text': '並び順',
                }" :footer-props="{
                  'items-per-page-text': '行/ページ:',
                }" no-data-text="データが存在しません">
              </v-data-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="mt-3 pb-0">取込ファイル情報（人事情報）読込データ ※データは保持されません</v-col>
          </v-row>
          <v-row>
            <v-col cols="">
              <v-data-table :headers="headersHumanResourcesDatas" :items="allHumanResourcesDatas" :item-ky="ssDatas.ID"
                :header-props="{
                  'sort-by-text': '並び順',
                }" :footer-props="{
                  'items-per-page-text': '行/ページ:',
                }" no-data-text="データが存在しません">
              </v-data-table>
            </v-col>
          </v-row> -->
        </v-form>
      </v-container>
    </div>

    <!-- <loading :active.sync="isLoading" :is-full-page="fullPage"></loading> -->
  </div>
</template>

<script>
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");
import * as XLSX from "xlsx";
import { importJobNames, importJobRoles } from "./const";

const subReportSMG = {
  PageID: 1842,
  ReportID: null, //新規ならnull、更新ならidがある
  Recodes: {
    FID13322: { value: "", formType: 41, disabled: false, modal: false },
    FID13315: { value: "", formType: 1, disabled: false },
    FID13316: { value: "", formType: 1, disabled: false },
  },
  DeleteSubReports: [],
}

const userPageID = 1654;
const initialPassword = "snL7FNmydN%V";
const userData = {
  PageID: userPageID,
  ReportID: null, //新規ならnull、更新ならidがある
  Recodes: {
    FID0: {
      value: "",
      formType: 21,
      name: "",
      mail: "eneosPatrol@demo.service.kakanai.com",
      role: "0",
      password: initialPassword,
      account: "",
      attribute: "",
    },
    FID12259: { value: "", formType: 1, disabled: false },//事業部コード
    FID12260: { value: "", formType: 1, disabled: false },//現在組織正式名称4
    FID12261: { value: "", formType: 1, disabled: false },//役職
    FID12262: { value: "", formType: 1, disabled: false }, // (非)権限
    FID13317: { value: "", formType: 1, disabled: false },//氏名
    FID13318: { value: "", formType: 1, disabled: false },//現在組織正式名称
    FID13319: { value: "0", formType: 1, disabled: false },//SSコード
    FID13320: { value: "", formType: 1, disabled: false },//社員コード（usernameと同じ、検索用）
  },
  DeleteSubReports: [],
};

export default {
  name: "Home",
  components: {},
  data() {
    return {
      initialPassword: initialPassword,
      isLoading: false,
      fullPage: false,
      headerText: ",", //`$$headerText`,
      valid: true,
      data: {
        PageID: 1661, //
        Page_id: 1661, //送信に使っているが本来は変換する
        Report_id: null, //
        UpdateType: "update",
        KeyID: [11265],
        KeyMap: {
          FID11265: 0,
        },
        Recodes: {
          FID11265: { value: "", formType: 1 }, //SSコード
          FID11266: { value: "", formType: 1 }, //SS名
          FID11267: { value: "", formType: 1 }, //直営事業部名
          FID11268: { value: "", formType: 1 }, //Gr（グループ）
          FID11269: { value: "", formType: 1 }, //MG(マネージャー)名
          // FID11270: { value: "", formType: 1 }, //SMG(サブマネージャー)名
          FID11271: { value: "", formType: 1 }, //GM(グループマネージャー)名:旧GL(グループリーダー)
          // FID11272: { value: "", formType: 1 }, //廃止：釣銭準備金
          FID11273: { value: "", formType: 14 }, //廃止：認証指定
          FID12529: { value: "", formType: 1 }, //店舗メールアドレス
          FID11274: { value: "", formType: 20 }, //登録日時
          FID13155: { value: "", formType: 1 }, //事業部コード
          FID13156: { value: "", formType: 1 }, //GM社員コード
          FID13157: { value: "", formType: 1 }, //GMメールアドレス
          FID13365: { value: "", formType: 1, disabled: false }, //MG社員コード
          FID13326: {
            value: "PID1842",
            formType: 17,
            disabled: false,
            subReports: [],
          },//SMG(サブマネージャー)名
        },
        DeleteSubReports: [],
      },
      //
      sendFlag: false,
      isImportSsError: true,
      isImportHumanResourcesError: true,
      uploadfileSsData: { file: {}, url: "", data: { name: "", size: 0, type: "" }, headerFlag: true },
      ssDatas: [],
      ssData: {
        ssCode: "",
        ssName: "",
        zigyoubuCode: "",
        zigyoubu: "",
        gmName: "",
        gmEmployeeCode: "",
        gmEmail: "",
        ssEmail: "",
      },

      uploadfileHumanResources: { file: {}, url: "", data: { name: "", size: 0, type: "" }, headerFlag: true },
      allHumanResourcesDatas: [],
      allHumanResourcesData: {
        zigyoubuCode: "",
        ssCode: "",
        sohikiName4: "",
        sohikiName: "",
        employeeCode: "",
        employeeName: "",
        jobName: "",
      },

      //
      header_flag: true,
      reports: [],
      rules: {
        fileSize: (value) => {
          return (
            value == null ||
            value.size < 15000000 ||
            "ファイルサイズを15MB未満にしてください"
          );
        },
      },
    };
  },
  async created() {
    await this.getInit();
  },
  computed: {
    isAllImport() {
      return (
        this.uploadfileSsData.data.name &&
        this.uploadfileHumanResources.data.name
      );
    },
    headers() {
      return [
        { text: "SSコード", value: "Recodes.FID11265.value", sortable: true },
        { text: "SS名", value: "Recodes.FID11266.value", sortable: true },
        { text: "直営事業部コード", value: "Recodes.FID13155.value", sortable: true },
        { text: "直営事業名", value: "Recodes.FID11267.value", sortable: true },
        { text: "Gr", value: "Recodes.FID11268.value", sortable: true },
        { text: "GM社員コード", value: "Recodes.FID13156.value", sortable: true },
        { text: "GM", value: "Recodes.FID11271.value", sortable: true },
        { text: "GMメールアドレス", value: "Recodes.FID13157.value", sortable: true },
        { text: "MG社員コード", value: "Recodes.FID13365.value", sortable: true },
        { text: "MG", value: "Recodes.FID11269.value", sortable: true },
        { text: "SMG", value: "Recodes.FID13326", sortable: false },
        // { text: "釣銭準備金", value: "Recodes.FID11272.value", sortable: true },
        // { text: "認証指定", value: "Recodes.FID11273.value", sortable: true },
        { text: "店舗メールアドレス", value: "Recodes.FID12529.value", sortable: true },
      ];
    },
    headerUsers() {
      return [
        {
          text: "社員番号",
          value: "Recodes.FID0.name",
          align: "start",
          sortable: true,
        },
        { text: "氏名", value: "Recodes.FID13317.value", sortable: true },
        //{ text: "メールアドレス", value: "Recodes.FID0.mail", sortable: false },
        { text: "権限", value: "Recodes.FID12262.value", sortable: false },
        {
          text: "事業部コード",
          value: "Recodes.FID12259.value",
          align: "start",
          sortable: true,
        },
        { text: "現在組織正式名称4", value: "Recodes.FID12260.value", sortable: true },
        { text: "現在組織正式名称", value: "Recodes.FID13318.value", sortable: true },
        { text: "SSコード", value: "Recodes.FID13319.value", sortable: true },
        { text: "役職", value: "Recodes.FID12261.value", sortable: true },
      ];
    },
    headersSsDatas() {
      return [
        { text: "SSコード", value: "ssCode", sortable: true },
        { text: "直営事業部コード", value: "zigyoubuCode", sortable: true },
        { text: "直営事業部名", value: "zigyoubu", sortable: true },
        { text: "SS名", value: "ssName", sortable: true },
        { text: "住所", value: "nonImportData1", sortable: true },
        { text: "事業部役職", value: "nonImportData2", sortable: true },
        { text: "GM名", value: "gmName", sortable: true },
        { text: "GM社員コード", value: "gmEmployeeCode", sortable: true },
        { text: "GMメールアドレス", value: "gmEmail", sortable: true },
        { text: "SSメールアドレス", value: "ssEmail", sortable: true },
        { text: "Gr", value: "ssGr", sortable: true },
      ];
    },
    headersHumanResourcesDatas() {
      return [
        { text: "事業部コード", value: "zigyoubuCode", sortable: true },
        { text: "SSコード", value: "ssCode", sortable: true },
        { text: "現在組織正式名称4", value: "sohikiName4", sortable: true },
        { text: "現在組織正式名称", value: "sohikiName", sortable: true },
        { text: "社員コード", value: "employeeCode", sortable: true },
        { text: "氏名漢字", value: "employeeName", sortable: true },
        { text: "現在役職正式名称", value: "jobName", sortable: true },
      ];
    },
    importSSDatas() {
      return this.ssDatas.map((ssData) => {
        const report = JSON.parse(JSON.stringify(this.data));
        report.Recodes.FID11265.value = ssData.ssCode;
        report.Recodes.FID11266.value = ssData.ssName;
        report.Recodes.FID11267.value = ssData.zigyoubu;
        report.Recodes.FID13155.value = ssData.zigyoubuCode;
        report.Recodes.FID12529.value = ssData.ssEmail;
        report.Recodes.FID13156.value = ssData.gmEmployeeCode;
        report.Recodes.FID11271.value = ssData.gmName;
        report.Recodes.FID13157.value = ssData.gmEmail;
        report.Recodes.FID11268.value = ssData.ssGr;
        // マネージャー名
        const findHumanResourcesData = this.allHumanResourcesDatas.filter(
          (item) => item.jobName == "MG" && item.ssCode == ssData.ssCode
        );
        report.Recodes.FID11269.value = findHumanResourcesData[0] ? findHumanResourcesData[0].employeeName : "";
        report.Recodes.FID13365.value = findHumanResourcesData[0] ? findHumanResourcesData[0].employeeCode : "";
        if (findHumanResourcesData.length > 1) {
          console.log("MG複数", findHumanResourcesData);
        }
        // サブマネージャー名
        const filterHumanResourcesDatas = this.allHumanResourcesDatas.filter(
          (item) => item.jobName == "SMG" && item.ssCode == ssData.ssCode
        );
        filterHumanResourcesDatas.forEach(
          (item) => {
            const sub = JSON.parse(JSON.stringify(subReportSMG));
            sub.Recodes.FID13315.value = item.employeeCode;
            sub.Recodes.FID13316.value = item.employeeName;
            report.Recodes.FID13326.subReports.push(sub);
          }
        );
        return report;
      });
    },
    importUserDatas() {
      return this.allHumanResourcesDatas.map((report) => {
        const user = JSON.parse(JSON.stringify(userData));
        user.Recodes.FID0.name = report.employeeCode;//社員番号
        //部長・GM: 管理者 その他: 一般
        if (importJobRoles[report.jobName]) {
          user.Recodes.FID0.attribute = importJobRoles[report.jobName].attribute;
          user.Recodes.FID0.role = importJobRoles[report.jobName].role;
          user.Recodes.FID12262.value = importJobRoles[report.jobName].name;
        }
        // user.Recodes.FID0.password = "";//パスワード(新規登録時のみ必須)
        user.Recodes.FID12259.value = report.zigyoubuCode || "0";//事業部コード
        user.Recodes.FID12260.value = report.sohikiName4;//現在組織正式名称4
        user.Recodes.FID12261.value = report.jobName;//役職
        user.Recodes.FID13317.value = report.employeeName;//氏名
        user.Recodes.FID13318.value = report.sohikiName;//現在組織正式名称
        user.Recodes.FID13319.value = report.ssCode || "0";//SSコード
        user.Recodes.FID13320.value = report.employeeCode;//社員コード（usernameと同じ、検索用）
        return user;
      })
    },
  },
  watch: {},
  methods: {
    async getInit() {
      //初期処理
      this.isLoading = true;
      //読み込み終了
      this.isLoading = false;
    },
    /*-----------------------------*/
    //文字コードのチェック
    charCodeConvert(file) {
      const Encoding = require("encoding-japanese");
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          //文字列をバイト列に変換
          var sjisArray = new Uint8Array(reader.result);
          const encoding = this.detectEncoding(reader.result);
          switch (encoding) {
            case "SJIS":
              var unicodeArray = Encoding.convert(sjisArray, {
                to: "UNICODE",
                from: "SJIS",
                type: "string",
              });
              resolve(unicodeArray);
              break;
            case "UTF8":
              var unicodeArray2 = Encoding.convert(sjisArray, {
                to: "UNICODE",
                from: "AUTO",
                type: "string",
              });
              resolve(unicodeArray2);
              break;
            default:
              alert("文字コードが対応していません:" + encoding);
          }
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      });
    },
    detectEncoding(buffer) {
      const uint8Array = new Uint8Array(buffer);
      if (
        uint8Array[0] === 0xef &&
        uint8Array[1] === 0xbb &&
        uint8Array[2] === 0xbf
      ) {
        return "UTF8";
      } else if (uint8Array[0] === 0xfe && uint8Array[1] === 0xff) {
        return "UTF16BE";
      } else if (uint8Array[0] === 0xff && uint8Array[1] === 0xfe) {
        return "UTF16LE";
      } else if (
        uint8Array[0] === 0 &&
        uint8Array[1] === 0 &&
        uint8Array[2] === 0xfe &&
        uint8Array[3] === 0xff
      ) {
        return "UTF32BE";
      } else if (
        uint8Array[0] === 0xff &&
        uint8Array[1] === 0xfe &&
        uint8Array[2] === 0 &&
        uint8Array[3] === 0
      ) {
        return "UTF32LE";
      } else if (
        uint8Array[0] === 0 &&
        uint8Array[1] === 0 &&
        uint8Array[2] === 0 &&
        uint8Array[3] !== 0
      ) {
        return "UTF32BE-noBOM";
      } else if (
        uint8Array[0] !== 0 &&
        uint8Array[1] === 0 &&
        uint8Array[2] === 0 &&
        uint8Array[3] === 0
      ) {
        return "UTF32LE-noBOM";
      } else {
        return "SJIS";
      }
    },
    /*ダブルクォーテーション内のカンマは分割しない*/
    csvSplit(line) {
      let c = "";
      let s = new String();
      let data = new Array();
      let singleQuoteFlg = false;

      for (let i = 0; i < line.length; i++) {
        c = line.charAt(i);
        if (c == "," && !singleQuoteFlg) {
          data.push(s.toString());
          s = "";
        } else if (c == "," && singleQuoteFlg) {
          s = s + c;
        } else if (c == '"') {
          singleQuoteFlg = !singleQuoteFlg;
        } else {
          s = s + c;
        }
      }
      //最後のデータを追加
      data.push(s.toString());
      return data;
    },
    //現在のレポートとCSVの主キーが同じか確認する
    sameKeyCheck(data, csv) {
      let flag = true;
      Object.keys(data.KeyMap).map((key) => {
        const x = key;
        const y = data.KeyMap[key];
        if (data.Recodes[x].value == "" || csv[y] == "") {
          flag = false;
        }
        if (data.Recodes[x].value != csv[y]) {
          flag = false;
        }
      });
      return flag;
    },
    setValue(report, csv, to, from, tag) {
      if (typeof tag == "undefined") {
        if (typeof csv[from] != "undefined" && csv[from] != "") {
          report.Recodes[to].value = String(csv[from]).trim();
        }
        return;
      } else {
        switch (tag) {
          case "add":
            report.Recodes[to].value += String(csv[from]).trim();
            break;
        }
      }
    },
    setValue2(report, csv, to, from) {
      if (typeof csv[from] != "undefined" && csv[from] != "") {
        report[to] = String(csv[from]).trim();
      }
      return;
    },
    async chargeExcelSS(event) {
      this.isLoading = true;
      try {
        this.uploadfileSsData.data.name = "";
        const file = event;
        this.isLoading = false;
        if (file) {
          this.uploadfileSsData.data.name = event.name;
          const reader = new FileReader();
          reader.onload = async (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]]; //シート番号
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            //ヘッダー行を削除
            if (this.uploadfileSsData.headerFlag) {
              jsonData.shift();
            }
            //データ処理
            this.ssDatas = this.convertReportsSS(jsonData);
            //バリデーションチェック
            this.isLoading = false;
            await this.$nextTick();
          };
          reader.readAsArrayBuffer(file);
        }
        this.isLoading = false;
      } catch (e) {
        console.log("chargeExcel", e);
      }
    },
    async chargeExcelHumanResources(event) {
      this.isLoading = true;
      try {
        const file = event;
        this.uploadfileHumanResources.data.name = "";
        this.isLoading = false;
        if (file) {
          this.uploadfileHumanResources.data.name = event.name;
          const reader = new FileReader();
          reader.onload = async (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]]; //シート番号
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            //先頭削除
            jsonData.shift();
            //ヘッダー行を削除
            if (this.uploadfileHumanResources.headerFlag) {
              jsonData.shift();
            }
            //データ処理
            this.allHumanResourcesDatas = this.convertReportsHumanResources(jsonData);
            //バリデーションチェック
            this.isLoading = false;
            await this.$nextTick();
          };
          reader.readAsArrayBuffer(file);
        }
        this.sendFlag = true;
        this.isLoading = false;
      } catch (e) {
        console.log("chargeExcel", e);
      }
    },
    convertReportsSS(csv) {
      let data = [];
      let mainReport = JSON.parse(JSON.stringify(this.ssData));
      let count = 0;
      let errorCount = 0;
      let errorDatas = [];
      //
      csv.forEach((c) => {
        if (c.length > 1) {
          // //A列(SSコード)が数字の行のみ取込対象
          // if (!c[0] || isNaN(c[0])) {
          //   return;
          // }
          if (count > 0) {
            data.push(mainReport);
          }
          count++;
          mainReport = JSON.parse(JSON.stringify(this.ssData));
          this.setValue2(mainReport, c, "ssCode", 0); //SSコード
          this.setValue2(mainReport, c, "zigyoubuCode", 1); //直営事業部コード
          this.setValue2(mainReport, c, "zigyoubu", 2); //直営事業本部名
          this.setValue2(mainReport, c, "ssName", 3); //SS名
          // 4:住所、5:事業部役職
          this.setValue2(mainReport, c, "gmName", 6); //GM名
          this.setValue2(mainReport, c, "gmEmployeeCode", 7); //GM社員コード
          this.setValue2(mainReport, c, "gmEmail", 8); //GMメールアドレス
          this.setValue2(mainReport, c, "ssEmail", 9); //店舗メールアドレス
          this.setValue2(mainReport, c, "ssGr", 10); //Gr
          //エラーチェック
          if (data.some((item) => item.ssCode == mainReport.ssCode)) {
            errorCount++;
            errorDatas.push(mainReport.ssCode);
          }
        }
      });
      if (errorCount > 0) {
        this.isImportSsError = true;
        let errorMessage = "■SSコードが重複しています。（SSコード）";
        errorMessage += "\n" + errorDatas.join("\n");
        alert(errorMessage);
      } else {
        this.isImportSsError = false;
      }
      data.push(mainReport);
      //
      return data;
    },
    convertReportsHumanResources(csv) {
      let data = [];
      let count = 0;
      let mainReport = JSON.parse(JSON.stringify(this.allHumanResourcesData));
      let errorCount = 0;
      let errorEmployeeCodeDatas = [];
      let errorMultipleMgOrGmDatas = [];
      let errorSsDifferentDatas = [];
      //
      csv.forEach((c) => {
        if (c.length > 1) {
          // G列『現在役職』が対象の役職の行のみ取込対象
          const jobName = c[6] ? importJobNames[String(c[6]).trim()] : "";
          if (!jobName) {
            return;
          }
          //B列:SSコード
          const ssCode = c[1] ? String(c[1]).trim() : "0";
          if (count > 0) {
            data.push(mainReport);
          }
          count++
          mainReport = JSON.parse(JSON.stringify(this.allHumanResourcesData));
          this.setValue2(mainReport, c, "zigyoubuCode", 0); //A列:事業部コード
          mainReport.ssCode = ssCode; //B列:SSコード
          this.setValue2(mainReport, c, "sohikiName4", 2); //C列:現在組織正式名称4
          this.setValue2(mainReport, c, "sohikiName", 3); //D列:現在組織正式名称
          this.setValue2(mainReport, c, "employeeCode", 4); //E列:社員コード
          this.setValue2(mainReport, c, "employeeName", 5); //F列:氏名漢字
          mainReport.jobName = jobName; //G列:現在役職正式名称を変換
          //サブレポート宣言処理

          //CSV読み込み時のデータ対応
          //エラーチェック
          // 同じ社員番号がある場合
          if (data.some((item) => item.employeeCode == mainReport.employeeCode)) {
            errorCount++;
            errorEmployeeCodeDatas.push(mainReport.employeeCode + " / " + mainReport.employeeName);
          }
          // 1つのSSコードに複数のMGまたはGMがある場合
          if (mainReport.ssCode != "0" && (mainReport.jobName == "MG" || mainReport.jobName == "GM") && data.some((item) => item.ssCode == mainReport.ssCode && (item.jobName == mainReport.jobName))) {
            errorCount++;
            errorMultipleMgOrGmDatas.push(mainReport.ssCode);
          }
          // SS情報と異なる「SSコード」「GM」の組み合わせが存在する場合
          if (mainReport.ssCode != "0" && mainReport.jobName == "GM" && this.ssDatas.some((item) => item.ssCode == mainReport.ssCode && item.gmEmployeeCode != mainReport.employeeCode)) {
            errorCount++;
            errorSsDifferentDatas.push(mainReport.ssCode);
          }
        }
      });
      if (errorCount > 0) {
        this.isImportHumanResourcesError = true;
        let errorMessage = "";
        if (errorEmployeeCodeDatas.length > 0) {
          errorMessage += "■社員番号が重複しています。（社員番号 / 氏名）";
          errorMessage += "\n" + errorEmployeeCodeDatas.join("\n");
        }
        if (errorMultipleMgOrGmDatas.length > 0) {
          if (errorMessage) {
            errorMessage += "\n";
          }
          errorMessage += "■1つのSSコードに複数のMGまたはGMが存在します。（SSコード）";
          errorMessage += "\n" + errorMultipleMgOrGmDatas.join("\n");
        }
        if (errorSsDifferentDatas.length > 0) {
          if (errorMessage) {
            errorMessage += "\n";
          }
          errorMessage += "■SS情報と異なる「SSコード」「GM」の組み合わせが存在します。（SSコード）";
          errorMessage += "\n" + errorSsDifferentDatas.join("\n");
        }
        alert(errorMessage);
      } else {
        this.isImportHumanResourcesError = false;
      }
      data.push(mainReport);
      //
      return data;
    },
    checkValidation: function () {
      let form = this.$refs.form;
      let check = form.validate();

      return check;
    },
    back() {
      history.back();
    },
    // 既存のマスタデータとCSVデータを比較して、更新、新規、削除のデータを取得
    getSendReports(reports, csvReports, primaryKeys, subFIDs = []) {
      const result = { updateReports: [], createReports: [], deleteReports: [] };
      reports.forEach((report) => {
        const csvReport = csvReports.find((csvReport) => {
          return this.isSameKey(report, csvReport, primaryKeys);
        });
        if (csvReport) {
          result.updateReports.push(this.getUpdateReport(report, csvReport, subFIDs));
        } else {
          result.deleteReports.push(report);
        }
      });
      csvReports.forEach((csvReport) => {
        const report = reports.find((report) => {
          return this.isSameKey(report, csvReport, primaryKeys);
        });
        if (!report) {
          result.createReports.push(csvReport);
        }
      });
      return result;
    },
    isSameKey(report, csvReport, primaryKeys) {
      return primaryKeys.every((key) => {
        return report.Recodes[key] &&
          csvReport.Recodes[key] &&
          report.Recodes[key].value == csvReport.Recodes[key].value;
      });
    },
    getUpdateReport(report, csvReport, subFIDs = []) {
      const updateReport = JSON.parse(JSON.stringify(report));
      Object.keys(csvReport.Recodes).forEach((key) => {
        if (subFIDs.includes(key)) {
          // サブレポートの削除と追加
          updateReport.DeleteSubReports.push(...report.Recodes[key].subReports);
          updateReport.Recodes[key].subReports = csvReport.Recodes[key].subReports;
        } else {
          updateReport.Recodes[key].value = csvReport.Recodes[key].value;
        }
      });
      return updateReport;
    },
    // 既存のSSマスタデータを取得
    async getReports() {
      const data = {
        PageID: this.data.PageID,
        orderBy: "",
        search: [],
      };
      const reports = await api.ReportSearch(data);
      if (reports != null) {
        return reports.map((report => {
          const data = JSON.parse(JSON.stringify(this.data));
          data.ReportID = report.ReportID;
          this.RecodeCopy(data, report.Recodes);
          return data;
        }));
      }
      return [];
    },
    // 既存のユーザーデータを取得
    async getUsers() {
      const reports = await api.UserIndex(userPageID);
      if (reports && reports.length) {
        return reports;
      }
      return [];
    },
    // 既存のマスタデータとCSVデータを比較して、更新、新規、削除のデータを取得
    getSendUserReports(getuserReports, csvReports, subFIDs = []) {
      const result = { updateReports: [], createReports: [], deleteReports: [] };
      // 更新と削除しないユーザーの社員番号を取得
      const notUpdateAndDeleteUsersCode = [];
      getuserReports.forEach((report) => {
        if (report.Recodes.FID13380 && report.Recodes.FID13380.value == "true") {
          notUpdateAndDeleteUsersCode.push(report.Recodes.FID0.name);
        }
      });
      const targetUsers = getuserReports.filter((report) => {
        return !notUpdateAndDeleteUsersCode.includes(report.Recodes.FID0.name);
      });
      const targetCsvReports = csvReports.filter((report) => {
        return !notUpdateAndDeleteUsersCode.includes(report.Recodes.FID0.name);
      });
      targetUsers.forEach((report) => {
        const csvReport = targetCsvReports.find((csvReport) => {
          return this.isSameUser(report, csvReport);
        });
        if (csvReport) {
          result.updateReports.push(this.getUpdateUserReport(report, csvReport, subFIDs));
        } else {
          result.deleteReports.push(report);
        }
      });
      targetCsvReports.forEach((csvReport) => {
        const report = targetUsers.find((report) => {
          return this.isSameUser(report, csvReport);
        });
        if (!report) {
          result.createReports.push(csvReport);
        }
      });
      return result;
    },
    isSameUser(report, csvReport) {
      return report.Recodes.FID0.name == csvReport.Recodes.FID0.name;
    },
    getUpdateUserReport(report, csvReport, subFIDs = []) {
      const updateReport = JSON.parse(JSON.stringify(report));
      Object.keys(csvReport.Recodes).forEach((key) => {
        if (subFIDs.includes(key)) {
          // サブレポートの削除と追加
          updateReport.DeleteSubReports.push(...report.Recodes[key].subReports);
          updateReport.Recodes[key].subReports = csvReport.Recodes[key].subReports;
        } else if (key == "FID0") {
          updateReport.Recodes[key].attribute = csvReport.Recodes[key].attribute;
          updateReport.Recodes[key].role = csvReport.Recodes[key].role;
        } else {
          updateReport.Recodes[key].value = csvReport.Recodes[key].value;
        }
      });
      return updateReport;
    },
    async send() {
      const isCorrectValue = this.checkValidation();
      if (isCorrectValue == false) return;
      this.isLoading = true;
      const [getReports, getUserReports] = await Promise.all([this.getReports(), this.getUsers()]);
      this.isLoading = false;
      const primaryKeys = this.data.KeyID.map((key) => "FID" + key);
      const subReportFID = "FID13326";
      const getSendReports = this.getSendReports(getReports, this.importSSDatas, primaryKeys, [subReportFID]);
      const createCount = getSendReports.createReports.length;
      const updateCount = getSendReports.updateReports.length;
      const deleteCount = getSendReports.deleteReports.length;
      //
      const getSendUSerReports = this.getSendUserReports(getUserReports, this.importUserDatas);
      const createUserCount = getSendUSerReports.createReports.length;
      const updateUserCount = getSendUSerReports.updateReports.length;
      const deleteUserCount = getSendUSerReports.deleteReports.length;
      console.log("createReports", createUserCount, getSendUSerReports.createReports);
      console.log("updateReports", updateUserCount, getSendUSerReports.updateReports);
      console.log("deleteReports", deleteUserCount, getSendUSerReports.deleteReports);
      await this.$nextTick();
      let message = `SSマスタとユーザーマスタの登録を行いますか？\n`;
      message += `■SSマスタ\n新規登録：${createCount}件\n更新：${updateCount}件\n削除：${deleteCount}件\n\n`;
      message += `■ユーザーマスタ\n新規登録：${createUserCount}件\n更新：${updateUserCount}件\n削除：${deleteUserCount}件`;
      if (!confirm(message)) {
        return;
      }

      this.isLoading = true;
      // TODO: Promise.allを使用して通信速度を早くするかは検討（サーバーに負荷がかかる可能性あり）
      // SSマスタ新規
      for (const createReport of getSendReports.createReports) {
        const result = await api.SendReport(createReport);
        if (result == false || result.result == "Fail") {
          alert("SSマスタ登録失敗[Error0830-1]");
          this.isLoading = false;
          return;
        }
      }
      // SSマスタ更新
      for (const updateReport of getSendReports.updateReports) {
        const result = await api.SendReport(updateReport);
        if (result == false || result.result == "Fail") {
          alert("SSマスタ更新失敗[Error0830-1]");
          this.isLoading = false;
          return;
        }
      }
      // SSマスタ削除
      for (const deleteReport of getSendReports.deleteReports) {
        const result = await api.DelReport(deleteReport);
        if (result == false || result.result == "Fail") {
          alert("SSマスタ削除失敗[Error0830-1]");
          this.isLoading = false;
          return;
        }
      }
      // ユーザー新規
      for (const createUser of getSendUSerReports.createReports) {
        const result = await api.CreateEmployee(createUser);
        if (!result || result.status != "sucsess") {
          alert("ユーザーマスタ登録失敗[Error0830-1]");
          this.isLoading = false;
          return;
        }
      }
      // ユーザー更新
      for (const updateUser of getSendUSerReports.updateReports) {
        const result = await api.UpdateEmployee(updateUser);
        if (!result || result.status != "sucsess") {
          alert("ユーザーマスタ更新失敗[Error0830-1]");
          this.isLoading = false;
          return;
        }
      }
      // ユーザー削除
      for (const deleteUser of getSendUSerReports.deleteReports) {
        const result = await api.DeleteEmployee(deleteUser);
        if (!result || result.status != "sucsess") {
          alert("ユーザ削除エラー:" + result.errors);
          this.isLoading = false;
          return;
        }
      }
      alert("登録完了");
      this.isLoading = false;
      history.back();
    },
    getSubReportSMGNames(subReports) {
      let smg = "";
      if (!subReports || !subReports.length) return smg;
      subReports.forEach((subReport, index) => {
        if (index) {
          smg += ", ";
        }
        smg += subReport.Recodes.FID13316.value;
      });
      return smg;
    },
    RecodeCopy(data, response) {
      for (const [FID] of Object.entries(data.Recodes)) {
        if (typeof response[FID] != "undefined") {
          data.Recodes[FID].value = response[FID].value;
        }
        if (
          typeof response[FID] != "undefined" &&
          typeof response[FID].subReports != "undefined" &&
          response[FID].subReports != null
        ) {
          data.Recodes[FID].subReports = response[FID].subReports;
        }
      }
    },
  },
};
</script>
