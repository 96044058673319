import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/vuex.js";
import goTo from "vuetify/lib/services/goto";
// Amplify読み込み
//AccessTokenの取得
import { Amplify, Auth, Hub } from "aws-amplify";
//
import { path } from "./path";
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    //バリデーション失敗時の自動スクロール
    let scrollTo = 0;
    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }
    return goTo(scrollTo);
  },
  routes: path,
});
//api通信
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");
const eneosPatrol = RepositoryFactory.get("eneosPatorl");

Vue.use(VueRouter);
let user;
let authState;
let IPaddress = "";
const customer = process.env.VUE_APP_NAME;

function getUser() {
  return Amplify.Auth.currentAuthenticatedUser()
    .then((data) => {
      if (data && data.signInUserSession) {
        store.commit("setUser", data);
        store.commit("setAuth", true);
        user = data;
        return data;
      }
    })
    .catch(() => {
      user = null;
      store.commit("setUser", null);
      store.commit("logOut");
      return null;
    });
}

async function signOut() {
  user = null;
  await store.commit("logOut");
  await Auth.signOut();
  location.href = process.env.BASE_URL + "/login";
}

async function getAccessToken() {
  const res1 = Auth.currentAuthenticatedUser();
  const res2 = Auth.currentSession();
  const [cognitoUser, currentSession] = await Promise.all([res1, res2]);
  await refreshToken(cognitoUser, currentSession);
}

function refreshToken(cognitoUser, currentSession) {
  return new Promise((resolve) => {
    cognitoUser.refreshSession(currentSession.refreshToken, (err, res) => {
      const { accessToken } = res;
      store.commit("setAccessToken", accessToken.jwtToken);
      resolve();
    });
  });
}

// ログイン状態管理
Hub.listen("auth", async (info) => {
  const state = info.payload.event;
  if (state === "signIn") {
    let profile;
    try {
      //先にアクセス情報を付与しないとAPIが弾く
      await getUser();
      await getAccessToken();
      //DB上にデータが存在するか確認
      profile = await api.GetRole();
      //await getCredentialID();
      store.commit("setRole", profile.Role);
    } catch (e) {
      await signOut();
      console.log("apiエラー", e);
      alert("ログインに失敗しました[code]1209:" + e);
      location.href = process.env.BASE_URL + "/login";
      return;
    }
  } else if (state === "signOut") {
    store.commit("logOut");
  } else if (state === "configured") {
    console.log("Hub.listen configured: user == null", user == null);
    //
    //
  } else if (state === "tokenRefresh_failure") {
    alert("ログインタイムアウト");
    await signOut();
    let url = process.env.BASE_URL + "login";
    if (url.includes("//")) {
      url = url.replace("//", "/");
    }
    location.href = url;
  } else if (state === "signIn_failure") {
    console.log("signIn_failure");
  } else if (state === "tokenRefresh") {
    //console.log('tokenRefresh')
    return;
  } else {
    console.log("Hub.listen other type", state);
  }
});

router.beforeEach(async (to, from, next) => {
  if (user === null || typeof user === "undefined") {
    await getUser();
  }
  const auth = user === null || typeof user === "undefined" ? false : true;
  //遷移先が Login以外で認証falseならログインに飛ぶ
  if (to.name !== "Login" && !auth) {
    return next({
      path: "/login",
    });
  }
  //遷移先が Loginで認証 trueならHomeに飛ぶ
  if (to.name === "Login" && auth && store.state.auth) {
    return next({
      path: "/",
    });
  }
  //リダイレクト時に強制的にHomeへ
  if (auth && to.path !== "/" && from.name === null) {
    return next({
      path: "/",
    });
  }
  //サインアウト処理
  if (!store.state.auth) {
    await Auth.signOut();
  }
  //通常遷移
  next();
});

// リダイレクト設定
router.beforeResolve(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    await getAccessToken();
    //cognitoグループが存在するか
    if (
      typeof user.signInUserSession.accessToken.payload["cognito:groups"] ===
      "undefined"
    ) {
      //await signOut();
      alert("ログインに失敗しました[code]no group");
      //location.href = process.env.BASE_URL + "/login";
      return;
    }
    //特定グループに所属しているか確認
    const group = user.signInUserSession.accessToken.payload[
      "cognito:groups"
    ].filter((word) => word == customer);
    if (!user || group.length == 0) {
      await signOut();
      alert("ログインに失敗しました[code]1211");
      location.href = process.env.BASE_URL + "/login";
      return;
    }
    //IP authがfail&メールアドレスが未検証の場合、ログアウト
    if (user == null) {
      const tmp = await Auth.currentAuthenticatedUser();
      user = tmp;
    }
    //
    //console.log("beforeResolve:1", user);
    // console.log("beforeResolve:2", user.attributes);
    // console.log("beforeResolve:3", typeof user.attributes.email_verified);
    // console.log("beforeResolve:4", store.state.IPauth);
    if (
      typeof store.state.IPauth == "undefined" ||
      store.state.IPauth == false
    ) {
      try {
        const inHouse = await eneosPatrol.GetIP(true);
        // console.log("beforeResolve:5", inHouse);
        store.commit("setIPauth", inHouse);
      } catch (e) {
        // console.log("beforeResolve:5b");
        store.commit("setIPauth", false);
      }
    }
    // console.log(
    //   "beforeResolve:6",
    //   store.state.IPauth,
    //   typeof user.attributes.email_verified == "undefined",
    //   user.attributes.email_verified == false
    // );
    if (
      !store.state.IPauth &&
      (typeof user.attributes.email_verified == "undefined" ||
        user.attributes.email_verified == false)
    ) {
      console.log("再ログインを行ってください:1", store.state.IPauth);
      console.log(
        "再ログインを行ってください:2",
        typeof user.attributes.email_verified
      );
      alert("再ログインを行ってください");
      await signOut();
      return;
    }
    return next();
  }
  return next();
});

router.signIn = async function (username, password) {
  try {
    let hint = "";
    //APIからIP取得
    let resIP = await eneosPatrol.GetIPString();
    IPaddress = resIP.message;
    //clientMetadataにIPアドレス情報を追加
    Auth.configure({
      authenticationFlowType: "CUSTOM_AUTH",
      clientMetadata: {
        IpAddress: IPaddress,
      },
    });
    //固定IPの確認
    await eneosPatrol.GetIP(true);
    //ログイン処理
    let flag = false;
    const res = await Auth.signIn(username, password);
    //console.log("ログイン処理 challengeName:", res.challengeName);
    user = res;
    //検証コードの確認
    if (res.challengeName === "CUSTOM_CHALLENGE") {
      authState = "challenge";
      flag = true;
    }
    //パスワードの強制変更
    if (res.challengeName == "NEW_PASSWORD_REQUIRED") {
      //パスワードの新規作成
      authState = "newPassword";
      flag = true;
    }
    //メールアドレスの未登録
    if (
      typeof res.challengeName == "undefined" &&
      typeof user.attributes.email == "undefined"
    ) {
      authState = "mail_register";
      flag = true;
    }
    //メールアドレスの初回検証
    if (
      typeof res.challengeName == "undefined" &&
      typeof user.attributes.email != "undefined" &&
      user.attributes.email_verified == false
    ) {
      //メールアドレス送信処理
      await Auth.verifyUserAttribute(user, "email"); //初回検証メールの送信
      authState = "email_verified";
      flag = true;
    }
    //ログイン成功(社内かメールアドレス検証済)
    if (
      typeof res.challengeName == "undefined" &&
      (store.state.IPauth == true ||
        (typeof user.attributes.email != "undefined" &&
          user.attributes.email_verified == true))
    ) {
      authState = "signedin";
      await router.successLogin();
      store.commit("setAuth", true);
      flag = true;
    }
    //どのステータスにもない
    if (!flag) {
      console.log("----どのステータスにもない---");
      console.log("user", user == null);
      console.log("res.challengeName", res.challengeName);
      console.log("store.state.IPauth", store.state.IPauth);
      if (user != null) {
        console.log(" user.attributes.email", user.attributes.email);
        console.log(
          "user.attributes.email_verified",
          user.attributes.email_verified
        );
      }
    }
    return [user, hint, authState];
  } catch (err) {
    let hint = "";
    console.log("router.signIn err:", err);
    if (authState == "challenge" && err.name == "NotAuthorizedException") {
      hint = "検証コードが異なります";
    }
    if (err.name == "NotAuthorizedException") {
      hint = "社員番号かパスワードが異なります";
    } else {
      hint = "ログインに失敗しました";
    }
    return [user, hint, authState];
  }
};

function wait(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

//パスワードの強制変更
router.firstResetPassword = async function (new_password) {
  try {
    await Auth.completeNewPassword(user, new_password, {});
    return "signedin";
  } catch (e) {
    console.log("firstPassword", e);
    if (
      e ==
      "InvalidPasswordException: Password does not conform to policy: Password must have symbol characters"
    ) {
      alert("パスワードの条件を満たしていません");
    } else {
      console.log("e", e);
      alert("パスワードの変更に失敗しました[code]2211");
    }
    //await signOut();
    return "";
  }
};
//メールアドレスの登録
router.registerMail = async function (mail) {
  const tmp = await Auth.currentAuthenticatedUser();
  const result = await Auth.updateUserAttributes(tmp, {
    email: mail,
  });
  if (result == "SUCCESS") {
    return [true, "メールアドレスの登録に成功"];
  } else {
    return [true, "検証に失敗しました"];
  }
};

//検証コードの再送(認証済)
router.resend = async function (username) {
  try {
    const res = await Auth.resendSignUp(username);
    console.log("resend", res);
    return "challenge";
  } catch (e) {
    console.log("resend error", e);
    alert("再送の上限を超えました");
    return "email_verified";
  }
};

//メールアドレスの検証(初回)
router.verifyMail = async function (code) {
  try {
    const result = await Auth.verifyCurrentUserAttributeSubmit(
      "email",
      String(code)
    );
    console.log("verifyMail", result);
    if (result == "SUCCESS") {
      return ["", ""];
    }
    return ["emai_verified", ""];
  } catch (e) {
    console.log("router.verifyMail:4", e);
    return ["email_verified", "検証コードが異なります"];
  }
};

//2段階検証
router.verify = async function (name, verficationCode) {
  let hint = "";
  try {
    //
    await wait(500);
    await Auth.sendCustomChallengeAnswer(user, verficationCode);
    await wait(500);
    const res = await Auth.sendCustomChallengeAnswer(user, verficationCode);
    user = res;
    authState = "signedin";
    await router.successLogin();
  } catch (err) {
    if (err.name == "NotAuthorizedException") {
      hint = "入力内容に間違いがあります";
    } else {
      hint = "ログインに失敗しました";
    }
    console.log("router.verify Err", err);
  }
  return [authState, hint];
};

//パスワードを忘れた場合
router.newPassword = async function (name, newPassword) {
  await Auth.completeNewPassword(name, newPassword, {});
};

//パスワードを忘れた場合
router.resetPassword = async function (username, code, newPassword) {
  try {
    const data = await Auth.forgotPasswordSubmit(username, code, newPassword);
    console.log("resetPassword", data);
    return ["", "成功"];
  } catch (err) {
    console.log(err);
    return ["forgotPassword", "パスワードリセットに失敗"];
  }
};

//ログイン成功時
router.successLogin = async function () {
  let profile;
  try {
    //先にアクセス情報を付与しないとAPIが弾く
    await getUser();
    await getAccessToken();
    await eneosPatrol.GetIP(); //固定IPか確認する
    //DB上にデータが存在するか確認
    profile = await api.GetRole();
    //await getCredentialID();
    store.commit("setRole", profile.Role);
  } catch (e) {
    console.log("apiエラー", e);
    await signOut();
    alert("ログインに失敗しました[code]2209:" + e);
    location.href = process.env.BASE_URL + "/login";
    return;
  }
};
//ユーザの確認
router.checkLogin = async function () {
  const currentUserInfo = await Auth.currentUserInfo();
  if (currentUserInfo === null) {
    await signOut();
  }
};

export default router;
