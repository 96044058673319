<template>
  <div class="body">
    <v-row v-if="!homeflag">
      <v-col align="right" class="button"
        ><v-btn @click="back" :disabled="backflag">戻る</v-btn></v-col
      >
    </v-row>
    <p align="center" class="title">選択・ホーム画面</p>
    <v-row
      justify="center"
      v-for="links2 in links"
      :key="links2.text"
      class="home mb-2"
      align-content="center"
    >
      <div v-for="link in links2" :key="link.text" class="home">
        <v-btn
          @click="move(link.to)"
          v-if="link.rule"
          :height="height"
          :width="width"
          class="white--text"
          :color="link.color"
          >{{ link.text }}</v-btn
        >
      </div>
    </v-row>
  </div>
</template>
<script>
import store from "../store/vuex.js";
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");

// 画面デザインや色などはCSSで調整
export default {
  name: "Home",
  components: {},
  data: () => ({
    links: [],
    Role: 0,
    homeflag: false,
    height: 100,
    width: 250,
    path: "",
    backflag: false,
  }),
  height: function () {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return 100;
      case "sm":
        return 100;
      case "md":
        return 100;
      case "lg":
        return 100;
      case "xl":
        return 100;
    }
    return 100;
  },
  width: function () {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return 250;
      case "sm":
        return 250;
      case "md":
        return 250;
      case "lg":
        return 250;
      case "xl":
        return 250;
    }
    return 250;
  },
  display: function () {
    const displayheight = this.$vuetify.breakpoint.height;
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return this.role != 1 && displayheight > 400
          ? "height: " + 480 + "px;"
          : null;
      case "sm":
        return this.role != 1 && displayheight > 400
          ? "height: " + 480 + "px;"
          : null;
    }
    return null;
  },
  async mounted() {
    if (!this.$store.state.auth) {
      this.$router.push("/login");
    }
    this.path = this.$route.path;
    if (this.$route.path == "/") {
      this.homeflag = true;
      const result = await api.EmployeeDetail();
      store.commit("setParams", result.Recodes);
    }
    store.commit("clearreports");
    const arrayLength = 3 + 1;
    this.links = Array.from({ length: arrayLength }, () => []);
    const result = await api.GetRole();
    this.Role = result.Role;
    store.commit("setRole", result.Role);
    this.links[0].push({
      to: "/patrolReport/index",
      text: "巡回レポート一覧",
      level: 0,
      color: "light-blue lighten-2",
      rule: false,
    });
    this.links[1].push({
      to: "/moneyCheck/index",
      text: "金銭点検表一覧",
      level: 2,
      color: "grey",
      rule: false,
    });
    this.links[2].push({
      to: "/stockCheck/index",
      text: "在庫差異確認表一覧",
      level: 2,
      color: "grey",
      rule: false,
    });
    this.links[3].push({
      to: "/setting",
      text: "設定",
      level: 2,
      color: "grey",
      rule: false,
    });

    for (let i = 0; i < this.links.length; i++) {
      this.links[i].forEach((l, index) => {
        if (l.level == 0 || l.level == this.Role || this.Role == "1") {
          this.links[i][index].rule = true;
        }
      });
      this.links[i] = this.links[i].filter((l) => {
        return l.rule;
      });
    }
  },
  methods: {
    move(link) {
      this.$router.push(link, () => {});
    },
    back() {
      if (this.$route.path == this.path) {
        history.back();
        this.backflag = true;
      } else {
        alert("読み込みエラー:ホームに戻ります");
        this.$router.push("/", () => {});
      }
      //history.back();
    },
  },
};
</script>
<style>
.selectbox {
  margin: 4px;
}
</style>
<style>
.home {
  margin: 8px;
}
</style>
