<template>
  <div class="home">
    <!-- ナビメニュー -->
    <v-navigation-drawer app clipped>
      <patrolReportNavigationMenu
        v-on:patrolReport="moveToPatrolReport"
        v-on:moneyCheck="moveToMoneyCheck"
        v-on:stockCheck="moveToStockCheck"
      >
      </patrolReportNavigationMenu>
    </v-navigation-drawer>
    <p>在庫差異確認表</p>
    <v-row>
      <v-col align="right">
        <v-btn @click.once="back"> 戻る </v-btn>
      </v-col>
    </v-row>
    <v-container cols="12">
      <div v-if="isLoading" align="center">
        <v-progress-circular
          :size="250"
          color="primary"
          indeterminate
          align="center"
        ></v-progress-circular>
      </div>
      <div v-else>
        <v-form ref="form" @submit.prevent>
          <v-row>
            <div v-show="false">
              {{ calc11932sp(data.Recodes.FID11932, data.Recodes) }}
            </div>
          </v-row>
          <v-row>
            <v-col cols="6" sm="6" md="4" lg="4">
              <v-text-field
                label="実施日"
                v-model="data.Recodes.FID11706.value"
                :disabled="data.Recodes.FID11706.disabled"
                background-color="white"
                id="FID11706"
                class="textfield11706"
                outlined
                readonly
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4">
              <v-text-field
                label="SSコード"
                v-model="data.Recodes.FID11707.value"
                :disabled="data.Recodes.FID11707.disabled"
                background-color="white"
                id="FID11707"
                class="textfield11707"
                outlined
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4">
              <v-text-field
                label="直営事業部名"
                v-model="data.Recodes.FID11708.value"
                :disabled="data.Recodes.FID11708.disabled"
                background-color="white"
                id="FID11708"
                class="textfield11708"
                outlined
                readonly
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="4" lg="4">
              <v-text-field
                label="SS名"
                v-model="data.Recodes.FID11709.value"
                :disabled="data.Recodes.FID11709.disabled"
                background-color="white"
                id="FID11709"
                class="textfield11709"
                outlined
                readonly
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4">
              <v-text-field
                label="確認者名"
                v-model="data.Recodes.FID11710.value"
                disabled
                background-color="white"
                id="FID11710"
                class="userInfo11710"
                readonly
                outlined
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4">
              <v-text-field
                label="対応者名"
                v-model="data.Recodes.FID11711.value"
                disabled
                background-color="white"
                id="FID11711"
                class="userInfo11711"
                readonly
                outlined
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <patrolreportCreateSpecial
                label=""
                background-color="white"
                id="FID11863"
                class=""
                :data="data"
                :pageID="1656"
                hide-details="auto"
                ref="patrolreportCreateSpecial"
              ></patrolreportCreateSpecial>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="2"
              sm="2"
              md="2"
              lg="2"
              class="text-right align-self-center"
            >
              <div
                label=""
                background-color="white"
                id="FID11727"
                class="plainText11727 text-caption"
                hide-details="auto"
              >
                【1】
              </div>
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3">
              <v-text-field
                label=""
                v-model="data.Recodes.FID11730.value"
                :background-color="compClass11730"
                id="FID11730"
                class="textfield11730"
                outlined
                readonly
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="7" sm="7" md="7" lg="7" class="align-self-center">
              <div
                label=""
                background-color="white"
                id="FID11718"
                class="plainText11718 text-caption"
                hide-details="auto"
              >
                出荷差異が8％±1ℓを超過した場合
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="2"
              sm="2"
              md="2"
              lg="2"
              class="text-right align-self-center"
            >
              <div
                label=""
                background-color="white"
                id="FID11728"
                class="plainText11728 text-caption"
                hide-details="auto"
              >
                【2】
              </div>
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3">
              <v-text-field
                label=""
                v-model="data.Recodes.FID11719.value"
                :background-color="compClass11719"
                id="FID11719"
                class="textfield11719"
                outlined
                readonly
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="7" sm="7" md="7" lg="7" class="align-self-center">
              <div
                label=""
                background-color="white"
                id="FID11720"
                class="plainText11720 text-caption"
                hide-details="auto"
              >
                出荷差異が15％を超過した場合
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="2"
              sm="2"
              md="2"
              lg="2"
              class="text-right align-self-center"
            >
              <div
                label=""
                background-color="white"
                id="FID11729"
                class="plainText11729 text-caption"
                hide-details="auto"
              >
                【3】
              </div>
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3">
              <v-text-field
                label=""
                v-model="data.Recodes.FID11721.value"
                :background-color="compClass11721"
                id="FID11721"
                class="textfield11721"
                outlined
                readonly
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="7" sm="7" md="7" lg="7" class="align-self-center">
              <div
                label=""
                background-color="white"
                id="FID11722"
                class="plainText11722 text-caption"
                hide-details="auto"
              >
                出荷差異が8％±1ℓを超過し、かつ15％を超過した場合
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <div
                label=""
                background-color="white"
                id="FID11723"
                class="plainText11723 text-caption"
                hide-details="auto"
              >
                ※上記基準はオイル差異に適用。タイヤ・バッテリーは差異を×と表示
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <subPageStockCheck
                label=""
                background-color="white"
                id="FID11753"
                class="tableSubPage11753"
                ref="subPage11753"
                :set-sub-reports="data.Recodes.FID11753.subReports"
                :max="20"
                :min="1"
                @update:subReports="
                  (newData) => (data.Recodes.FID11753.subReports = newData)
                "
                hide-details="auto"
              ></subPageStockCheck>
            </v-col>
          </v-row>
          <v-row> </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-btn
                label="登録"
                :disabled="data.Recodes.FID11756.disabled || allDisabled11679"
                background-color="white"
                id="FID11756"
                class="button11756"
                @click="vtnModal11756 = true"
                color="primary"
                block
                style="font-size: 20px; letter-spacing: 10px"
                height="50"
                hide-details="auto"
              >
                登録
              </v-btn>
              <v-dialog v-model="vtnModal11756" max-width="400">
                <v-card>
                  <v-card-title class="text-h5">
                    現在の入力内容で登録を完了してもよろしいでしょうか？
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="mr-3"
                      color="green darken-1 white--text"
                      @click="onBtn11756(), (vtnModal11756 = false)"
                      large
                    >
                      はい
                    </v-btn>
                    <v-btn
                      color="red darken-1 white--text"
                      @click="vtnModal11756 = false"
                      large
                    >
                      いいえ
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col cols="6" sm="6" md="4" lg="4">
              <v-btn
                label="▲"
                :disabled="data.Recodes.FID12269.disabled"
                background-color="white"
                id="FID12269"
                class="button12269"
                @click="onBtn12269"
                color="light-blue"
                small
                fixed
                :style="'right: 50px; bottom: 55px; z-index: 99'"
                hide-details="auto"
              >
                ▲
              </v-btn>
            </v-col>
            <v-col cols="auto" sm="auto" md="auto" lg="auto">
              <v-btn
                label="▼"
                background-color="white"
                id="FID13152"
                class="button13152"
                @click="onBtn13152"
                color="light-blue"
                small
                fixed
                :style="'right: 100px; bottom: 55px; z-index: 99'"
                hide-details="auto"
              >
                ▼
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="left">
              <v-btn
                @click="deleteModal = true"
                :disabled="data.ReportID == null || allDisabled11679"
                color="red white--text"
              >
                削除
              </v-btn>
              <v-dialog v-model="deleteModal" max-width="400">
                <v-card>
                  <v-card-title class="text-h5"> 削除しますか？ </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="mr-3"
                      color="green darken-1 white--text"
                      @click="deleteModal = false"
                      large
                    >
                      いいえ
                    </v-btn>
                    <v-btn color="red darken-1 white--text" @click="del" large>
                      はい
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col v-if="showTemplate11755">
              <v-btn
                @click="save11755()"
                color="yellow"
                :disabled="allDisabled11679"
                fixed
                bottom
                :style="'right:+50px; z-index: 99'"
              >
                一時保存
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-container>
  </div>
</template>

<script>
import store from "../store/vuex.js";
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");

import { stockStatusCheck } from "./specialMethod";
import patrolreportCreateSpecial from "../views/patrolreportCreateSpecial";
import subPageStockCheck from "../views/subPageStockCheck";
import { updatePatrolreport } from "./specialMethod";
import patrolReportNavigationMenu from "../views/patrolReportNavigationMenu";

export default {
  name: "Home",
  components: {
    patrolreportCreateSpecial,
    subPageStockCheck,
    patrolReportNavigationMenu,
  },
  data() {
    return {
      isLoading: false,
      escapeValidation: false,
      showChild: true,
      subInit: false,
      disabled: false,
      data: {
        PageID: 1656,
        ReportID: null, //新規ならnull、更新ならidがある
        Recodes: {
          FID11932: { value: "", formType: 1, disabled: false },
          FID12220: { value: "", formType: 1, disabled: false },
          FID11706: { value: "", formType: 1, disabled: true },
          FID11707: { value: "", formType: 1, disabled: true },
          FID11708: { value: "", formType: 1, disabled: true },
          FID11709: { value: "", formType: 1, disabled: true },
          FID11710: { value: "", formType: 18, disabled: false },
          FID11711: { value: "", formType: 18, disabled: false },
          FID11870: { value: "", formType: 1, disabled: false },
          FID11730: { value: "×", formType: 1, disabled: false },
          FID11719: { value: "×1", formType: 1, disabled: false },
          FID11721: { value: "×2", formType: 1, disabled: false },
          FID11753: {
            value: "PID1670",
            formType: 17,
            disabled: false,
            subReports: [],
          },
          FID11843: { value: "", formType: 1, disabled: false },
          FID11755: { value: "", formType: 20, disabled: false },
          FID11756: { value: "", formType: 16, disabled: true },
          FID12269: { value: "", formType: 16, disabled: false },
          FID13152: { value: "", formType: 16, disabled: false },
          FID13379: { value: "", formType: 1, disabled: false }, //事業部コード
        },
        DeleteSubReports: [],
      },
      masterData11754: {},
      tmp11754: ["", ""],
      vtnModal11756: false,

      rules: {},
      deleteModal: false,
      copyFlag: false,
      sendCopyFlag: false,
      noBack: false,
      backCount: -1,
      init: true,
    };
  },
  async created() {
    await this.getInit();
  },

  computed: {
    compClass11730: function () {
      return "yellow lighten-2";
    },
    compClass11719: function () {
      return "amber";
    },
    compClass11721: function () {
      return "pink lighten-4";
    },
    showTemplate11755() {
      if (this.$store.state.role == 1) {
        return true;
      }
      if (this.data.Recodes.FID11756.value == "") {
        return true;
      }
      return false;
    },
    allDisabled11679() {
      // 管理者とグループ管理者は入力可能
      if (this.$store.state.role == 1 || this.$store.state.role == 2) {
        return false;
      }
      return true;
    },
    userRole() {
      let role = "一般";
      if (this.$store.state.role == 1) {
        role = "管理者";
      } else if (this.$store.state.role == 2) {
        role = "グループ管理者";
      }
      return role;
    },
  },
  watch: {
    $route() {
      this.getInit();
    },
    data: {
      handler: async function (data) {
        if (
          this.tmp11754[0] != data.Recodes.FID12220.value ||
          this.tmp11754[1] != data.Recodes.FID11707.value
        ) {
          this.masterData11754 = await this.getMasterData11754();
        }
        this.tmp11754[0] = data.Recodes.FID12220.value;
        this.tmp11754[1] = data.Recodes.FID11707.value;

        if (
          data.Recodes.FID11711.value != "" &&
          (data.Recodes.FID11756.value == "" ||
            data.Recodes.FID11756.value == null)
        ) {
          this.data.Recodes.FID11756.disabled = false;
        } else {
          this.data.Recodes.FID11756.disabled = true;
        }
      },
      deep: true,
    },

    masterData11754: {
      deep: true,
      handler: async function (newData) {
        if (
          typeof newData.Recodes.FID11488 != "undefined" &&
          this.data.ReportID == null
        ) {
          this.data.Recodes.FID12220.value = newData.Recodes.FID11488.value;
        }
        if (
          typeof newData.Recodes.FID11486 != "undefined" &&
          this.data.ReportID == null
        ) {
          this.data.Recodes.FID11707.value = newData.Recodes.FID11486.value;
        }
        if (typeof this.masterData11754 == "object") {
          this.autoAddSubpage11754(this.masterData11754);
        }
      },
    },
  },
  methods: {
    async getInit() {
      try {
        //初期処理
        this.isLoading = true;
        //
        if (this.$route.query.copyFlag) {
          this.reportCopy();
        } else if (this.$route.params.report_id != null) {
          this.data.ReportID = Number(this.$route.params.report_id);
          //データ取得
          let data = {
            PageID: this.data.PageID,
            ReportID: Number(this.data.ReportID),
          };
          const result = await api.ReportDetailV2(data);
          this.RecodeCopy(this.data, result.Recodes);
          //初期処理
        }
        //データ取得処理
        this.masterData11754 = await this.getMasterData11754();

        //init処理
        this.initValues12220();
        this.initValues11706();
        this.initValues11707();
        this.initValues11708();
        this.initValues11709();
        this.initValues13379();
        this.initFID11710();
        this.initValues11711();
        this.initValues11870();
        this.initSetDeleteFlagSubPage11753();
        this.initValues11843();
        this.initCheck11756();

        //読み込み終了
        this.isLoading = false;
      } catch (e) {
        console.log(e);
        alert("読み込みに失敗しました");
        this.back();
      }
    },
    RecodeCopy(data, response) {
      for (const [FID] of Object.entries(data.Recodes)) {
        if (typeof response[FID] != "undefined") {
          data.Recodes[FID].value = response[FID].value;
        }
        if (
          typeof response[FID] != "undefined" &&
          typeof response[FID].subReports != "undefined" &&
          response[FID].subReports != null
        ) {
          data.Recodes[FID].subReports = response[FID].subReports;
        }
      }
    },
    calc11932sp(target, Recodes) {
      let total = stockStatusCheck(target, Recodes);
      this.$set(target, "value", String(total));
      return total;
    },
    async getMasterData11754() {
      //
      let d = {
        PageID: 1665,
        orderBy: "",
        search: [],
      };
      //
      d.search.push({
        form_id: 11488,
        value: this.data.Recodes.FID12220.value,
        option: "match",
      });
      d.search.push({
        form_id: 11486,
        value: this.data.Recodes.FID11707.value,
        option: "match",
      });

      //apiからマスタデータの取得
      if (
        this.data.Recodes.FID12220.value != "" &&
        this.data.Recodes.FID12220.value != null &&
        this.data.Recodes.FID11707.value != "" &&
        this.data.Recodes.FID11707.value != null
      ) {
        try {
          let response = await api.GetMasterV2(d);
          if (response.data.length == 1) {
            response.data[0].PageID = 1665;
            return response.data[0];
          } else {
            console.log("マスタが複数見つかりました", response.data);
          }
        } catch (e) {
          console.log("e", e);
        }
      }
      return this.masterData11754;
    },
    autoAddSubpage11754() {
      let param0 = {};
      param0.subpage = this.$refs.subPage11753;
      param0.index = "FID11753";
      param0.masterData = this.masterData11754.Recodes;
      param0.subFormID = "FID11493";
      param0.pos = [
        { from: "FID11494", to: "FID11739" },
        { from: "FID11495", to: "FID11740" },
        { from: "FID11496", to: "FID11747" },
        { from: "FID11497", to: "FID11748" },
        { from: "FID11498", to: "FID11749" },
        { from: "FID11499", to: "FID11741" },
        { from: "FID11819", to: "FID11820" },
        { from: "FID11494", to: "FID12221" },
      ];
      this.autoAddMultipleSubpageTable(param0);
    },
    autoAddMultipleSubpageTable(param) {
      if (
        typeof param.subpage == "undefined" ||
        typeof param.masterData == "undefined" ||
        typeof param.masterData[param.subFormID] == "undefined"
      ) {
        return;
      }
      let wantReports = param.masterData[param.subFormID].subReports;
      // OTBがタイヤ,バッテリーのみに絞込み
      wantReports = wantReports.filter((report) => {
        return (
          report.Recodes.FID11819.value == "タイヤ" ||
          report.Recodes.FID11819.value == "バッテリー"
        );
      });

      //※減らす処理がない
      // マスタデータ取得したデータに紐付くサブレポートの数と、サブページのサブレポートの数を同じにする
      for (
        let i = param.subpage.subReports.length;
        i < wantReports.length;
        i++
      ) {
        param.subpage.subPageAdd();
      }
      for (
        let i = param.subpage.subReports.length - 1;
        i > wantReports.length - 1;
        i--
      ) {
        param.subpage.subReports[i].DeleteFlag = true;
      }

      //データ格納処理
      for (let i = 0; i < wantReports.length; i++) {
        // this.$nextTick(function () {
          param.subpage.subReports[i].DeleteFlag = false;
          param.pos.forEach((p) => {
            param.subpage.subReports[i].Recodes[p.to].value =
              wantReports[i].Recodes[p.from].value;
          });
        // });
      }
      this.initSetDeleteFlagSubPage11753()
    },
    getDateTime() {
      let date = new Date();
      let years = date.getFullYear().toString();
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let days = date.getDate().toString().padStart(2, "0");
      let hours = date.getHours().toString().padStart(2, "0");
      let minutes = date.getMinutes().toString().padStart(2, "0");
      let seconds = date.getSeconds().toString().padStart(2, "0");
      //
      let value =
        years +
        "-" +
        month +
        "-" +
        days +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds;
      if (value == " ") {
        return "";
      }
      return value;
    },
    async save11755() {
      this.data.Recodes.FID11755.value = this.getDateTime();
      //(N96)送信処理を追加

      if (!(await this.checkValidation())) {
        return;
      }
      this.escapeValidation = true;
      await updatePatrolreport(this.data); //特殊関数の呼び出し
      await this.send();
    },
    async onBtn11756() {
      let check = await this.checkValidation();
      if (!check) {
        return;
      }
      //処理を記載
      const date = this.getDateTime();
      this.data.Recodes.FID11756.value = date; //tag:日付+時間
      this.data.Recodes.FID11756.disabled = true; //1回だけ
      await this.save11755(); //送信処理
    },
    async onBtn12269() {
      //処理を記載
      this.goSelectorID("FID11706");
    },
    async onBtn13152() {
      //処理を記載
      this.goSelectorID("FID11756");
    },
    goSelectorID(id) {
      //要素が無いと移動できないため
      this.isLoading = false;
      this.$nextTick(() => {
        const elements = document.querySelectorAll("*");
        // idがある要素のidを配列に格納
        for (let i = 0; i < elements.length; i++) {
          if (elements[i].id == id) {
            this.$vuetify.goTo(elements[i], { offset: 100 });
            break;
          }
        }
      });
    },
    initValues12220() {
      if (
        this.data.Recodes.FID12220.value == "" &&
        typeof localStorage.getItem("kakanaiValueFID12222") != "undefined" &&
        localStorage.getItem("kakanaiValueFID12222") != null
      ) {
        this.data.Recodes.FID12220.value = localStorage.getItem(
          "kakanaiValueFID12222"
        );
        localStorage.removeItem("kakanaiValueFID12222");
      }
    },
    initValues11706() {
      if (
        this.data.Recodes.FID11706.value == "" &&
        typeof localStorage.getItem("kakanaiValueFID11758") != "undefined" &&
        localStorage.getItem("kakanaiValueFID11758") != null
      ) {
        this.data.Recodes.FID11706.value = localStorage.getItem(
          "kakanaiValueFID11758"
        );
        localStorage.removeItem("kakanaiValueFID11758");
      }
    },
    initValues11707() {
      if (
        this.data.Recodes.FID11707.value == "" &&
        typeof localStorage.getItem("kakanaiValueFID11763") != "undefined" &&
        localStorage.getItem("kakanaiValueFID11763") != null
      ) {
        this.data.Recodes.FID11707.value = localStorage.getItem(
          "kakanaiValueFID11763"
        );
        localStorage.removeItem("kakanaiValueFID11763");
      }
    },
    initValues11708() {
      if (
        this.data.Recodes.FID11708.value == "" &&
        typeof localStorage.getItem("kakanaiValueFID11844") != "undefined" &&
        localStorage.getItem("kakanaiValueFID11844") != null
      ) {
        this.data.Recodes.FID11708.value = localStorage.getItem(
          "kakanaiValueFID11844"
        );
        localStorage.removeItem("kakanaiValueFID11844");
      }
    },
    initValues11709() {
      if (
        this.data.Recodes.FID11709.value == "" &&
        typeof localStorage.getItem("kakanaiValueFID11767") != "undefined" &&
        localStorage.getItem("kakanaiValueFID11767") != null
      ) {
        this.data.Recodes.FID11709.value = localStorage.getItem(
          "kakanaiValueFID11767"
        );
        localStorage.removeItem("kakanaiValueFID11767");
      }
    },
    initValues13379() {
      if (
        this.data.Recodes.FID13379.value == "" &&
        typeof localStorage.getItem("kakanaiValueFID13155") != "undefined" &&
        localStorage.getItem("kakanaiValueFID13155") != null
      ) {
        this.data.Recodes.FID13379.value = localStorage.getItem(
          "kakanaiValueFID13155"
        );
        localStorage.removeItem("kakanaiValueFID13155");
      }
    },
    initFID11710() {
      this.data.Recodes.FID11710.value = this.$store.state.user.attributes.name;
    },
    initValues11711() {
      if (
        this.data.Recodes.FID11711.value == "" &&
        typeof localStorage.getItem("kakanaiValueFID11770") != "undefined" &&
        localStorage.getItem("kakanaiValueFID11770") != null
      ) {
        this.data.Recodes.FID11711.value = localStorage.getItem(
          "kakanaiValueFID11770"
        );
        localStorage.removeItem("kakanaiValueFID11770");
      }
    },
    initValues11870() {
      if (
        this.data.Recodes.FID11870.value == "" &&
        typeof localStorage.getItem("kakanaiValueFID11768") != "undefined" &&
        localStorage.getItem("kakanaiValueFID11768") != null
      ) {
        this.data.Recodes.FID11870.value = localStorage.getItem(
          "kakanaiValueFID11768"
        );
        localStorage.removeItem("kakanaiValueFID11768");
      }
    },
    initSetDeleteFlagSubPage11753() {
      const subPageFID = "FID11753";
      if (
        typeof this.data.Recodes[subPageFID] == "undefined" ||
        !this.data.Recodes[subPageFID].subReports
      ) {
        return;
      }
      if (!this.init) return
      this.data.Recodes[subPageFID].subReports.forEach((item) => {
        // 入荷累計
        const nowArrival =
          typeof item.Recodes.FID11748.value == "undefined" ||
          isNaN(item.Recodes.FID11748.value)
            ? 0
            : Number(item.Recodes.FID11748.value);
        // 入荷
        const arrival =
          typeof item.Recodes.FID11744.value == "undefined" ||
          isNaN(item.Recodes.FID11744.value)
            ? 0
            : Number(item.Recodes.FID11744.value);
        this.$set(item, "originArrival", String(nowArrival - arrival));
        // 出荷累計
        const nowShipping =
          typeof item.Recodes.FID11749.value == "undefined" ||
          isNaN(item.Recodes.FID11749.value)
            ? 0
            : Number(item.Recodes.FID11749.value);
        // 出荷
        const shipping =
          typeof item.Recodes.FID11745.value == "undefined" ||
          isNaN(item.Recodes.FID11745.value)
            ? 0
            : Number(item.Recodes.FID11745.value);
        this.$set(item, "originShipping", String(nowShipping - shipping));
        // 現時点在庫（前月繰越＋入荷累計ー出荷累計）
        // 前月繰越
        const carryOver =
          typeof item.Recodes.FID11747.value == "undefined" ||
          isNaN(item.Recodes.FID11747.value)
            ? 0
            : Number(item.Recodes.FID11747.value);
        this.$set(item, "originNowStock", String(carryOver + (nowArrival - arrival) - (nowShipping - shipping)));

        if ("DeleteFlag" in item) return;
        this.$set(item, "DeleteFlag", false);
        this.init = false
      });
    },
    initValues11843() {
      if (
        this.data.Recodes.FID11843.value == "" &&
        typeof localStorage.getItem("kakanaiValueFID11841") != "undefined" &&
        localStorage.getItem("kakanaiValueFID11841") != null
      ) {
        this.data.Recodes.FID11843.value = localStorage.getItem(
          "kakanaiValueFID11841"
        );
        localStorage.removeItem("kakanaiValueFID11841");
      }
    },
    initCheck11756() {
      if (typeof this.data.Recodes.FID11756 == "undefined") {
        this.data.Recodes.FID11756 = { value: "", formType: 16 };
      }
      if (this.data.Recodes.FID11756.value != "") {
        this.data.Recodes.FID11756.disabled = true; //1回だけ
      }
    },
    sendBeforeDeleteSubPage11753() {
      const subPageFID = "FID11753";
      this.data.Recodes[subPageFID].subReports.forEach((item) => {
        if (item.DeleteFlag && item.ReportID) {
          this.data.DeleteSubReports.push(item);
        }
      });
      this.data.Recodes[subPageFID].subReports = this.data.Recodes[
        subPageFID
      ].subReports.filter((item) => !item.DeleteFlag);
    },

    checkValidation: async function () {
      let form = this.$refs.form;
      let check = form.validate();
      if (!check) {
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        return check;
      }

      return check;
    },
    async send() {
      const result = this.escapeValidation
        ? true
        : await this.checkValidation();

      if (result) {
        this.isLoading = true;
        this.sendBeforeDeleteSubPage11753();

        const save = await api.SendReport(this.data);
        if (save == false || save.result == "Fail") {
          alert("登録失敗[Error0830-1]");
          this.isLoading = false;
          return;
        }

        if (this.sendCopyFlag) {
          this.sendCopy();
          return;
        }
        alert("登録完了");
        if (!this.noBack) {
          this.back();
        } else {
          this.$set(this.$route.params, "report_id", save.reportID);
          this.$set(this.data, "ReportID", save.reportID);
          await this.getInit();
          this.isLoading = false;
          this.noBack = false;
        }
      }
    },
    back() {
      store.commit("clearreports");
      if (this.copyFlag) {
        history.go(this.backCount);
      } else {
        history.back();
      }
    },
    async del() {
      this.isLoading = true;
      await api.DelReport(this.data);
      alert("削除完了");
      this.back();
    },
    // ナビメニューからの遷移用
    moveToPatrolReport() {
      this.$refs.patrolreportCreateSpecial.movePatrolReport();
    },
    moveToMoneyCheck() {
      this.$refs.patrolreportCreateSpecial.moveMoneyCheck();
    },
    moveToStockCheck() {
      this.$refs.patrolreportCreateSpecial.moveStockCheck();
    },
  },
};
</script>
<style></style>
