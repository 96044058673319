import axios from "axios";
import store from "../store/vuex";

const baseDomain = process.env.VUE_APP_SEISEI_URL;

const baseURL = `${baseDomain}`;
const Repository = axios.create({
  baseURL: baseURL,
  timeout: 600000,
});

//const resource = "/api/eneosCar/v2";

let IPAuthResult = null;

export default {
  //---認証無し------------------------------------------------------
  async GetIPString() {
    const response = await this.API_Get("/get/ip");
    return response;
  },
  //
  async GetIP(force) {
    if (typeof force == "undefined" && IPAuthResult != null) {
      return IPAuthResult;
    }

    IPAuthResult = false;
    try {
      // const result = await this.API_Get("/api/eneosCar/checkip");
      // if (result.message == "fixIP") {
      //   IPAuthResult = true;
      //   await store.commit("setIPauth", true);
      // } else {
        IPAuthResult = false;
        await store.commit("setIPauth", false);
      // }
      return IPAuthResult;
    } catch (e) {
      console.log("GetIP 検証に失敗", e);
      IPAuthResult = false;
      await store.commit("setIPauth", false);
      return false;
    }
  },
  async API_Get(URL) {
    Repository.interceptors.request.use(function (config) {
      config.headers.Authorization = `Bearer ${store.state.accessToken}`;
      return config;
    });
    const { data } = await Repository.get(URL).catch(function (error) {
      if (error.isAxiosError) {
        if (error.code === "ECONNABORTED") {
          alert("エラーコード[E0002]セッション切断"); //APIへのリクエスト失敗
          return false;
        }
        alert("エラーコード[E0001]"); //APIへのリクエスト失敗
        return false;
      }
      const errorMessage = error.response.data || error.message;
      console.log("Get:", errorMessage);
    });
    return data;
  },
  async API_Post(data, URL) {
    let result = await Repository.post(URL, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${store.state.accessToken}`,
      },
      timeout: 600000,
    }).catch(function (error) {
      if (error.isAxiosError) {
        if (error.code === "ECONNABORTED") {
          alert("エラーコード[E0003]セッション切断"); //APIへのリクエスト失敗
          return false;
        }
        alert("エラーコード[E0004]"); //APIへのリクエスト失敗
        return false;
      }
      const errorMessage = error.response.data || error.message;
      console.log("post:", errorMessage);
      return false;
    });
    return result;
  },
};
