<template>
  <div class="body">
    <p align="center" class="title">金銭点検表一覧</p>
    <v-row>
      <v-col align="right" class="button">
        <v-btn @click="makeCSV12257" class="mr-5" color="orange white--text"
          >CSV出力</v-btn
        >
        <v-btn @click.once="back">戻る</v-btn>
      </v-col>
    </v-row>
    <div v-if="isLoading" align="center">
      <v-progress-circular
        :size="250"
        color="primary"
        indeterminate
        align="center"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-form ref="form" v-model="valid">
        <v-row class="ml-2 mr-1">
          <v-col cols="12" sm="12" md="4" lg="4">
            <v-menu
              v-model="keyword11889.show"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on }"
                ><v-text-field
                  label="実施日"
                  v-model="keyword11889.date"
                  background-color="white"
                  id="FID11889"
                  class="searchForm"
                  outlined
                  v-on="on"
                  readonly
                  clearable
                  hide-details="auto"
                >
                </v-text-field> </template
              ><v-date-picker
                v-model="keyword11889.date"
                no-title
                scrollable
                locale="ja-jp"
                :day-format="(date) => new Date(date).getDate()"
                range
                @input="
                  keyword11889.show =
                    keyword11889.date.length > 1 ? false : true
                "
              ></v-date-picker>
            </v-menu> </v-col
          ><v-col cols="12" sm="12" md="4" lg="4">
            <v-select
              label="ステータス"
              v-model="keyword11890"
              background-color="white"
              id="FID11890"
              class="searchForm selectBox11890"
              no-data-text="データが見つかりません"
              :items="selectBoxFID11890"
              item-text="label"
              item-value="value"
              outlined
              clearable
              hide-details="auto"
            >
            </v-select> </v-col
          >
          <v-col cols="6" sm="6" md="4" lg="4">
            <v-text-field
              label="事業部コード"
              v-model="selectedDivisionCode13155"
              background-color="white"
              id="FID13155"
              class="searchForm"
              outlined
              clearable
              hide-details="auto"
              :disabled="userRole == '一般' || userRole == 'グループ管理者' || userDivisionCode != ''"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="4" lg="4">
            <v-autocomplete
              label="SSコード"
              v-model="keyword11891"
              background-color="white"
              id="FID11891"
              class="searchForm selectBox11891"
              no-data-text="データが見つかりません"
              :items="SSCodes"
              item-text="SSCode"
              item-value="SSCode"
              outlined
              clearable
              hide-details="auto"
              :disabled="userRole == '一般'"
            >
            </v-autocomplete> </v-col
          ><v-col cols="6" sm="6" md="4" lg="4">
            <v-autocomplete
              label="SS名"
              v-model="keyword12263"
              background-color="white"
              id="FID12263"
              class="searchForm selectBox12263"
              no-data-text="データが見つかりません"
              :items="SSNames"
              item-text="SSName"
              item-value="SSName"
              outlined
              clearable
              hide-details="auto"
              :disabled="userRole == '一般'"
            >
            </v-autocomplete> </v-col
          ><v-col cols="6" sm="6" md="4" lg="4">
            <v-text-field
              label="責任者名"
              v-model="keyword12264"
              background-color="white"
              id="FID12264"
              class="searchForm"
              outlined
              clearable
              hide-details="auto"
            >
            </v-text-field> </v-col
          ><v-col cols="0"
            ><v-btn x-large @click="search()" color="primary white--text"
              >検索</v-btn
            ></v-col
          >
        </v-row>
        <v-row class="ml-2 mr-1"> </v-row>
        <v-row class="ml-2 mr-1">
          <v-col cols="">
            <v-data-table
              class="reportIndex"
              :headers="headers"
              :items="reports"
              :item-key="reports.ID"
              :page="
                $store.state.pageNum == undefined ? 1 : $store.state.pageNum
              "
              @update:page="updatePageNum"
              :sort-by="sortBy"
              :sort-desc="sortDesc"
              @click:row="move"
              :header-props="{
                'sort-by-text': '並び順',
              }"
              :footer-props="{
                'items-per-page-text': '行/ページ:',
              }"
              no-data-text="データが存在しません"
              :items-per-page="displayResults"
              @update:sort-by="updateSortBy"
              @update:sort-desc="updateSortDesc"
              :custom-sort="customSort"
            >
              <template v-slot:item.Target="{ item }">
                <v-chip
                  v-if="getLabel(item) != ''"
                  :color="getIconColor(item)"
                  class="white--text"
                >
                  {{ getLabel(item) }}
                </v-chip></template
              >
            </v-data-table>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import store from "../store/vuex.js";
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");
import { moneySearchCheck } from "./specialMethod";
import { makeMoneyCheckCSV } from "./specialMethod";

export default {
  name: "ReportIndex",
  components: {},
  data() {
    return {
      valid: true,
      initEscape: false,
      isLoading: false,
      fullPage: false,
      displayResults: 15, //5,10,15のいずれか
      height: 100,
      width: 250,
      path: "",
      sortBy: null,
      sortDesc: null,
      //
      PageID: 1655,
      reports: [],
      rules: {
        required: (value) => !!value || "必須項目です",
        requiredArray: (value) => (value.length > 0 ? true : "必須項目です"),
        fullWidth: (value) => {
          return /^[^ -~｡-ﾟ]*$/.test(value) || "全角のみ入力可能です";
        },
      },
      keyword11889: { date: ["", ""], show: false },
      selectedDivisionCode13155: "",
      selectBoxFID11890: [],
      keyword11890: "",
      selectBoxFID11891: [],
      keyword11891: "",
      selectBoxFID12263: [],
      keyword12263: "",
      keyword12264: "",
    };
  },
  async created() {
    await this.getInit();
  },
  watch: {},
  methods: {
    async getInit() {
      //タイトルをデフォルトに戻す
      if (typeof process.env.VUE_APP_BASE_TITLE != "undefined") {
        document.title = process.env.VUE_APP_BASE_TITLE;
      }
      //初期処理
      this.isLoading = true;
      this.getKeyWord(this.$store.state.keyWord);

      this.path = this.$route.path;
      //データ取得
      if (JSON.stringify(this.$store.state.reports) != "{}") {
        this.reports = this.$store.state.reports;
      } else {
        // ユーザーマスタのSSコードがあればSSコード検索の初期選択に設定する
        if (this.userSSCode) {
          this.keyword11891 = this.userSSCode;
        }
        // ユーザーマスタの事業部コードがあれば事業部コード検索の初期選択に設定する
        if (this.userDivisionCode) {
          this.selectedDivisionCode13155 = this.userDivisionCode;
        }
        let profile;
        [, profile] = await Promise.all([this.search(), api.GetRole()]);
        store.commit("setRole", profile.Role);
      }
      if (this.$store.state.tableSort.sortBy != null) {
        this.sortBy = this.$store.state.tableSort.sortBy;
        this.sortDesc = this.$store.state.tableSort.sortDesc;
      }
      this.selectBoxFID11890 = await moneySearchCheck();
      const ssMasters = await this.getSsMasters();
      [this.selectBoxFID12263, this.selectBoxFID11891] = this.getFilterSSNameAndCodeArray(ssMasters);
      // 対象外機能
      this.$store.commit("setReferrerIndexPagePath", "/moneyCheck/index");
      this.$store.commit("clearReferrerPagePath");

      this.isLoading = false;
    },
    getSsMasterData() {
      const data = {
        PageID: 1661,
        orderBy: "",
        search: [],
      };
      // 管理者は絞込なし
      if (this.userRole == "管理者") {
        return data;
      } else if (this.userRole == "グループ管理者") {
        // 事業部コードで絞り込み
        data.search.push({ form_id: 13155, value: this.userDivisionCode });
      } else if (this.userRole == "一般") {
        // SSコードで絞り込み
        data.search.push({ form_id: 11265, value: this.userSSCode });
      }
      //
      return data;
    },
    async getSsMasters() {
      const reports = await api.ReportSearch(this.getSsMasterData());
      if (reports && reports.length) {
        return reports;
      }
      return [];
    },
    getFilterSSNameAndCodeArray(ssMasters) {
      const filterSSNamees = [];
      const filterSSCodes = [];
      ssMasters.forEach((ssMaster) => {
        if (ssMaster.Recodes) {
          if (ssMaster.Recodes.FID11265 && ssMaster.Recodes.FID11265.value) {
            // filterSSCodes.push(ssMaster.Recodes.FID11265.value);
            filterSSCodes.push(
              {
                "DivisionCode": ssMaster.Recodes.FID13155.value,
                "SSCode": ssMaster.Recodes.FID11265.value,
              }
            );
          }
          if (ssMaster.Recodes.FID11266 && ssMaster.Recodes.FID11266.value) {
            // filterSSNamees.push(ssMaster.Recodes.FID11266.value);
            filterSSNamees.push(
              {
                "DivisionCode": ssMaster.Recodes.FID13155.value,
                "SSName": ssMaster.Recodes.FID11266.value,
              }
            );
          }
        }
      });
      return [filterSSNamees, filterSSCodes];
    },
    getLabel(report) {
      if (
        typeof report.Recodes.FID11931 != "undefined" &&
        report.Recodes.FID11931.value == "一時保存"
      ) {
        return "一時保存";
      }
      if (
        typeof report.Recodes.FID11931 != "undefined" &&
        report.Recodes.FID11931.value == "完了"
      ) {
        return "完了";
      }
      return "";
    },
    getIconColor(report) {
      if (
        typeof report.Recodes.FID11931 != "undefined" &&
        report.Recodes.FID11931.value == "一時保存"
      ) {
        return "orange";
      }
      if (
        typeof report.Recodes.FID11931 != "undefined" &&
        report.Recodes.FID11931.value == "完了"
      ) {
        return "blue";
      }
      return "";
    },
    getKeyWord(data) {
      if (data.PageID == this.PageID) {
        data.search.forEach((data) => {
          if (data.form_id == 11340) {
            this.keyword11889.date = data.value.split(",");
          }
          if (data.form_id == 0) {
            this.keyword11890 = data.value;
          }
          if (data.form_id == 11341) {
            this.keyword11891 = data.value;
          }
          if (data.form_id == 11342) {
            this.keyword12263 = data.value;
          }
          if (data.form_id == 11343) {
            this.keyword12264 = data.value;
          }
        });
      }
    },
    getSearchWord() {
      let data = {
        PageID: this.PageID,
        orderBy: "",
        search: [],
      };

      if (this.keyword11889.date != null && this.keyword11889.date.length > 0) {
        let date = this.keyword11889.date[0];
        if (this.keyword11889.date.length > 1) {
          this.keyword11889.date = this.dateSort(this.keyword11889.date);
          date = this.keyword11889.date.join(",");
        }
        data.search.push({ form_id: 11340, value: date, option: "dateOnly" });
      }
      this.keyword11889.show = false;
      if (this.keyword11890 != "") {
        data.search.push({ form_id: 0, value: this.keyword11890 });
      }
      if (this.keyword11891 != "") {
        data.search.push({
          form_id: 11341,
          value: this.keyword11891,
          option: "match",
        });
      }
      if (this.keyword12263 != "") {
        data.search.push({
          form_id: 11342,
          value: this.keyword12263,
          option: "match",
        });
      }
      if (this.keyword12264 != "") {
        data.search.push({ form_id: 11343, value: this.keyword12264 });
      }
      // 事業所コード
      if (this.selectedDivisionCode13155 != "") {
        data.search.push({ form_id: 13378, value: this.selectedDivisionCode13155 });
      }

      return data;
    },
    async search() {
      if (this.initEscape == false) {
        this.initEscape = true;
        this.isLoading = false;
        await this.$nextTick();
      }
      const ck = await this.checkValidation();
      if (!ck && !this.isLoading) {
        return;
      }
      //初期処理
      this.isLoading = true;
      //データ追加
      let data = await this.getSearchWord();
      //api
      const reports = await api.ReportSearch(data);
      //SearchTag
      if (reports != null) {
        this.reports = reports.reverse();
        store.commit("setreports", this.reports);
      }
      store.commit("setkeyWord", data);
      this.isLoading = false;
    },
    async makeCSV12257() {
      await this.search();
      // let header =
      //   "キー,日付,SSコード,SS名,責任者名,点検時刻,※GM（巡回者）参照,レジ合計,金庫合計,夜間金庫合計,aレジ合計,b金庫合計,c夜間金庫合計,BNA内,釣銭機内,セルフ洗浄機釣銭,POS現金残高,現金過不足,釣銭取り忘れ,登録,";
      // let csv = "\ufeff" + header + "\n";
      // this.reports.map((report) => {
      //   let line = "";
      //   line += this.getValue(report.Recodes.FID11842) + ","; //キー
      //   line += this.getValue(report.Recodes.FID11340) + ","; //日付
      //   line += this.getValue(report.Recodes.FID11341) + ","; //SSコード
      //   line += this.getValue(report.Recodes.FID11342) + ","; //SS名
      //   line += this.getValue(report.Recodes.FID11343) + ","; //責任者名
      //   line += this.getValue(report.Recodes.FID11346) + ","; //点検時刻
      //   line += this.getValue(report.Recodes.FID11349) + ","; //※GM（巡回者）参照
      //   line += this.getValue(report.Recodes.FID11483) + ","; //レジ合計
      //   line += this.getValue(report.Recodes.FID11521) + ","; //金庫合計
      //   line += this.getValue(report.Recodes.FID11538) + ","; //夜間金庫合計
      //   line += this.getValue(report.Recodes.FID11541) + ","; //aレジ合計
      //   line += this.getValue(report.Recodes.FID11657) + ","; //b金庫合計
      //   line += this.getValue(report.Recodes.FID11659) + ","; //c夜間金庫合計
      //   line += this.getValue(report.Recodes.FID11661) + ","; //BNA内
      //   line += this.getValue(report.Recodes.FID11663) + ","; //釣銭機内
      //   line += this.getValue(report.Recodes.FID11665) + ","; //セルフ洗浄機釣銭
      //   line += this.getValue(report.Recodes.FID11668) + ","; //POS現金残高
      //   line += this.getValue(report.Recodes.FID11671) + ","; //現金過不足
      //   line += this.getValue(report.Recodes.FID11674) + ","; //釣銭取り忘れ
      //   line += this.getValue(report.Recodes.FID11678) + ","; //登録
      //   line += "\n";
      //   csv += line;
      // });
      const csv = makeMoneyCheckCSV(this.reports);
      this.downloadCSV(csv, "Report2_kinsen.csv");
    },

    checkValidation: async function () {
      let form = this.$refs.form;
      let check = form.validate();
      if (!check) {
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        return check;
      }

      return check;
    },
    create() {
      this.isLoading = true;
      this.$router.push({ path: "/moneyCheck/create" });

      this.isLoading = false;
    },
    move(data) {
      this.isLoading = true;
      this.$router.push({ path: "/moneyCheck/create/" + data.ReportID });

      this.isLoading = false;
    },
    back() {
      store.commit("clearkeyWord");
      store.commit("clearreports");
      store.commit("clearPageNum");
      store.commit("clearTableSort");
      store.commit("clearkeyWord");

      //
      if (this.$route.path == this.path) {
        history.back();
      } else {
        alert("読み込みエラー:ホームに戻ります");
        this.$router.push("/", () => {});
      }
      //history.back();
    },
    getValue(v) {
      if (typeof v == "undefined") {
        return "";
      }
      return v.value;
    },
    downloadCSV(csv, filename) {
      //CSV出力部分
      let blob = new Blob([csv], { type: "text/csv" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    },
    updatePageNum(value) {
      store.commit("setPageNum", value);
    },
    updateSortBy(value) {
      store.commit("setTableSortBy", value);
    },
    updateSortDesc(value) {
      store.commit("setTableSortDesc", value);
    },
    customSort(items, index, isDesc) {
      //デフォルト
      if (index.length == 0 || items.length == 0) {
        return items;
      }
      const regex = /[^0-9]/g;
      const order = isDesc[0] ? 1 : -1;
      const pos = parseInt(index[0].replace(regex, ""));
      items.sort((a, b) => {
        //アイコンソートは特殊処理
        if (index == "target") {
          // //アイコンの特殊ソート処理は実装途中
          return 0;
        }
        //
        return this.sort(a, b, order, pos);
      });
      //
      return items;
    },
    sort(a, b, order, pos) {
      if (
        typeof a.Recodes["FID" + pos] == "undefined" ||
        typeof b.Recodes["FID" + pos] == "undefined"
      )
        return -1;
      if (a.Recodes["FID" + pos].value == b.Recodes["FID" + pos].value)
        return 0;
      if (a.Recodes["FID" + pos].value === "") return 1;
      if (b.Recodes["FID" + pos].value === "") return -1;
      if (
        !isNaN(a.Recodes["FID" + pos].value) &&
        !isNaN(b.Recodes["FID" + pos].value)
      ) {
        return Number(a.Recodes["FID" + pos].value) ==
          Number(b.Recodes["FID" + pos].value)
          ? 0
          : Number(a.Recodes["FID" + pos].value) >
            Number(b.Recodes["FID" + pos].value)
          ? -1 * order
          : order;
      }
      return (
        a.Recodes["FID" + pos].value.localeCompare(
          b.Recodes["FID" + pos].value,
          "jp"
        ) *
        -1 *
        order
      );
    },
    dateSort(dateArr) {
      const date1 = new Date(dateArr[0].replace(/-/g, "/"));
      const date2 = new Date(dateArr[1].replace(/-/g, "/"));
      if (date2 - date1 > 0) {
        return dateArr;
      } else {
        return [dateArr[1], dateArr[0]];
      }
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "ステータス",
          value: "Target",
          align: "start",
          sortable: false,
        },
        { text: "実施日", value: "Recodes.FID11340.value", sortable: true },
        { text: "SSコード", value: "Recodes.FID11341.value", sortable: true },
        { text: "SS名", value: "Recodes.FID11342.value", sortable: true },
        { text: "責任者名", value: "Recodes.FID11343.value", sortable: true },
        { text: "現金過不足", value: "Recodes.FID11986.value", sortable: true },
      ];
    },
    userSSCode() {
      if (this.$store.state.params && this.$store.state.params.FID13319 && this.$store.state.params.FID13319.value && this.$store.state.params.FID13319.value != "0") {
        return this.$store.state.params.FID13319.value;
      }
      return "";
    },
    userDivisionCode() {
      if (this.$store.state.params && this.$store.state.params.FID12259 && this.$store.state.params.FID12259.value && this.$store.state.params.FID12259.value != "0") {
        return this.$store.state.params.FID12259.value;
      }
      return "";
    },
    userRole() {
      let role = "一般";
      if (this.$store.state.role == 1) {
        role = "管理者";
      } else if (this.$store.state.role == 2) {
        role = "グループ管理者";
      }
      return role;
    },
    SSCodes() {
      if (this.selectedDivisionCode13155) {
        return this.selectBoxFID11891.filter(ssMaster => {
          return ssMaster.DivisionCode.indexOf(this.selectedDivisionCode13155) > -1
        })
      } else {
        return this.selectBoxFID11891
      }
    },
    SSNames() {
      if (this.selectedDivisionCode13155) {
        return this.selectBoxFID12263.filter(ssMaster => {
          return ssMaster.DivisionCode.indexOf(this.selectedDivisionCode13155) > -1
        })
      } else {
        return this.selectBoxFID12263
      }
    },
  },
};
</script>
