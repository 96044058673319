<template>
  <div class="body">
    <p align="center" class="title">GM巡回レポート一覧</p>
    <v-row>
      <v-col align="left" class="button"
        ><v-btn  v-if="userRole == '管理者' || userRole == 'グループ管理者'" @click.stop="linkBtn11228()" color="">新規作成</v-btn></v-col
      >
      <v-col cols="auto" align="right" class="button">
        <v-btn @click="makeCSV12186" :disabled="userRole == '一般'" class="mr-5" color="orange white--text"
          >CSV出力</v-btn
        >
        <v-btn @click.once="back">戻る</v-btn>
      </v-col>
    </v-row>
    <div v-if="isLoading" align="center">
      <v-progress-circular
        :size="250"
        color="primary"
        indeterminate
        align="center"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-form ref="form" v-model="valid">
        <v-row class="ml-2 mr-1">
          <v-col cols="12" sm="12" md="4" lg="4">
            <v-menu
              v-model="keyword11781.show"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on }"
                ><v-text-field
                  label="巡回日"
                  v-model="keyword11781.date"
                  background-color="white"
                  id="FID11781"
                  class="searchForm"
                  outlined
                  v-on="on"
                  readonly
                  clearable
                  hide-details="auto"
                >
                </v-text-field> </template
              ><v-date-picker
                v-model="keyword11781.date"
                no-title
                scrollable
                locale="ja-jp"
                :day-format="(date) => new Date(date).getDate()"
                range
                @input="
                  keyword11781.show =
                    keyword11781.date.length > 1 ? false : true
                "
              ></v-date-picker>
            </v-menu> </v-col
          ><v-col cols="12" sm="12" md="4" lg="4">
            <v-autocomplete
              label="ステータス"
              v-model="keyword11780"
              background-color="white"
              id="FID11780"
              class="searchForm selectBox11780"
              no-data-text="データが見つかりません"
              :items="selectBoxFID11780"
              item-text="label"
              item-value="value"
              outlined
              clearable
              multiple
              hide-details="auto"
            >
            </v-autocomplete> </v-col
          >
          <v-col cols="12" sm="12" md="4" lg="4">
            <v-text-field
              label="事業部コード"
              v-model="selectedDivisionCode13377"
              background-color="white"
              id="FID13377"
              class="searchForm"
              outlined
              clearable
              hide-details="auto"
              :disabled="userRole == '一般' || userRole == 'グループ管理者' || userDivisionCode != ''"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="4" lg="4">
            <v-autocomplete
              label="SSコード"
              v-model="selectedSsCodeFID11782"
              background-color="white"
              id="FID11782"
              class="searchForm selectBox11782"
              no-data-text="データが見つかりません"
              :items="SSCodes"
              item-text="SSCode"
              item-value="SSCode"
              outlined
              clearable
              hide-details="auto"
              :disabled="userRole == '一般'"
            >
            </v-autocomplete> </v-col
          ><v-col cols="12" sm="12" md="4" lg="4">
            <v-autocomplete
              label="SS名"
              v-model="keyword11871"
              background-color="white"
              id="FID11871"
              class="searchForm selectBox11871"
              no-data-text="データが見つかりません"
              :items="SSNames"
              item-text="SSName"
              item-value="SSName"
              outlined
              clearable
              hide-details="auto"
              :disabled="userRole == '一般'"
            >
            </v-autocomplete> </v-col
          ><v-col cols="12" sm="12" md="4" lg="4">
            <v-menu
              v-model="keyword11872.show"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on }"
                ><v-text-field
                  label="指導（改善）完了日"
                  v-model="keyword11872.date"
                  background-color="white"
                  id="FID11872"
                  class="searchForm"
                  outlined
                  v-on="on"
                  readonly
                  clearable
                  hide-details="auto"
                >
                </v-text-field> </template
              ><v-date-picker
                v-model="keyword11872.date"
                no-title
                scrollable
                locale="ja-jp"
                :day-format="(date) => new Date(date).getDate()"
                range
                @input="
                  keyword11872.show =
                    keyword11872.date.length > 1 ? false : true
                "
              ></v-date-picker>
            </v-menu> </v-col
          ><v-col cols="12" sm="12" md="4" lg="4">
            <v-text-field
              label="GM（巡回者）"
              v-model="keyword11921"
              background-color="white"
              id="FID11921"
              class="searchForm"
              outlined
              clearable
              hide-details="auto"
            >
            </v-text-field> </v-col
          ><v-col cols="0"
            ><v-btn x-large @click="search()" color="primary white--text"
              >検索</v-btn
            ></v-col
          >
        </v-row>
        <v-row class="ml-2 mr-1"> </v-row>
        <v-row class="ml-2 mr-1">
          <v-col cols="">
            <v-data-table
              class="reportIndex"
              :headers="headers"
              :items="reports"
              :item-key="reports.ID"
              :page="
                $store.state.pageNum == undefined ? 1 : $store.state.pageNum
              "
              @update:page="updatePageNum"
              :sort-by="sortBy"
              :sort-desc="sortDesc"
              @click:row="move"
              :header-props="{
                'sort-by-text': '並び順',
              }"
              :footer-props="{
                'items-per-page-text': '行/ページ:',
              }"
              no-data-text="データが存在しません"
              :items-per-page="displayResults"
              @update:sort-by="updateSortBy"
              @update:sort-desc="updateSortDesc"
              :custom-sort="customSort"
            >
              <template v-slot:item.Recodes.FID11874.value="{ item }"
                ><v-chip
                  v-if="getSpecialFID11874(item).value != ''"
                  :color="getSpecialFID11874(item).color"
                  :class="getSpecialFID11874(item).textColor || 'white--text'"
                  >{{ getSpecialFID11874(item).value }}
                </v-chip></template
              >
            </v-data-table>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import store from "../store/vuex.js";
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");
import { patrolReportStatus } from "./specialMethod";
import { makePatrolReportCSV } from "./specialMethod";
import { makeMoneyCheckCSV } from "./specialMethod";
import { makeStockCheckCSV } from "./specialMethod";
import { getMasterData } from "./specialMethod";
import JSZip from "jszip";

export default {
  name: "ReportIndex",
  components: {},
  data() {
    return {
      valid: true,
      initEscape: false,
      isLoading: false,
      fullPage: false,
      displayResults: 15, //5,10,15のいずれか
      height: 100,
      width: 250,
      path: "",
      sortBy: null,
      sortDesc: null,
      //
      PageID: 1651,
      reports: [],
      rules: {
        required: (value) => !!value || "必須項目です",
        requiredArray: (value) => (value.length > 0 ? true : "必須項目です"),
        fullWidth: (value) => {
          return /^[^ -~｡-ﾟ]*$/.test(value) || "全角のみ入力可能です";
        },
      },
      keyword11781: { date: ["", ""], show: false },
      selectBoxFID11780: [
        "GM確認待",
        "改善予定日未確定",
        "指導中(1週間前)",
        "指導中",
        "期限切れ",
        "一時保存",
        "完了",
      ],
      keyword11780: [],
      selectedDivisionCode13377: "",
      selectBoxSsCodeFID11782: [],
      selectedSsCodeFID11782: "",
      selectBoxSsNameFID11871: [],
      keyword11871: "",
      keyword11872: { date: ["", ""], show: false },
      keyword11921: "",
    };
  },
  async created() {
    await this.getInit();
  },
  watch: {},
  methods: {
    async getInit() {
      //タイトルをデフォルトに戻す
      if (typeof process.env.VUE_APP_BASE_TITLE != "undefined") {
        document.title = process.env.VUE_APP_BASE_TITLE;
      }
      //初期処理
      this.isLoading = true;
      this.getKeyWord(this.$store.state.keyWord);

      this.path = this.$route.path;
      //データ取得
      if (JSON.stringify(this.$store.state.reports) != "{}") {
        this.reports = this.$store.state.reports;
      } else {
        // ユーザーマスタのSSコードがあればSSコード検索の初期選択に設定する
        if (this.userSSCode) {
          this.selectedSsCodeFID11782 = this.userSSCode;
        }
        // ユーザーマスタの事業部コードがあれば事業部コード検索の初期選択に設定する
        if (this.userDivisionCode) {
          this.selectedDivisionCode13377 = this.userDivisionCode;
        }
        const [profile] = await Promise.all([api.GetRole()]);
        store.commit("setRole", profile.Role);
        if (this.userRole == "一般") {
          this.keyword11780.push("指導中")
          this.keyword11780.push("指導中(1週間前)")
        }
        await Promise.all([this.search()]);
      }
      if (this.$store.state.tableSort.sortBy != null) {
        this.sortBy = this.$store.state.tableSort.sortBy;
        this.sortDesc = this.$store.state.tableSort.sortDesc;
      }
      const ssMasters = await this.getSsMasters();
      [this.selectBoxSsNameFID11871, this.selectBoxSsCodeFID11782] = this.getFilterSSNameAndCodeArray(ssMasters);

      // 対象外機能
      this.$store.commit("setReferrerIndexPagePath", "/patrolReport/index");
      this.$store.commit("clearReferrerPagePath");

      this.isLoading = false;
    },
    getSsMasterData() {
      const data = {
        PageID: 1661,
        orderBy: "",
        search: [],
      };
      // 管理者は絞込なし
      if (this.userRole == "管理者") {
        return data;
      } else if (this.userRole == "グループ管理者") {
        // 事業部コードで絞り込み
        data.search.push({ form_id: 13155, value: this.userDivisionCode });
      } else if (this.userRole == "一般") {
        // SSコードで絞り込み
        data.search.push({ form_id: 11265, value: this.userSSCode });
      }
      //
      return data;
    },
    async getSsMasters() {
      const reports = await api.ReportSearch(this.getSsMasterData());
      if (reports && reports.length) {
        return reports;
      }
      return [];
    },
    getFilterSSNameAndCodeArray(ssMasters) {
      const filterSSNamees = [];
      const filterSSCodes = [];
      ssMasters.forEach((ssMaster) => {
        if (ssMaster.Recodes) {
          if (ssMaster.Recodes.FID11265 && ssMaster.Recodes.FID11265.value) {
            filterSSCodes.push(
              {
                "DivisionCode": ssMaster.Recodes.FID13155.value,
                "SSCode": ssMaster.Recodes.FID11265.value,
              }
            );
          }
          if (ssMaster.Recodes.FID11266 && ssMaster.Recodes.FID11266.value) {
            filterSSNamees.push(
              {
                "DivisionCode": ssMaster.Recodes.FID13155.value,
                "SSName": ssMaster.Recodes.FID11266.value,
              }
            );
          }
        }
      });
      return [filterSSNamees, filterSSCodes];
    },
    getSpecialFID11874(item) {
      const value =
        item.Recodes && item.Recodes.FID11874
          ? item.Recodes.FID11874.value
          : "";
      return patrolReportStatus(item, value);
    },
    linkBtn11228() {
      store.commit("clearkeyWord");
      store.commit("clearreports");
      store.commit("clearPageNum");
      store.commit("clearTableSort");
      store.commit("clearkeyWord");
      this.isLoading = true;
      this.$router.push({ path: "/patrolReport/select" });
      this.isLoading = false;
    },
    getKeyWord(data) {
      if (data.PageID == this.PageID) {
        data.search.forEach((data) => {
          if (data.form_id == 11758) {
            this.keyword11781.date = data.value.split(",");
          }
          if (data.form_id == 11873) {
            // this.keyword11780 = data.value;
          }
          if (data.form_id == 11763) {
            this.selectedSsCodeFID11782 = data.value;
          }
          if (data.form_id == 11767) {
            this.keyword11871 = data.value;
          }
          if (data.form_id == 11774) {
            this.keyword11872.date = data.value.split(",");
          }
          if (data.form_id == 11766) {
            this.keyword11921 = data.value;
          }
        });
      }
    },
    getSearchWord() {
      let data = {
        PageID: this.PageID,
        orderBy: "",
        search: [],
      };
      // 巡回日
      if (this.keyword11781.date != null && this.keyword11781.date.length > 0) {
        let date = this.keyword11781.date[0];
        if (this.keyword11781.date.length > 1) {
          this.keyword11781.date = this.dateSort(this.keyword11781.date);
          date = this.keyword11781.date.join(",");
        }
        data.search.push({ form_id: 11758, value: date, option: "dateOnly" });
      }
      this.keyword11781.show = false;
      // SSコード
      if (this.selectedSsCodeFID11782) {
        data.search.push({
          form_id: 11763,
          value: this.selectedSsCodeFID11782,
          option: "match",
        });
      }
      // SS名
      if (this.keyword11871) {
        data.search.push({
          form_id: 11767,
          value: this.keyword11871,
          option: "match",
        });
      }
      // 指導完了日
      if (this.keyword11872.date != null && this.keyword11872.date.length > 0) {
        let date = this.keyword11872.date[0];
        if (this.keyword11872.date.length > 1) {
          this.keyword11872.date = this.dateSort(this.keyword11872.date);
          date = this.keyword11872.date.join(",");
        }
        data.search.push({ form_id: 11774, value: date, option: "dateOnly" });
      }
      this.keyword11872.show = false;
      // GL（巡回者）
      if (this.keyword11921) {
        data.search.push({ form_id: 11766, value: this.keyword11921 });
      }
      //事業部コード
      if (this.selectedDivisionCode13377) {
        data.search.push({ form_id: 13377, value: this.selectedDivisionCode13377, option: "match" });
      }
      return data;
    },
    async search() {
      if (this.initEscape == false) {
        this.initEscape = true;
        this.isLoading = false;
        await this.$nextTick();
      }
      const ck = await this.checkValidation();
      if (!ck && !this.isLoading) {
        return;
      }
      //初期処理
      this.isLoading = true;
      //データ追加
      let data = await this.getSearchWord();
      //api
      const reports = await api.ReportSearch(data);
      //SearchTag
      if (reports != null) {
        this.reports = reports.reverse().filter((report) => {
          // ステータスで絞り込み
          if (this.keyword11780.length) {
            // if (patrolReportStatus(report).search == this.keyword11780) {
            if (this.keyword11780.includes(patrolReportStatus(report).search)) {
              return true;
            } else {
              return false;
            }
          }
          return true;
        });
        store.commit("setreports", this.reports);
      }
      store.commit("setkeyWord", data);
      this.isLoading = false;
    },
    async makeCSV12186() {
      await this.search();
      // let header =
      //   "キー,巡回日,巡回開始時刻,巡回終了時刻,SSコード,担当GM,グループ,GM（巡回者）,SS名,MG,対応者名（面談者名）,1.テキスト,1.今回,1.改善確認,2.テキスト,2.今回,2.改善確認,3.テキスト,3.今回,3.改善確認,4.テキスト,4.今回,4.改善確認,5.①現金差異,5.①現金差異（円）,5.①今回,5.①改善確認,5.②釣銭機ナンバー,5.②釣銭機差異,5.②釣銭機差異（円）,5.②今回,5.②改善確認,5.③SSログ（当月累計回数）,5.③管理者ログ1（詳細）,5.③SSログ（詳細）,5.③今回,5.③改善確認,5.④先売上,5.④商品名,5.④金額,5.④今回,5.④改善確認,5.④規定外保管物,5.④名称,5.④その他金券類,5.④受払表作成,5.④今回,5.④改善確認,5.⑤タイヤ差異,5.⑤差異個数,5.⑤簿外在庫,5.⑤今回,5.⑤改善確認,5.⑤バッテリー差異,5.⑤差異個数,5.⑤簿外在庫,5.⑤今回,5.⑤改善確認,5.⑥計量機内の点検（油漏れ）,5.⑥油水分離槽清掃状況,5.⑥マンホール内の滞水,5.⑥消火器の期限・破損,5.⑥今回,5.⑥改善確認,6.⑦分解整備料金表,6.⑦分解整備工具,6.⑦今回,6.⑦改善確認,6.⑧分解記録簿件数（件）,6.⑧分解記録簿件数（件）,6.⑧分解整備記録簿記載,6.⑧今回,6.⑧改善確認,6.⑨ステッカー番号記載,6.⑨自署,6.⑨シフト出勤状況,6.⑨今回,6.⑨改善確認,7.シート運用,7.不備項目,7.再発防止策の運用,7.今回,7.改善確認,改善予定日確定,改善完了報告,GM確認,指導（改善）完了日,対応者名（面談者）,テキスト,対応・処理予定日,";
      // let csv = "\ufeff" + header + "\n";
      // this.reports.map((report) => {
      //   let line = "";
      //   line += '"' + this.getValue(report.Recodes.FID11841) + '",'; //キー
      //   line += '"' + this.getValue(report.Recodes.FID11758) + '",'; //巡回日
      //   // line += '"' + this.getValue(report.Recodes.FID11227) + '",'; //行動指針
      //   line += '"' + this.getValue(report.Recodes.FID11761) + '",'; //巡回開始時刻
      //   line += '"' + this.getValue(report.Recodes.FID11762) + '",'; //巡回終了時刻
      //   line += '"' + this.getValue(report.Recodes.FID11763) + '",'; //SSコード
      //   line += '"' + this.getValue(report.Recodes.FID11764) + '",'; //担当GM
      //   line += '"' + this.getValue(report.Recodes.FID11765) + '",'; //グループ
      //   line += '"' + this.getValue(report.Recodes.FID11766) + '",'; //GM（巡回者）
      //   line += '"' + this.getValue(report.Recodes.FID11767) + '",'; //SS名
      //   line += '"' + this.getValue(report.Recodes.FID11768) + '",'; //MG
      //   line += '"' + this.getValue(report.Recodes.FID11770) + '",'; //対応者名（面談者名）
      //   line += '"' + this.getValue(report.Recodes.FID11787) + '",'; //1.テキスト
      //   line += '"' + this.getValue(report.Recodes.FID11788) + '",'; //1.今回
      //   line += '"' + this.getValue(report.Recodes.FID11789) + '",'; //1.改善確認
      //   line += '"' + this.getValue(report.Recodes.FID11792) + '",'; //2.テキスト
      //   line += '"' + this.getValue(report.Recodes.FID11793) + '",'; //2.今回
      //   line += '"' + this.getValue(report.Recodes.FID11794) + '",'; //2.改善確認
      //   line += '"' + this.getValue(report.Recodes.FID11797) + '",'; //3.テキスト
      //   line += '"' + this.getValue(report.Recodes.FID11798) + '",'; //3.今回
      //   line += '"' + this.getValue(report.Recodes.FID11799) + '",'; //3.改善確認
      //   line += '"' + this.getValue(report.Recodes.FID11802) + '",'; //4.テキスト
      //   line += '"' + this.getValue(report.Recodes.FID11803) + '",'; //4.今回
      //   line += '"' + this.getValue(report.Recodes.FID11804) + '",'; //4.改善確認
      //   line += '"' + this.getValue(report.Recodes.FID12032) + '",'; //5.①現金差異
      //   line += '"' + this.getValue(report.Recodes.FID12033) + '",'; //5.①現金差異（円）
      //   line += '"' + this.getValue(report.Recodes.FID12035) + '",'; //5.①今回
      //   line += '"' + this.getValue(report.Recodes.FID12037) + '",'; //5.①改善確認
      //   line += '"' + this.getValue(report.Recodes.FID12043) + '",'; //5.②釣銭機ナンバー
      //   line += '"' + this.getValue(report.Recodes.FID12045) + '",'; //5.②釣銭機差異
      //   line += '"' + this.getValue(report.Recodes.FID12042) + '",'; //5.②釣銭機差異（円）
      //   line += '"' + this.getValue(report.Recodes.FID12047) + '",'; //5.②今回
      //   line += '"' + this.getValue(report.Recodes.FID12049) + '",'; //5.②改善確認
      //   line += '"' + this.getValue(report.Recodes.FID12055) + '",'; //5.③SSログ（当月累計回数）
      //   line += '"' + this.getValue(report.Recodes.FID12058) + '",'; //5.③管理者ログ1（詳細）
      //   line += '"' + this.getValue(report.Recodes.FID12061) + '",'; //5.③SSログ（詳細）
      //   line += '"' + this.getValue(report.Recodes.FID12063) + '",'; //5.③今回
      //   line += '"' + this.getValue(report.Recodes.FID12065) + '",'; //5.③改善確認
      //   line += '"' + this.getValue(report.Recodes.FID12070) + '",'; //5.④先売上
      //   line += '"' + this.getValue(report.Recodes.FID12075) + '",'; //5.④商品名
      //   line += '"' + this.getValue(report.Recodes.FID12076) + '",'; //5.④金額
      //   line += '"' + this.getValue(report.Recodes.FID12072) + '",'; //5.④今回
      //   line += '"' + this.getValue(report.Recodes.FID12074) + '",'; //5.④改善確認
      //   line += '"' + this.getValue(report.Recodes.FID12079) + '",'; //5.④規定外保管物
      //   line += '"' + this.getValue(report.Recodes.FID12080) + '",'; //5.④名称
      //   line += '"' + this.getValue(report.Recodes.FID12082) + '",'; //5.④その他金券類
      //   line += '"' + this.getValue(report.Recodes.FID12084) + '",'; //5.④受払表作成
      //   line += '"' + this.getValue(report.Recodes.FID12086) + '",'; //5.④今回
      //   line += '"' + this.getValue(report.Recodes.FID12088) + '",'; //5.④改善確認
      //   line += '"' + this.getValue(report.Recodes.FID12094) + '",'; //5.⑤タイヤ差異
      //   line += '"' + this.getValue(report.Recodes.FID12095) + '",'; //5.⑤差異個数
      //   line += '"' + this.getValue(report.Recodes.FID12097) + '",'; //5.⑤簿外在庫
      //   line += '"' + this.getValue(report.Recodes.FID12099) + '",'; //5.⑤今回
      //   line += '"' + this.getValue(report.Recodes.FID12101) + '",'; //5.⑤改善確認
      //   line += '"' + this.getValue(report.Recodes.FID12108) + '",'; //5.⑤バッテリー差異
      //   line += '"' + this.getValue(report.Recodes.FID12109) + '",'; //5.⑤差異個数
      //   line += '"' + this.getValue(report.Recodes.FID12111) + '",'; //5.⑤簿外在庫
      //   line += '"' + this.getValue(report.Recodes.FID12113) + '",'; //5.⑤今回
      //   line += '"' + this.getValue(report.Recodes.FID12115) + '",'; //5.⑤改善確認
      //   line += '"' + this.getValue(report.Recodes.FID12120) + '",'; //5.⑥計量機内の点検（油漏れ）
      //   line += '"' + this.getValue(report.Recodes.FID12122) + '",'; //5.⑥油水分離槽清掃状況
      //   line += '"' + this.getValue(report.Recodes.FID12124) + '",'; //5.⑥マンホール内の滞水
      //   line += '"' + this.getValue(report.Recodes.FID12126) + '",'; //5.⑥消火器の期限・破損
      //   line += '"' + this.getValue(report.Recodes.FID12128) + '",'; //5.⑥今回
      //   line += '"' + this.getValue(report.Recodes.FID12130) + '",'; //5.⑥改善確認
      //   line += '"' + this.getValue(report.Recodes.FID12136) + '",'; //6.⑦分解整備料金表
      //   line += '"' + this.getValue(report.Recodes.FID12138) + '",'; //6.⑦分解整備工具
      //   line += '"' + this.getValue(report.Recodes.FID12140) + '",'; //6.⑦今回
      //   line += '"' + this.getValue(report.Recodes.FID12142) + '",'; //6.⑦改善確認
      //   line += '"' + this.getValue(report.Recodes.FID12148) + '",'; //6.⑧分解記録簿件数（件）
      //   line += '"' + this.getValue(report.Recodes.FID12150) + '",'; //6.⑧分解記録簿件数（件）
      //   line += '"' + this.getValue(report.Recodes.FID12152) + '",'; //6.⑧分解整備記録簿記載
      //   line += '"' + this.getValue(report.Recodes.FID12154) + '",'; //6.⑧今回
      //   line += '"' + this.getValue(report.Recodes.FID12156) + '",'; //6.⑧改善確認
      //   line += '"' + this.getValue(report.Recodes.FID12161) + '",'; //6.⑨ステッカー番号記載
      //   line += '"' + this.getValue(report.Recodes.FID12163) + '",'; //6.⑨自署
      //   line += '"' + this.getValue(report.Recodes.FID12165) + '",'; //6.⑨シフト出勤状況
      //   line += '"' + this.getValue(report.Recodes.FID12167) + '",'; //6.⑨今回
      //   line += '"' + this.getValue(report.Recodes.FID12169) + '",'; //6.⑨改善確認
      //   line += '"' + this.getValue(report.Recodes.FID12175) + '",'; //7.シート運用
      //   line += '"' + this.getValue(report.Recodes.FID12177) + '",'; //7.不備項目
      //   line += '"' + this.getValue(report.Recodes.FID12179) + '",'; //7.再発防止策の運用
      //   line += '"' + this.getValue(report.Recodes.FID12181) + '",'; //7.今回
      //   line += '"' + this.getValue(report.Recodes.FID12183) + '",'; //7.改善確認
      //   line += '"' + this.getValue(report.Recodes.FID12023) + '",'; //改善予定日確定
      //   line += '"' + this.getValue(report.Recodes.FID11988) + '",'; //改善完了報告
      //   line += '"' + this.getValue(report.Recodes.FID11985) + '",'; //GM確認
      //   line += '"' + this.getValue(report.Recodes.FID11774) + '",'; //指導（改善）完了日
      //   line += '"' + this.getValue(report.Recodes.FID11775) + '",'; //対応者名（面談者）
      //   line += '"' + this.getValue(report.Recodes.FID11910) + '",'; //テキスト
      //   line += '"' + this.getValue(report.Recodes.FID11927) + '",'; //対応・処理予定日
      //   line += "\n";
      //   csv += line;
      // });
      const csv = makePatrolReportCSV(this.reports);
      // const files = []
      // files.push({
      //   filename: "Report1_jyunkai.csv", content: csv
      // })
      //CSV出力
      let blob = new Blob([csv], { type: "text/csv" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Report1_jyunkai.csv";
      link.click();

      const stockCheckValue = [];
      const moneyCheckValue = [];
      this.reports.forEach((report) => {
        if (this.getValue(report.Recodes.FID11954)) {
          moneyCheckValue.push(this.getValue(report.Recodes.FID11841));
        }
        if (this.getValue(report.Recodes.FID11955)) {
          stockCheckValue.push(this.getValue(report.Recodes.FID11841));
        }
      });
      // 金銭点検チェックを取得
      const money_check_reports = await this.getSearchMoneyCheck(moneyCheckValue.join(','));
      const csv2 = makeMoneyCheckCSV(money_check_reports);
      // files.push({
      //   filename: "Report2_kinsen.csv", content: csv2
      // })
      //CSV出力
      blob = new Blob([csv2], { type: "text/csv" });
      link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Report2_kinsen.csv";
      link.click();

      // 在庫差異チェックを取得
      const stock_check_reports = await this.getSearchStockCheck(stockCheckValue.join(','));
      const productReports = await getMasterData.productReports();
      const products = productReports.map((report) => {
        return {
          code: report.Recodes.FID11896.value,
          name: report.Recodes.FID11897.value,
          class_code: report.Recodes.FID12213.value,
        };
      });
      const csv3 = makeStockCheckCSV(stock_check_reports, products);
      // files.push({
      //   filename: "Report3_zaiko.csv", content: csv3
      // })
      //CSV出力
      blob = new Blob([csv3], { type: "text/csv" });
      link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Report3_zaiko.csv";
      link.click();
      // this.downloadCSV(files);
    },
    async getSearchMoneyCheck(value) {
      let data = {
        PageID: 1655,
        orderBy: "",
        search: [],
      };
      data.search.push({ form_id: 11842, value: value, option: "commaValuesAnyMatch" });
      const reports = await api.ReportSearch(data);
      if (reports != null) {
        reports.reverse();
      }
      return reports;
    },
    async getSearchStockCheck(value) {
      let data = {
        PageID: 1656,
        orderBy: "",
        search: [],
      };
      data.search.push({ form_id: 11843, value: value, option: "commaValuesAnyMatch" });
      const reports = await api.ReportSearch(data);
      if (reports != null) {
        reports.reverse();
      }
      return reports;
    },

    checkValidation: async function () {
      let form = this.$refs.form;
      let check = form.validate();
      if (!check) {
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        return check;
      }

      return check;
    },
    create() {
      this.isLoading = true;
      this.$router.push({ path: "/patrolReport/create" });

      this.isLoading = false;
    },
    move(data) {
      this.isLoading = true;
      this.$router.push({ path: "/patrolReport/create/" + data.ReportID });

      this.isLoading = false;
    },
    back() {
      store.commit("clearkeyWord");
      store.commit("clearreports");
      store.commit("clearPageNum");
      store.commit("clearTableSort");
      store.commit("clearkeyWord");

      //
      if (this.$route.path == this.path) {
        history.back();
      } else {
        alert("読み込みエラー:ホームに戻ります");
        this.$router.push("/", () => {});
      }
      //history.back();
    },
    getValue(v) {
      if (typeof v == "undefined") {
        return "";
      }
      return v.value;
    },
    downloadCSV(files) {
      // JSZipインスタンスを作成
      const zip = new JSZip();
      // files 配列の各要素を ZIP に追加
      files.forEach(file => {
        // file.filename: CSVファイル名、file.content: CSVの内容
        zip.file(file.filename, file.content);
      });

      // ZIPファイルの生成とダウンロード
      zip.generateAsync({ type: "blob" }).then(function (blob) {
        const zipFilename = "csv_files.zip"; // ZIPファイル名
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = zipFilename;
        link.click();
      });
    },
    updatePageNum(value) {
      store.commit("setPageNum", value);
    },
    updateSortBy(value) {
      store.commit("setTableSortBy", value);
    },
    updateSortDesc(value) {
      store.commit("setTableSortDesc", value);
    },
    customSort(items, index, isDesc) {
      //デフォルト
      if (index.length == 0 || items.length == 0) {
        return items;
      }
      const regex = /[^0-9]/g;
      const order = isDesc[0] ? 1 : -1;
      const pos = parseInt(index[0].replace(regex, ""));
      items.sort((a, b) => {
        //アイコンソートは特殊処理
        if (index == "target") {
          // //アイコンの特殊ソート処理は実装途中
          return 0;
        }
        //
        return this.sort(a, b, order, pos);
      });
      //
      return items;
    },
    sort(a, b, order, pos) {
      if (
        typeof a.Recodes["FID" + pos] == "undefined" ||
        typeof b.Recodes["FID" + pos] == "undefined"
      )
        return -1;
      if (a.Recodes["FID" + pos].value == b.Recodes["FID" + pos].value)
        return 0;
      if (a.Recodes["FID" + pos].value === "") return 1;
      if (b.Recodes["FID" + pos].value === "") return -1;
      if (
        !isNaN(a.Recodes["FID" + pos].value) &&
        !isNaN(b.Recodes["FID" + pos].value)
      ) {
        return Number(a.Recodes["FID" + pos].value) ==
          Number(b.Recodes["FID" + pos].value)
          ? 0
          : Number(a.Recodes["FID" + pos].value) >
            Number(b.Recodes["FID" + pos].value)
          ? -1 * order
          : order;
      }
      return (
        a.Recodes["FID" + pos].value.localeCompare(
          b.Recodes["FID" + pos].value,
          "jp"
        ) *
        -1 *
        order
      );
    },
    dateSort(dateArr) {
      const date1 = new Date(dateArr[0].replace(/-/g, "/"));
      const date2 = new Date(dateArr[1].replace(/-/g, "/"));
      if (date2 - date1 > 0) {
        return dateArr;
      } else {
        return [dateArr[1], dateArr[0]];
      }
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "ステータス",
          value: "Recodes.FID11874.value",
          align: "start",
          sortable: true,
        },
        { text: "巡回日", value: "Recodes.FID11758.value", sortable: true },
        { text: "SSコード", value: "Recodes.FID11763.value", sortable: true },
        { text: "SS名", value: "Recodes.FID11767.value", sortable: true },
        {
          text: "GM（巡回者）",
          value: "Recodes.FID11766.value",
          sortable: true,
        },
        { text: "金銭点検", value: "Recodes.FID11954.value", sortable: true },
        {
          text: "在庫差異確認",
          value: "Recodes.FID11955.value",
          sortable: true,
        },
        {
          text: "指導（改善）完了日",
          value: "Recodes.FID11774.value",
          sortable: true,
        },
      ];
    },
    userSSCode() {
      if (this.$store.state.params && this.$store.state.params.FID13319 && this.$store.state.params.FID13319.value && this.$store.state.params.FID13319.value != "0") {
        return this.$store.state.params.FID13319.value;
      }
      return "";
    },
    userDivisionCode() {
      if (this.$store.state.params && this.$store.state.params.FID12259 && this.$store.state.params.FID12259.value && this.$store.state.params.FID12259.value != "0") {
        return this.$store.state.params.FID12259.value;
      }
      return "";
    },
    userRole() {
      let role = "一般";
      if (this.$store.state.role == 1) {
        role = "管理者";
      } else if (this.$store.state.role == 2) {
        role = "グループ管理者";
      }
      return role;
    },
    SSCodes() {
      if (this.selectedDivisionCode13377) {
        return this.selectBoxSsCodeFID11782.filter(ssMaster => {
          return ssMaster.DivisionCode.indexOf(this.selectedDivisionCode13377) > -1
        })
      } else {
        return this.selectBoxSsCodeFID11782
      }
    },
    SSNames() {
      if (this.selectedDivisionCode13377) {
        return this.selectBoxSsNameFID11871.filter(ssMaster => {
          return ssMaster.DivisionCode.indexOf(this.selectedDivisionCode13377) > -1
        })
      } else {
        return this.selectBoxSsNameFID11871
      }
    },
  },
};
</script>
