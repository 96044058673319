import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    auth: false,
    user: null,
    credential: "",
    cre: [],
    name: "",
    accessToken: "",
    role: 0,
    params: {},
    backPath: "/",
    backPath2: "/",
    //
    keyWord: {},
    reports: {},
    pageNum: 1,
    //
    tableSort: {
      sortBy: null,
      sortDesc: null,
    },
    displayMode: true,
    referrerIndexPagePath: "",
    referrerPagePath: "",
    IPauth: false,
    movePatrolReportInspectionItem: "0", // ナビメニューで押下された点検項目を保持
    reportSerialNumber: "", // 巡回レポート通し番号
  },
  mutations: {
    setIPauth(state, bool) {
      state.IPauth = bool;
    },
    setAuth(state, auth) {
      state.auth = auth;
    },
    setRole(state, role) {
      state.role = Number(role);
    },
    setParams(state, params) {
      state.params = params;
    },
    setUser(state, user) {
      state.user = user;
      if (user) {
        state.name = user.username;
      } else {
        state.name = null;
      }
    },
    setBackpath(state, path) {
      state.backPath = path;
    },
    setBackpath2(state, path) {
      state.backPath2 = path;
    },
    setCredential(state, id) {
      state.credential = id;
    },
    setAccessToken(state, data) {
      state.accessToken = data;
    },
    setPageNum(state, pageNum) {
      state.pageNum = pageNum;
    },
    setkeyWord(state, obj) {
      state.keyWord = obj;
    },
    setTableSort(state, obj) {
      state.tableSort.sortBy = obj.sortBy;
      state.tableSort.sortDesc = obj.sortDesc;
    },
    setTableSortBy(state, obj) {
      state.tableSort.sortBy = obj;
    },
    setTableSortDesc(state, obj) {
      state.tableSort.sortDesc = obj;
    },
    setDisplayMode(state, bool) {
      state.displayMode = bool;
    },
    clearkeyWord(state) {
      state.keyWord = {};
    },
    setreports(state, obj) {
      state.reports = obj;
    },
    setReferrerIndexPagePath(state, str) {
      state.referrerIndexPagePath = str;
    },
    setReferrerPagePath(state, str) {
      state.referrerPagePath = str;
    },
    setMovePatrolReportInspectionItem(state, str) {
      state.movePatrolReportInspectionItem = str;
    },
    setReportSerialNumber(state, str) {
      state.reportSerialNumber = str;
    },
    clearReferrerPagePath(state) {
      state.referrerPagePath = "";
    },
    clearreports(state) {
      state.reports = {};
    },
    clearPageNum(state) {
      state.pageNum = 1;
    },
    clearTableSort(state) {
      state.tableSort.sortBy = null;
      state.tableSort.sortDesc = null;
    },
    clearMovePatrolReportInspectionItem(state) {
      state.movePatrolReportInspectionItem = "0";
    },
    clearReportSerialNumber(state) {
      state.reportSerialNumber = "";
    },
    resetDisplayMode(state) {
      state.displayMode = true;
    },
    logOut(state) {
      state.auth = false;
      state.role = 0;
      state.name = null;
      state.credential = null;
      state.cre = null;
      state.params = [];
      state.accessToken = null;
      state.keyWord = {};
      state.pageNum = 1;
      state.tableSort.sortBy = null;
      state.tableSort.sortDesc = null;
      state.movePatrolReportInspectionItem = "0";
      state.reportSerialNumber = "";
    },
  },
});
