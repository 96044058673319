<template>
  <v-row>
    <v-col>
      <v-data-table
        :headers="headers"
        :items="subReports"
        :items-per-page="itemsPerPage"
        :footer-props="{
          'items-per-page-text': '行/ページ:',
        }"
        disable-sort
        no-data-text="データが存在しません"
        hide-default-footer
      >
        <template v-if="true" v-slot:footer>
          <div class="v-data-footer">
            <v-row align="center" justify="space-between">
              <v-col cols="2">
                <v-btn
                  @click="subPageAdd()"
                  :color="addBtnColor"
                  class="ma-2"
                  >{{ addBtnText }}</v-btn
                >
              </v-col>
              <v-col v-if="true" cols="2" class="my-3">
                {{ `登録件数: ${subPageCount}件` }}
              </v-col>
            </v-row>
          </div>
        </template>
        <template v-slot:item="{ item }" v-ripple
          ><tr>
            <td v-if="typeof item.Recodes.FID11494 != 'undefined'">
              <v-text-field
                label="商品コード"
                v-model="item.Recodes.FID11494.value"
                :disabled="item.DeleteFlag"
                :rules="[rules.required]"
                id="FID11494"
                class="textfield11494"
                hide-details="auto"
              >
              </v-text-field>
            </td>
            <td v-else></td>
            <td v-if="typeof item.Recodes.FID11495 != 'undefined'">
              <v-text-field
                label="商品名"
                v-model="item.Recodes.FID11495.value"
                :disabled="item.DeleteFlag"
                :rules="[rules.required]"
                id="FID11495"
                class="textfield11495"
                hide-details="auto"
              >
              </v-text-field>
            </td>
            <td v-else></td>
            <td v-if="typeof item.Recodes.FID11496 != 'undefined'">
              <v-dialog
                v-model="item.Recodes.FID11496.modal"
                :max-width="resizeWidth"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs">
                    <v-text-field
                      label="前月繰越"
                      v-model="item.Recodes.FID11496.value"
                      :disabled="item.DeleteFlag"
                      :rules="[rules.required, rules.smallNum]"
                      id="FID11496"
                      class="textfield11496"
                      append-icon="mdi-calculator"
                      @click:append="on.click"
                      hide-details="auto"
                    >
                    </v-text-field></div></template
                ><input-Number
                  v-if="item.Recodes.FID11496.modal"
                  title="前月繰越"
                  :value="item.Recodes.FID11496.value"
                  :rules="[rules.required, rules.smallNum]"
                  v-on:ok="
                    (item.Recodes.FID11496.modal = false),
                      $set(item.Recodes.FID11496, 'value', $event)
                  "
                  type="calculator"
                ></input-Number>
              </v-dialog>
            </td>
            <td v-else></td>
            <td v-if="typeof item.Recodes.FID11497 != 'undefined'">
              <v-dialog
                v-model="item.Recodes.FID11497.modal"
                :max-width="resizeWidth"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs">
                    <v-text-field
                      label="入荷累計"
                      v-model="item.Recodes.FID11497.value"
                      :disabled="item.DeleteFlag"
                      :rules="[rules.required, rules.smallNum]"
                      id="FID11497"
                      class="textfield11497"
                      append-icon="mdi-calculator"
                      @click:append="on.click"
                      hide-details="auto"
                    >
                    </v-text-field></div></template
                ><input-Number
                  v-if="item.Recodes.FID11497.modal"
                  title="入荷累計"
                  :value="item.Recodes.FID11497.value"
                  :rules="[rules.required, rules.smallNum]"
                  v-on:ok="
                    (item.Recodes.FID11497.modal = false),
                      $set(item.Recodes.FID11497, 'value', $event)
                  "
                  type="calculator"
                ></input-Number>
              </v-dialog>
            </td>
            <td v-else></td>
            <td v-if="typeof item.Recodes.FID11498 != 'undefined'">
              <v-dialog
                v-model="item.Recodes.FID11498.modal"
                :max-width="resizeWidth"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs">
                    <v-text-field
                      label="出荷累計"
                      v-model="item.Recodes.FID11498.value"
                      :disabled="item.DeleteFlag"
                      :rules="[rules.required, rules.smallNum]"
                      id="FID11498"
                      class="textfield11498"
                      append-icon="mdi-calculator"
                      @click:append="on.click"
                      hide-details="auto"
                    >
                    </v-text-field></div></template
                ><input-Number
                  v-if="item.Recodes.FID11498.modal"
                  title="出荷累計"
                  :value="item.Recodes.FID11498.value"
                  :rules="[rules.required, rules.smallNum]"
                  v-on:ok="
                    (item.Recodes.FID11498.modal = false),
                      $set(item.Recodes.FID11498, 'value', $event)
                  "
                  type="calculator"
                ></input-Number>
              </v-dialog>
            </td>
            <td v-else></td>
            <td v-if="typeof item.Recodes.FID11499 != 'undefined'">
              <v-dialog
                v-model="item.Recodes.FID11499.modal"
                :max-width="resizeWidth"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs">
                    <v-text-field
                      label="現在時点在庫"
                      v-model="item.Recodes.FID11499.value"
                      :disabled="item.DeleteFlag"
                      :rules="[rules.required, rules.smallNum]"
                      id="FID11499"
                      class="textfield11499"
                      append-icon="mdi-calculator"
                      @click:append="on.click"
                      hide-details="auto"
                    >
                    </v-text-field></div></template
                ><input-Number
                  v-if="item.Recodes.FID11499.modal"
                  title="現在時点在庫"
                  :value="item.Recodes.FID11499.value"
                  :rules="[rules.required, rules.smallNum]"
                  v-on:ok="
                    (item.Recodes.FID11499.modal = false),
                      $set(item.Recodes.FID11499, 'value', $event)
                  "
                  type="calculator"
                ></input-Number>
              </v-dialog>
            </td>
            <td v-else></td>
            <td v-if="typeof item.Recodes.FID11819 != 'undefined'">
              <v-autocomplete
                label="OTB"
                v-model="item.Recodes.FID11819.value"
                :disabled="item.DeleteFlag"
                :rules="[rules.required, rules.containBox11819]"
                id="FID11819"
                class="selectBox11819"
                no-data-text="データが見つかりません"
                clearable
                :items="selectBoxFID11819"
                hide-details="auto"
              >
              </v-autocomplete>
            </td>
            <td v-else></td>
            <td v-if="buttonShow">
              <v-btn
                @click="subPageDel(item)"
                :color="item.DeleteFlag ? restoreBtnColor : deleteBtnColor"
                :disabled="!item.DeleteFlag && min >= subPageCount"
                >{{ item.DeleteFlag ? restoreBtnText : deleteBtnText }}</v-btn
              >
            </td>
            <td v-else></td></tr
        ></template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import inputNumber from "../views/inputNumber";

export default {
  name: "subPageTable",
  components: {
    inputNumber,
  },
  props: {
    setSubReports: {
      type: Array,
      required: true,
    },
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      data: {
        PageID: 1666,
        ReportID: null,
        Recodes: {
          FID11494: { value: "", formType: 1, disabled: false },
          FID11495: { value: "", formType: 1, disabled: false },
          FID11496: { value: "0", formType: 1, disabled: false, modal: false },
          FID11497: { value: "0", formType: 1, disabled: false, modal: false },
          FID11498: { value: "0", formType: 1, disabled: false, modal: false },
          FID11499: { value: "0", formType: 1, disabled: false, modal: false },
          FID11819: { value: "", formType: 2, disabled: false },
        },
        DeleteFlag: false,
        DeleteSubReports: [],
      },

      selectBoxFID11819: ["オイル", "バッテリー", "タイヤ", "その他"],

      PageID: 1666,
      subReports: [],
      buttonShow: true,
      addBtnText: "追加",
      addBtnColor: "primary",
      deleteBtnText: "削除",
      deleteBtnColor: "red white--text",
      restoreBtnText: "復元",
      restoreBtnColor: "green white--text",
      itemsPerPage: -1,
      rules: {
        required: (value) => !!value || "必須項目です",
        smallNum: (value) => {
          return /^(\d*.?\d+)*$/.test(value) || "数値のみ入力可能です。";
        },

        containBox11819: (value) => {
          if (typeof this.selectBoxFID11819 == "undefined") {
            return true;
          }
          return this.selectBoxFID11819.includes(value) ||
            value == "" ||
            value == null
            ? true
            : "選択肢にない項目が入力されています";
        },
      },
    };
  },
  async created() {
    await this.getInit();
  },
  computed: {
    headers() {
      return [
        {
          text: "商品コード",
          value: "Recodes.FID11494.value",
          align: "start",
          sortable: false,
        },
        {
          text: "商品名",
          value: "Recodes.FID11495.value",
          align: "start",
          sortable: false,
        },
        {
          text: "前月繰越",
          value: "Recodes.FID11496.value",
          align: "start",
          sortable: false,
        },
        {
          text: "入荷累計",
          value: "Recodes.FID11497.value",
          align: "start",
          sortable: false,
        },
        {
          text: "出荷累計",
          value: "Recodes.FID11498.value",
          align: "start",
          sortable: false,
        },
        {
          text: "現在時点在庫",
          value: "Recodes.FID11499.value",
          align: "start",
          sortable: false,
        },
        {
          text: "OTB",
          value: "Recodes.FID11819.value",
          align: "start",
          sortable: false,
        },
        { text: "", value: "Del", sortable: false },
      ];
    },
    subPageCount: {
      cache: false,
      get() {
        return this.subReports.filter((item) => !item.DeleteFlag).length;
      },
    },

    resizeWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "270px";
        case "sm":
          return "500px";
        case "md":
          return "600px";
        case "lg":
          return "500px";
        case "xl":
          return "500px";
        default:
          return "500px";
      }
    },
  },
  watch: {
    $route() {
      this.getInit();
    },
    subReports: {
      handler: async function (newData) {
        this.$emit("update:subReports", newData);
      },
      deep: true,
    },
  },
  methods: {
    async getInit() {
      this.initSubPage();
    },
    initSubPage() {
      this.setSubReports.forEach((item) => this.subReports.push(item));
      if (this.subReports.length < this.min) {
        for (let i = 0; i < this.min; i++) {
          if (!this.subReports[i]) {
            this.subReports.push(JSON.parse(JSON.stringify(this.data)));
          }
        }
      }
    },
    subPageAdd(index) {
      if (typeof index == "undefined") {
        index = this.subReports.length;
      }
      if (this.subReports.length < this.max) {
        this.subReports.splice(index, 0, JSON.parse(JSON.stringify(this.data)));
      } else {
        alert(`追加は最大${this.max}件までです`);
      }
    },
    subPageDel(item) {
      this.buttonShow = false;
      item.DeleteFlag = !item.DeleteFlag;
      this.buttonShow = true;
    },
  },
};
</script>
<style></style>
