import Repository from "./go_api";
import store from "../store/vuex";

const resource = "/api/eneosPatrol/v2/resource";

export default {
  async SendMail(data) {
    const result = await this.API_Post(data, resource + "/mail/send");
    return result.data;
  },
  async API_Post(data, URL) {
    const result = await Repository.post(URL, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${store.state.accessToken}`,
      },
    }).catch(function(error) {
      const badResult = {
        data: {
          "error code": "0830",
          message: "エラーコード[0830]",
          status: "failed",
        },
      }
      if (error.isAxiosError) {
        if (error.response.status === 404) {
          badResult.data["error code"] = "404"
          badResult.data.message = "API not found"
          return badResult
        } else if (error.response.status === 400) {
          badResult.data["error code"] = "400";
          let message = "エラーコード[0830]";
          if (error.response.data && error.response.data.text) {
            message = error.response.data.text;
          } else if (error.response.data && error.response.data.message) {
            message = error.response.data.message;
          }
          badResult.data.message = message;
          alert(badResult.data.message);
          return badResult;
        }
        if (error.code === "ECONNABORTED") {
          alert("エラーコード[0830-1]セッション切断") //APIへのリクエスト失敗
          badResult["error code"] = "0830-1"
          badResult.message = "エラーコード[0830-1]セッション切断"
          return badResult
        }
        alert("エラーコード[0830]") //APIへのリクエスト失敗
        return badResult
      }
      const errorMessage = error.response.data || error.message
      console.log("post:", errorMessage)
      return badResult
    })
    return result
  },
};
