<template>
  <div>
    <v-row>
      <v-col>
        <v-menu
          v-model="modal"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="改善予定日"
              :value="FIDData.value"
              @change="changeValue"
              :rules="[rules.toDayAfter]"
              :disabled="FIDData.disabled || disabled"
              :background-color="inputColor(FIDData)"
              append-icon="mdi-calendar"
              outlined
              v-on="on"
              @click:append="on.click"
              readonly
              hide-details="auto"
              clearable
              persistent-hint
            >
            </v-text-field> </template
          ><v-date-picker
            :value="FIDData.value"
            @change="changeValue"
            no-title
            scrollable
            locale="ja-jp"
            :day-format="(date) => new Date(date).getDate()"
            @input="modal = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { isAllInputedNow, isNotAllCheckNowOK } from "./specialMethod";
import { spInputColor } from "./specialMethod";

export default {
  name: "subPagePatrolReportCheckSpecialInputDate",
  components: {},
  props: {
    data: { default: () => {}, type: Object },
    mainRecodes: { default: () => {}, type: Object },
    FIDData: { default: () => {}, type: Object },
  },
  data() {
    return {
      disabled: false,
      modal: false,
      rules: {
        toDayAfter: (value) => {
          if (!value) {
            return true;
          }
          // 確定済み（改善予定日確定日時がある）場合
          if (this.data.Recodes && this.data.Recodes.FID12026 && this.data.Recodes.FID12026.value) {
            return true;
          }
          // 本日の日付を取得
          const today = new Date()
          // UTCに9時間足してJSTへ変換
          today.setHours(today.getHours() + 9);
          // 本日の日付を文字列に変換 YYYY-MM-DD
          const todayStr = today.toISOString().match(/^\d{4}-\d{2}-\d{2}/g).pop();
          if (value < todayStr) {
            return "本日以降のみ指定可能";
          }
          return true;
        },
      },
    };
  },
  created() {
    //
  },
  computed: {
    // 前回GM確認日
    lastGlConfirmedDate() {
      return this.data.Recodes && this.data.Recodes.FID12027 ? this.data.Recodes.FID12027.value : "";
    }
  },
  watch: {
    mainRecodes: {
      handler: function (mainRecodes) {
        if (mainRecodes) {
          const mainData = { Recodes: mainRecodes };
          // 「今回」が全て入力されている、かつ全てチェックがOKでない場合に入力可能
          if (isAllInputedNow(mainData) && isNotAllCheckNowOK(mainData)) {
            this.disabled = false;
          } else {
            this.disabled = true;
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    changeValue(value) {
      this.$emit("changeValue", value ? String(value) : "");
    },
    inputColor(field) {
      let data = {
        Recodes: this.mainRecodes
      }
      if (this.disabled) return "white"
      return spInputColor(data, field)
    },
  },
};
</script>
<style></style>
