export const path = [
  {
    path: "/login",
    name: "Login",
    component: require("../views/Auth/Login.vue").default,
  },
  {
    path: "/patrolReport/create",
    name: "GL巡回レポート",
    component: require("../views/patrolreportCreate.vue").default,
    meta: { requiresAuth: true },
  },
  {
    path: "/patrolReport/create/:report_id",
    name: "GL巡回レポート_update",
    component: require("../views/patrolreportCreate.vue").default,
    meta: { requiresAuth: true },
  },
  {
    path: "/patrolReport/index",
    name: "GL巡回レポート一覧",
    component: require("../views/patrolreportIndex.vue").default,
    meta: { requiresAuth: true },
  },
  {
    path: "/user/index",
    name: "ユーザ情報一覧",
    component: require("../views/userIndex.vue").default,
    meta: { requiresAuth: true },
  },
  {
    path: "/user/setting/:report_id",
    name: "ユーザ情報設定",
    component: require("../views/userSetting.vue").default,
    meta: { requiresAuth: true },
  },
  {
    path: "/moneyCheck/create",
    name: "金銭点検確認表",
    component: require("../views/moneyCheckCreate.vue").default,
    meta: { requiresAuth: true },
  },
  {
    path: "/moneyCheck/create/:report_id",
    name: "金銭点検確認表_update",
    component: require("../views/moneyCheckCreate.vue").default,
    meta: { requiresAuth: true },
  },
  {
    path: "/stockCheck/create",
    name: "在庫差異確認表",
    component: require("../views/stockCheckCreate.vue").default,
    meta: { requiresAuth: true },
  },
  {
    path: "/stockCheck/create/:report_id",
    name: "在庫差異確認表_update",
    component: require("../views/stockCheckCreate.vue").default,
    meta: { requiresAuth: true },
  },
  {
    path: "/moneyCheck/index",
    name: "金銭点検表一覧",
    component: require("../views/moneyCheckIndex.vue").default,
    meta: { requiresAuth: true },
  },
  {
    path: "/stockCheck/index",
    name: "在庫差異確認表一覧",
    component: require("../views/stockCheckIndex.vue").default,
    meta: { requiresAuth: true },
  },
  {
    path: "/setting",
    name: "設定",
    component: require("../views/setting.vue").default,
    meta: { requiresAuth: true },
  },
  {
    path: "/ssMaster/index",
    name: "SSマスタ一覧",
    component: require("../views/ssMasterIndex.vue").default,
    meta: { requiresAuth: true },
  },
  {
    path: "/ssMaster/create",
    name: "SSマスタ登録・更新",
    component: require("../views/ssMasterCreate.vue").default,
    meta: { requiresAuth: true },
  },
  {
    path: "/ssMaster/create/:report_id",
    name: "SSマスタ登録・更新_update",
    component: require("../views/ssMasterCreate.vue").default,
    meta: { requiresAuth: true },
  },
  {
    path: "/stockMaster/index",
    name: "在庫マスタ一覧",
    component: require("../views/stockMasterIndex.vue").default,
    meta: { requiresAuth: true },
  },
  {
    path: "/stockMaster/create",
    name: "在庫マスタ登録・新規",
    component: require("../views/stockMasterCreate.vue").default,
    meta: { requiresAuth: true },
  },
  {
    path: "/stockMaster/create/:report_id",
    name: "在庫マスタ登録・新規_update",
    component: require("../views/stockMasterCreate.vue").default,
    meta: { requiresAuth: true },
  },
  {
    path: "/moneycheck/select",
    name: "金銭点検チェック店舗選択",
    component: require("../views/moneyCheckSelect.vue").default,
    meta: { requiresAuth: true },
  },
  {
    path: "/stockCheck/select",
    name: "在庫点検チェック店舗選択",
    component: require("../views/stockCheckSelect.vue").default,
    meta: { requiresAuth: true },
  },
  {
    path: "/patrolReport/select",
    name: "巡回レポート店舗選択",
    component: require("../views/patrolReportSelect.vue").default,
    meta: { requiresAuth: true },
  },
  {
    path: "/product/index",
    name: "商品マスタ一覧",
    component: require("../views/productIndex.vue").default,
    meta: { requiresAuth: true },
  },
  {
    path: "/product/create",
    name: "商品マスタ登録・更新",
    component: require("../views/productCreate.vue").default,
    meta: { requiresAuth: true },
  },
  {
    path: "/product/create/:report_id",
    name: "商品マスタ登録・更新_update",
    component: require("../views/productCreate.vue").default,
    meta: { requiresAuth: true },
  },
  {
    path: "/",
    name: "選択・ホーム画面",
    component: require("../views/home.vue").default,
    meta: { requiresAuth: true },
  },
  {
    path: "/gl/create",
    name: "担当GLマスタ",
    component: require("../views/glCreate.vue").default,
    meta: { requiresAuth: true },
  },
  {
    path: "/gl/create/:report_id",
    name: "担当GLマスタ_update",
    component: require("../views/glCreate.vue").default,
    meta: { requiresAuth: true },
  },
  {
    path: "/gl/index",
    name: "担当GLマスタ一覧",
    component: require("../views/glIndex.vue").default,
    meta: { requiresAuth: true },
  },
  {
    path: "/csvImport/product",
    name: "CSV取り込み：商品マスタ",
    component: require("../views/csvImportProduct.vue").default,
    meta: { requiresAuth: true },
  },
  {
    path: "/csvImport/stock",
    name: "CSV取り込み：在庫データ",
    component: require("../views/csvImportStock.vue").default,
    meta: { requiresAuth: true },
  },
  {
    path: "/stockCheck/before/create",
    name: "在庫差異チェック：在庫データ未取込画面",
    component: require("../views/stockCheckCreateBefore.vue").default,
    meta: { requiresAuth: true },
  },
  {
    path: "/stockCheck/before/create/:report_id",
    name: "在庫差異チェック：在庫データ未取込画面_update",
    component: require("../views/stockCheckCreateBefore.vue").default,
    meta: { requiresAuth: true },
  },
  {
    path: "/csvExcelImportSsMaster",
    name: "CSV・エクセルSSデータ取り込み",
    component: require("../views/csvExcelImportSsMaster.vue").default,
    meta: { requiresAuth: true },
  },
];
