<template>
  <div class="home">
    <p>SSマスタ登録・更新</p>
    <v-row>
      <v-col align="right"><v-btn @click.once="back">戻る</v-btn></v-col>
    </v-row>
    <v-container cols="12">
      <div v-if="isLoading" align="center">
        <v-progress-circular
          :size="250"
          color="primary"
          indeterminate
          align="center"
        ></v-progress-circular>
      </div>
      <div v-else>
        <v-form ref="form" @submit.prevent>
          <v-row>
            <v-col cols="12" sm="6" md="4" lg="4">
              <v-text-field
                label="SSコード"
                v-model="data.Recodes.FID11265.value"
                :rules="[rules.required]"
                background-color="white"
                id="FID11265"
                class="textfield11265"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4">
              <v-text-field
                label="SS名"
                v-model="data.Recodes.FID11266.value"
                :rules="[rules.required]"
                background-color="white"
                id="FID11266"
                class="textfield11266"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="4" lg="4">
              <v-text-field
                label="事業部コード"
                v-model="data.Recodes.FID13155.value"
                :rules="[rules.required]"
                background-color="white"
                id="FID13155"
                class="textfield13155"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4">
              <v-text-field
                label="直営事業部名"
                v-model="data.Recodes.FID11267.value"
                :rules="[rules.required]"
                background-color="white"
                id="FID11267"
                class="textfield11267"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4">
              <v-text-field
                label="Gr（グループ）"
                v-model="data.Recodes.FID11268.value"
                :rules="[]"
                background-color="white"
                id="FID11268"
                class="textfield11268"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6" lg="6">
              <v-text-field
                label="店舗メールアドレス"
                v-model="data.Recodes.FID12529.value"
                :rules="[rules.validMail]"
                background-color="white"
                id="FID12529"
                class="textfield12529"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="4" lg="4">
              <v-text-field
                label="GM社員コード"
                v-model="data.Recodes.FID13156.value"
                :rules="[rules.required]"
                background-color="white"
                id="FID13156"
                class="textfield13156"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4">
              <v-text-field
                label="GM名"
                v-model="data.Recodes.FID11271.value"
                :rules="[rules.required]"
                background-color="white"
                id="FID11271"
                class="textfield11271"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6">
              <v-text-field
                label="GMメールアドレス"
                v-model="data.Recodes.FID13157.value"
                :rules="[rules.validMail, rules.required]"
                background-color="white"
                id="FID13157"
                class="textfield13157"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="4" lg="4">
              <v-text-field
                label="MG社員コード"
                v-model="data.Recodes.FID13365.value"
                :rules="[]"
                background-color="white"
                id="FID13365"
                class="textfield13365"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4">
              <v-text-field
                label="MG名"
                v-model="data.Recodes.FID11269.value"
                :rules="[]"
                background-color="white"
                id="FID11269"
                class="textfield11269"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row
            v-if="
              typeof data.Recodes.FID13326.subReports != 'undefined' &&
              data.Recodes.FID13326.subReports.length > 0
            "
          >
            <v-col
              cols="12"
              v-for="(report, i) in data.Recodes.FID13326.subReports"
              :key="`[13326-${i}`"
            >
              <subPageSMG
                label=""
                background-color="white"
                :id="'FID13326-' + i"
                class="subPage13326"
                ref="subPage13326"
                :Report="report"
                :subindex="i"
                :daleteReports.sync="data.DeleteSubReports"
                v-on:clickSubmit="saveSubPage"
                :myInit="subInit"
                FID="FID13326"
                :mainRecodes="data.Recodes"
                hide-details="auto"
              ></subPageSMG>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="left">
              <v-btn
                @click="subPageDel('FID13326')"
                :disabled="data.Recodes.FID13326.subReports.length == 0"
                color="orange white--text"
                class="ma-2"
              >
                削除
              </v-btn>
              <v-btn
                @click="subPageAdd('FID13326')"
                color="light-blue white--text"
                class="ma-2"
              >
                追加
              </v-btn>
            </v-col>
          </v-row>
          <!-- 認証指定 -->
          <!-- <v-row>
            <v-col cols="7" sm="7" md="0" lg="3">
              <v-row no-gutters>
                <v-col
                  v-for="label in data.Recodes.FID11273.labels"
                  :key="label"
                  cols="12"
                  sm="12"
                  :md="data.Recodes.FID11273.labels.length > 4 ? 4 : 6"
                  :lg="data.Recodes.FID11273.labels.length > 4 ? 4 : 6"
                >
                  <v-card class="pa-2" tile>
                    <v-checkbox
                      class="black--text ma-1"
                      v-model="FID11273json"
                      :label="label"
                      :value="label"
                      hide-details="auto"
                      :rules="[
                        rules.checkBoxMinFID11273,
                        rules.checkBoxMax11273,
                      ]"
                    >
                      <template slot="label">
                        {{ label }}
                      </template>
                    </v-checkbox>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row> -->

          <v-row>
            <v-col align="left">
              <v-btn
                @click="deleteModal = true"
                :disabled="data.ReportID == null || disabled"
                color="red white--text"
                >削除
              </v-btn>
              <v-dialog v-model="deleteModal" max-width="400">
                <v-card>
                  <v-card-title class="text-h5"> 削除しますか？ </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="mr-3"
                      color="green darken-1 white--text"
                      @click="deleteModal = false"
                      large
                    >
                      いいえ
                    </v-btn>
                    <v-btn color="red darken-1 white--text" @click="del" large>
                      はい
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col v-if="showTemplate11274">
              <v-btn
                @click="save11274()"
                color="primary"
                fixed
                bottom
                :style="'right:+50px;'"
                >送信</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-container>
  </div>
</template>

<script>
import store from "../store/vuex.js";
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");
import subPageSMG from "../views/subPageSMG";

import { validMail } from "./specialMethod";

export default {
  name: "Home",
  components: {subPageSMG},
  data() {
    return {
      isLoading: false,
      escapeValidation: false,
      showChild: true,
      subInit: false,
      disabled: false,
      data: {
        PageID: 1661,
        ReportID: null, //新規ならnull、更新ならidがある
        Recodes: {
          FID11265: { value: "", formType: 1, disabled: false }, //SSコード
          FID11266: { value: "", formType: 1, disabled: false }, //SS名
          FID11267: { value: "", formType: 1, disabled: false }, //直営事業部名
          FID11268: { value: "", formType: 1, disabled: false }, //Gr（グループ）
          FID11269: { value: "", formType: 1, disabled: false }, //MG(マネージャー)名
          // FID11270: { value: "", formType: 1, disabled: false }, //廃止:SMG(サブマネージャー)名
          FID11271: { value: "", formType: 1, disabled: false }, //GM(グループマネージャー)名:旧GL(グループリーダー)
          // FID11272: { value: "", formType: 1, disabled: false }, //廃止：釣銭準備金
          FID11273: {
            value: "",
            formType: 14,
            disabled: false,
            color: "black",
            labels: ["認証指定"],
          }, //廃止：認証指定
          FID12529: { value: "", formType: 1, disabled: false }, //店舗メールアドレス
          FID11274: { value: "", formType: 20, disabled: false }, //登録日時
          FID13155: { value: "", formType: 1, disabled: false }, //事業部コード
          FID13156: { value: "", formType: 1, disabled: false }, //GM社員コード
          FID13157: { value: "", formType: 1, disabled: false }, //GMメールアドレス
          FID13365: { value: "", formType: 1, disabled: false }, //MG社員コード
          FID13326: {
            value: "PID1842",
            formType: 17,
            disabled: false,
            subReports: [],
          },//SMG(サブマネージャー)名
        },
        DeleteSubReports: [],
      },
      FID11273json: [""],
      duplicateCheckFID11265: "",

      rules: {
        required: (value) => !!value || "必須項目です",
        checkBoxMinFID11273: (value) => {
          return value.filter((item) => item !== "").length >= 0 ? true : "";
        },
        checkBoxMax11273: (value) => {
          return value.filter((item) => item !== "").length <= 10 ? true : "";
        },
        validMail: (value) => {
          return validMail(value);
        },
      },
      deleteModal: false,
      copyFlag: false,
      sendCopyFlag: false,
      noBack: false,
      backCount: -1,
    };
  },
  async created() {
    await this.getInit();
  },

  computed: {
    showTemplate11274() {
      return true;
    },
  },
  watch: {
    $route() {
      this.getInit();
    },

    FID11273json: function (value) {
      this.$set(
        this.data.Recodes.FID11273,
        "value",
        value.filter((item) => item !== "").join(",")
      );
    },
  },
  methods: {
    async getInit() {
      try {
        //初期処理
        this.isLoading = true;
        //
        if (this.$route.query.copyFlag) {
          this.reportCopy();
        } else if (this.$route.params.report_id != null) {
          this.data.ReportID = Number(this.$route.params.report_id);
          //データ取得
          let data = {
            PageID: this.data.PageID,
            ReportID: Number(this.data.ReportID),
          };
          const result = await api.ReportDetailV2(data);
          this.RecodeCopy(this.data, result.Recodes);
          //初期処理
        }
        //データ取得処理

        //init処理
        this.initFID11273();
        this.initSubPage13326();
        this.setDuplicateCheckFID11265();

        //読み込み終了
        this.isLoading = false;
      } catch (e) {
        console.log(e);
        alert("読み込みに失敗しました");
        this.back();
      }
    },
    RecodeCopy(data, response) {
      for (const [FID] of Object.entries(data.Recodes)) {
        if (typeof response[FID] != "undefined") {
          data.Recodes[FID].value = response[FID].value;
        }
        if (
          typeof response[FID] != "undefined" &&
          typeof response[FID].subReports != "undefined" &&
          response[FID].subReports != null
        ) {
          data.Recodes[FID].subReports = response[FID].subReports;
        }
      }
    },
    getDateTime() {
      let date = new Date();
      let years = date.getFullYear().toString();
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let days = date.getDate().toString().padStart(2, "0");
      let hours = date.getHours().toString().padStart(2, "0");
      let minutes = date.getMinutes().toString().padStart(2, "0");
      let seconds = date.getSeconds().toString().padStart(2, "0");
      //
      let value =
        years +
        "-" +
        month +
        "-" +
        days +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds;
      if (value == " ") {
        return "";
      }
      return value;
    },
    async save11274() {
      this.data.Recodes.FID11274.value = this.getDateTime();

      var duplicateCheck0 = await api.FormDetail(11265);
      duplicateCheck0 = duplicateCheck0.filter((v) => v !== "");
      const count0 = duplicateCheck0.reduce((acc, val) => {
        return val === this.data.Recodes.FID11265.value ? acc + 1 : acc;
      }, 0);
      if (
        (this.$route.params.report_id != null &&
          count0 > 0 &&
          this.duplicateCheckFID11265 != this.data.Recodes.FID11265.value) ||
        (this.$route.params.report_id == null && count0 > 0)
      ) {
        alert("既に入力されています");
        return;
      }
      await this.send();
    },
    initFID11273() {
      if (this.data.Recodes.FID11273.value != "") {
        this.FID11273json = this.data.Recodes.FID11273.value.split(",");
      }
    },
    setDuplicateCheckFID11265() {
      if (
        this.data.ReportID != null &&
        this.data.Recodes.FID11265.value != ""
      ) {
        this.duplicateCheckFID11265 = this.data.Recodes.FID11265.value;
      }
    },

    checkValidation: async function () {
      let form = this.$refs.form;
      let check = form.validate();
      if (!check) {
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        return check;
      }

      return check;
    },
    async send() {
      const result = this.escapeValidation
        ? true
        : await this.checkValidation();

      if (result) {
        this.isLoading = true;

        const save = await api.SendReport(this.data);
        if (save == false || save.result == "Fail") {
          alert("登録失敗[Error0830-1]");
          this.isLoading = false;
          return;
        }
        console.log("SendPage1661");

        if (this.sendCopyFlag) {
          this.sendCopy();
          return;
        }
        alert("登録完了");
        if (!this.noBack) {
          this.back();
        } else {
          this.$set(this.$route.params, "report_id", save.reportID);
          this.$set(this.data, "ReportID", save.reportID);
          await this.getInit();
          this.isLoading = false;
          this.noBack = false;
        }
      }
    },
    back() {
      store.commit("clearreports");
      if (this.copyFlag) {
        history.go(this.backCount);
      } else {
        history.back();
      }
    },
    async del() {
      this.isLoading = true;
      await api.DelReport(this.data);
      alert("削除完了");
      this.back();
    },
    subPageAdd(FID, index) {
      this.isLoading = true;
      if (
        typeof this.data.Recodes[FID] == "undefined" ||
        typeof this.data.Recodes[FID].subReports == "undefined"
      ) {
        index = 0;
        this.data.Recodes[FID].subReports = [];
      }
      if (typeof index == "undefined") {
        index = this.data.Recodes[FID].subReports.length;
      }
      let max = 20;
      if (this.data.Recodes[FID].subReports.length < max) {
        this.data.Recodes[FID].subReports.splice(index, 0, {});
      }
      this.isLoading = false;
    },
    subPageDel(FID, index) {
      //this.subInit = false;
      let min = 1;
      if (typeof index == "undefined") {
        index = this.data.Recodes[FID].subReports.length - 1;
      }
      if (this.data.Recodes[FID].subReports.length > min) {
        this.data.DeleteSubReports = this.data.DeleteSubReports.concat(
          this.data.Recodes[FID].subReports.splice(index, 1)
        );
      }
      //this.subInit = true;
    },
    saveSubPage(FID, subindex, reports) {
      this.data.Recodes[FID].subReports.splice(subindex, 1, reports);
      if (reports.DeleteSubReports.length == 1) {
        this.data.DeleteSubReports = this.data.DeleteSubReports.concat(
          reports.DeleteSubReports.pop()
        );
      }
    },
    initSubPage13326() {
      for (let i = this.data.Recodes.FID13326.subReports.length; i < 1; i++) {
        this.data.Recodes.FID13326.subReports.push({});
      }
    },
  },
};
</script>
<style></style>
