<template>
  <div class="home">
    <p>エクセルデータ取り込み：商品マスタ</p>
    <v-col align="right"><v-btn @click.once="back">戻る</v-btn></v-col>
    <div v-if="isLoading" align="center">
      <v-progress-circular
        :size="250"
        color="primary"
        indeterminate
        align="center"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-container cols="12">
        <v-form ref="form">
          <v-row>
            <v-checkbox
              v-model="header_flag"
              label="ヘッダー有"
            ></v-checkbox> </v-row
          ><v-row
            ><v-file-input
              v-model="uploadfile.file"
              type="file"
              label="エクセル取り込み(xlsx)"
              prepend-icon="mdi-image"
              ref="rfafile"
              accept=".xlsx,.xlsm"
              @change="chargeExcel"
              :rules="[rules.fileSize]"
              show-size
            ></v-file-input
          ></v-row>
          <v-row class="ma-0 mt-3">
            <v-col cols="12" class="ma-0 pa-0">★取込ファイルのヘッダー情報</v-col>
            <v-col cols="12" class="ma-0 pa-0">
              【A列】商品コード,【B列】商品名称,【C列】商品分類1,【D列】商品分類3,【E列】任意分類1,【F列】売上分類
            </v-col>
          </v-row>
          <v-row>
            <v-col align="right">
              <v-btn @click="send" color="primary" :disabled="!sendFlag" :loading="isLoading"
                >送信</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="">
              <v-data-table
                :headers="headers"
                :items="reports"
                :item-key="reports.ID"
                :header-props="{
                  'sort-by-text': '並び順',
                }"
                :footer-props="{
                  'items-per-page-text': '行/ページ:',
                }"
                no-data-text="データが存在しません"
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </div>

    <!-- <loading :active.sync="isLoading" :is-full-page="fullPage"></loading> -->
  </div>
</template>

<script>
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");
import  * as XLSX from "xlsx";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      isLoading: false,
      fullPage: false,
      headerText: ",", //`$$headerText`,
      valid: true,
      data: {
        PageID: 1676, //
        Page_id: 1676, //送信に使っているが本来は変換する
        Report_id: null, //
        UpdateType: "update",
        KeyID: [11896],
        KeyMap: {
          FID11896: 0,
        },
        Recodes: {
          FID11896: { value: "", formType: 1 },
          FID11897: { value: "", formType: 1 },
          FID12213: { value: "", formType: 1 },
          FID11898: { value: "", formType: 2 },
          FID11899: { value: "", formType: 20 },
        },
      },

      //
      sendFlag: false,
      uploadfile: { file: {}, url: "", data: { name: "", size: 0, type: "" } },
      header_flag: true,
      reports: [],
      rules: {
        fileSize: (value) => {
          return (
            value == null ||
            value.size < 15000000 ||
            "ファイルサイズを15MB未満にしてください"
          );
        },
      },
    };
  },
  async created() {
    await this.getInit();
  },
  computed: {
    headers() {
      return [
        { text: "商品コード", value: "Recodes.FID11896.value", sortable: true },
        { text: "商品名", value: "Recodes.FID11897.value", sortable: true },
        { text: "売上分類", value: "Recodes.FID12213.value", sortable: true },
        { text: "OTB", value: "Recodes.FID11898.value", sortable: true },
      ];
    },
  },
  methods: {
    async getInit() {
      //初期処理
      this.isLoading = true;
      //読み込み終了
      this.isLoading = false;
    },
    /*-----------------------------*/
    //文字コードのチェック
    charCodeConvert(file) {
      const Encoding = require("encoding-japanese");
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          //文字列をバイト列に変換
          var sjisArray = new Uint8Array(reader.result);
          const encoding = this.detectEncoding(reader.result);
          switch (encoding) {
            case "SJIS":
              var unicodeArray = Encoding.convert(sjisArray, {
                to: "UNICODE",
                from: "SJIS",
                type: "string",
              });
              resolve(unicodeArray);
              break;
            case "UTF8":
              var unicodeArray2 = Encoding.convert(sjisArray, {
                to: "UNICODE",
                from: "AUTO",
                type: "string",
              });
              resolve(unicodeArray2);
              break;
            default:
              alert("文字コードが対応していません:" + encoding);
          }
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      });
    },
    detectEncoding(buffer) {
      const uint8Array = new Uint8Array(buffer);
      if (
        uint8Array[0] === 0xef &&
        uint8Array[1] === 0xbb &&
        uint8Array[2] === 0xbf
      ) {
        return "UTF8";
      } else if (uint8Array[0] === 0xfe && uint8Array[1] === 0xff) {
        return "UTF16BE";
      } else if (uint8Array[0] === 0xff && uint8Array[1] === 0xfe) {
        return "UTF16LE";
      } else if (
        uint8Array[0] === 0 &&
        uint8Array[1] === 0 &&
        uint8Array[2] === 0xfe &&
        uint8Array[3] === 0xff
      ) {
        return "UTF32BE";
      } else if (
        uint8Array[0] === 0xff &&
        uint8Array[1] === 0xfe &&
        uint8Array[2] === 0 &&
        uint8Array[3] === 0
      ) {
        return "UTF32LE";
      } else if (
        uint8Array[0] === 0 &&
        uint8Array[1] === 0 &&
        uint8Array[2] === 0 &&
        uint8Array[3] !== 0
      ) {
        return "UTF32BE-noBOM";
      } else if (
        uint8Array[0] !== 0 &&
        uint8Array[1] === 0 &&
        uint8Array[2] === 0 &&
        uint8Array[3] === 0
      ) {
        return "UTF32LE-noBOM";
      } else {
        return "SJIS";
      }
    },
    /*ダブルクォーテーション内のカンマは分割しない*/
    csvSplit(line) {
      let c = "";
      let s = new String();
      let data = new Array();
      let singleQuoteFlg = false;

      for (let i = 0; i < line.length; i++) {
        c = line.charAt(i);
        if (c == "," && !singleQuoteFlg) {
          data.push(s.toString());
          s = "";
        } else if (c == "," && singleQuoteFlg) {
          s = s + c;
        } else if (c == '"') {
          singleQuoteFlg = !singleQuoteFlg;
        } else {
          s = s + c;
        }
      }
      //最後のデータを追加
      data.push(s.toString());
      return data;
    },
    //現在のレポートとCSVの主キーが同じか確認する
    sameKeyCheck(data, csv) {
      let flag = true;
      Object.keys(data.KeyMap).map((key) => {
        const x = key;
        const y = data.KeyMap[key];
        if (data.Recodes[x].value == "" || csv[y] == "") {
          flag = false;
        }
        if (data.Recodes[x].value != csv[y]) {
          flag = false;
        }
      });
      return flag;
    },
    setValue(report, csv, to, from, tag) {
      if (typeof tag == "undefined") {
        if (typeof csv[from] != "undefined" && csv[from] != "") {
          report.Recodes[to].value = String(csv[from]).trim();
        }
        return;
      } else {
        switch (tag) {
          case "add":
            report.Recodes[to].value += String(csv[from]).trim();
            break;
        }
      }
    },
    async chargeExcel(event) {
      this.isLoading = true;
      try {
        const file = event;
        this.isLoading = false;
        if (file) {
          const reader = new FileReader();
          reader.onload = async (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]]; //シート番号
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            //先頭削除
            jsonData.shift();
            //データ処理
            this.reports = this.convertReports(jsonData);
            this.sendFlag = true;
            //バリデーションチェック
            this.isLoading = false;
            await this.$nextTick();
            await this.checkValidation();
          };
          reader.readAsArrayBuffer(file);
        }
        this.isLoading = false;
      } catch (e) {
        console.log("chargeExcel", e);
      }
    },
    convertReports(csv) {
      let data = [];
      let mainReport = JSON.parse(JSON.stringify(this.data));
      //
      csv.forEach((c, index) => {
        if (c.length > 1) {
          //主キーチェック
          if (!this.sameKeyCheck(mainReport, c)) {
            if (index > 0) {
              data.push(mainReport);
            }
            mainReport = JSON.parse(JSON.stringify(this.data));
            this.setValue(mainReport, c, "FID11896", 0); //商品コード
            this.setValue(mainReport, c, "FID11897", 1); //商品名
            this.setValue(mainReport, c, "FID12213", 5); //売上分類
            // 売上分類からOTBの設定
            const number = parseInt(mainReport.Recodes.FID12213.value);
            if (number == 41) {
              mainReport.Recodes.FID11898.value = "タイヤ";
            } else if (number == 42) {
              mainReport.Recodes.FID11898.value = "バッテリー";
            } else if (number >= 20 && number <= 29) {
              mainReport.Recodes.FID11898.value = "オイル";
            } else {
              mainReport.Recodes.FID11898.value = "その他";
            }
          }
          //サブレポート宣言処理

          //CSV読み込み時のデータ対応
          //サブレポートの追加処理
        }
      });
      data.push(mainReport);
      //
      return data;
    },
    // 既存の商品マスタデータを取得
    async getReports() {
      const data = {
        PageID: this.data.PageID,
        orderBy: "",
        search: [],
      };
      const reports = await api.ReportSearch(data);
      if (reports != null) {
        // PageIDを設定, FID0を削除
        reports.forEach((report) => {
          report.PageID = this.data.PageID;
          if (report.Recodes.FID0) {
            delete report.Recodes.FID0;
          }
        });
        return reports;
      }
      return [];
    },
    // 既存の商品マスタデータとCSVデータを比較して、更新、新規、削除のデータを取得
    getSendReports(reports, csvReports, primaryKeys) {
      const result = { updateReports: [], createReports: [], deleteReports: [] };
      reports.forEach((report) => {
        const csvReport = csvReports.find((csvReport) => {
          return this.isSameKey(report, csvReport, primaryKeys);
        });
        if (csvReport) {
          result.updateReports.push(this.getUpdateReport(report, csvReport));
        } else {
          result.deleteReports.push(report);
        }
      });
      csvReports.forEach((csvReport) => {
        const report = reports.find((report) => {
          return this.isSameKey(report, csvReport, primaryKeys);
        });
        if (!report) {
          result.createReports.push(csvReport);
        }
      });
      return result;
    },
    isSameKey(report, csvReport, primaryKeys) {
      return primaryKeys.every((key) => {
        return report.Recodes[key].value == csvReport.Recodes[key].value;
      });
    },
    getUpdateReport(report, csvReport) {
      const updateReport = JSON.parse(JSON.stringify(report));
      Object.keys(csvReport.Recodes).forEach((key) => {
        updateReport.Recodes[key].value = csvReport.Recodes[key].value;
      });
      return updateReport;
    },
    async send() {
      const isCorrectValue = this.checkValidation();
      if (isCorrectValue == false) return;
      this.isLoading = true;
      const getReports = await this.getReports();
      this.isLoading = false;
      const primaryKeys = this.data.KeyID.map((key) => "FID" + key);
      const getSendReports = this.getSendReports(getReports, this.reports, primaryKeys);
      const createCount = getSendReports.createReports.length;
      const updateCount = getSendReports.updateReports.length;
      const deleteCount = getSendReports.deleteReports.length;
      await this.$nextTick();
      if (confirm(`データを登録しますか？\n新規登録：${createCount}件\n更新：${updateCount}件\n削除：${deleteCount}件`) == false) {
        return;
      }
      this.isLoading = true;
      for (const createReport of getSendReports.createReports) {
        const result = await api.SendReport(createReport);
        if (result == false || result.result == "Fail") {
          alert("登録失敗[Error0830-1]");
          this.isLoading = false;
          return;
        }
      }
      for (const updateReport of getSendReports.updateReports) {
        const result = await api.SendReport(updateReport);
        if (result == false || result.result == "Fail") {
          alert("登録失敗[Error0830-1]");
          this.isLoading = false;
          return;
        }
      }
      for (const deleteReport of getSendReports.deleteReports) {
        const result = await api.DelReport(deleteReport);
        if (result == false || result.result == "Fail") {
          alert("登録失敗[Error0830-1]");
          this.isLoading = false;
          return;
        }
      }
      alert("登録完了");
      this.isLoading = false;
      history.back();
    },
    checkValidation: function () {
      let form = this.$refs.form;
      let check = form.validate();

      return check;
    },
    back() {
      history.back();
    },
  },
};
</script>
