// 【巡回レポート】
export const patrolReport = {
  // 「今回」「改善確認」「改善確認回数」のFID, ssCertification: SS認証併設のみ
  checkFIDs: [
    { now: "FID11788", improvement: "FID11789", count: "FID11989", ssCertification: false }, // 1.第一印象・クリンネス&提示物
    { now: "FID11793", improvement: "FID11794", count: "FID11990", ssCertification: false }, // 2.設備（固定資産/リース/社有車/各種設備/PC/消防関連）
    { now: "FID11798", improvement: "FID11799", count: "FID11991", ssCertification: false }, // 3.マネジメントツール・人材育成＆教育
    { now: "FID11803", improvement: "FID11804", count: "FID11992", ssCertification: false }, // 4.コンプライアンス（違法改造車/未認証での分解整備/不正な労働管理）
    { now: "FID12035", improvement: "FID12037", count: "FID12038", ssCertification: false }, // 5.実現金1
    { now: "FID12047", improvement: "FID12049", count: "FID12041", ssCertification: false }, // 5.実現金2
    { now: "FID12063", improvement: "FID12065", count: "FID12052", ssCertification: false }, // 5.実現金3
    { now: "FID12072", improvement: "FID12074", count: "FID12068", ssCertification: false }, // 5.実現金4
    { now: "FID12086", improvement: "FID12088", count: "FID12089", ssCertification: false }, // 5.実現金4-2
    { now: "FID12099", improvement: "FID12101", count: "FID12092", ssCertification: false }, // 5.在庫5
    { now: "FID12113", improvement: "FID12115", count: "FID12106", ssCertification: false }, // 5.在庫5-2
    { now: "FID12128", improvement: "FID12130", count: "FID12118", ssCertification: false }, // 5.点検6
    { now: "FID12140", improvement: "FID12142", count: "FID12133", ssCertification: true }, // 6.分解整備7
    { now: "FID12154", improvement: "FID12156", count: "FID12147", ssCertification: true }, // 6.分解整備8
    { now: "FID12167", improvement: "FID12169", count: "FID12159", ssCertification: true }, // 6.分解整備9
    { now: "FID12181", improvement: "FID12183", count: "FID12172", ssCertification: false }, // 7.
  ],
  // 「今回」「改善確認」の値
  checkValue: { ok: "〇", ng: "×", none: "該当なし" },
}

export const importJobNames = {
  "直営事業部長": "直営事業部長",
  "担当事業部長": "担当事業部長",
  "ｸﾞﾙｰﾌﾟﾏﾈｰｼﾞｬｰ": "GM",
  "ｸﾞﾙｰﾌﾟﾏﾈｰｼﾞｬｰ待遇": "GM",
  "ｻﾌﾞﾏﾈｰｼﾞｬｰ待遇": "SMG",
  "ｻﾌﾞﾏﾈｰｼﾞｬｰ": "SMG",
  "ﾏﾈｰｼﾞｬｰ待遇": "MG",
  "ﾏﾈｰｼﾞｬｰ": "MG",
}

export const importJobRoles = {
  "直営事業部長": { attribute: "admin", role: "1", name: "管理者" },
  "担当事業部長": { attribute: "admin", role: "1", name: "管理者" },
  "GM": { attribute: "group_admin", role: "2", name: "グループ管理者" },
  "SMG": { attribute: "general", role: "0", name: "一般" },
  "MG": { attribute: "general", role: "0", name: "一般" },
}
