<template>
  <div class="home">
    <!-- ナビメニュー -->
    <v-navigation-drawer app clipped>
      <patrolReportNavigationMenu
        v-on:patrolReport="moveToPatrolReport"
        v-on:moneyCheck="moveToMoneyCheck"
        v-on:stockCheck="moveToStockCheck"
      >
      </patrolReportNavigationMenu>
    </v-navigation-drawer>
    <p>CSV取り込み（在庫管理）</p>
    <v-row>
      <v-col align="right"><v-btn @click.once="back">戻る</v-btn></v-col>
    </v-row>
    <v-container cols="12">
      <div v-if="isLoading" align="center">
        <v-progress-circular
          :size="250"
          color="primary"
          indeterminate
          align="center"
        ></v-progress-circular>
      </div>
      <div v-else>
        <v-row class="mt-5">
          <v-col cols="12">
            在庫管理表の取り込みがされていないため、記録を作成することができません。
          </v-col>
          <v-col cols="12">
            CSVデータの流し込みをお願いいたします。
          </v-col>
        </v-row>
        <!-- <v-row class="mt-5">
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-btn
              label="CSV取り込みがされている場合"
              background-color="white"
              id="FID11988"
              class="button11988"
              color="green white--text"
              block
              style="font-size: 20px; letter-spacing: 10px"
              height="50"
              hide-details="auto"
            >
              CSV取り込みがされている場合</v-btn
            >
          </v-col>
        </v-row> -->
        <!-- ナビメニューから遷移のためpatrolreportCreateSpecial配置 -->
        <v-row v-show="false">
          <v-col cols="12" sm="12" md="12" lg="12">
            <patrolreportCreateSpecial
              :data="data"
              ref="patrolreportCreateSpecial"
            ></patrolreportCreateSpecial>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import store from "../store/vuex.js";
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");
import patrolreportCreateSpecial from "../views/patrolreportCreateSpecial";
import patrolReportNavigationMenu from "../views/patrolReportNavigationMenu";

export default {
  name: "stockCheckCreateBefore",
  components: {
    patrolreportCreateSpecial,
    patrolReportNavigationMenu,
  },
  data() {
    return {
      isLoading: false,
      backCount: -1,
      data: {},
      reportSerialNumber: "",
    };
  },
  async created() {
    await this.getInit();
  },

  computed: {
    //
  },
  watch: {
    $route() {
      this.getInit();
    },
  },
  methods: {
    async getInit() {
      try {
        //初期処理
        this.isLoading = true;
        //
        if (this.$route.query.copyFlag) {
          this.reportCopy();
        } else if (this.$route.params.report_id != null) {
          // this.data.ReportID = Number(this.$route.params.report_id);
          // //データ取得
          // let data = {
          //   PageID: this.data.PageID,
          //   ReportID: Number(this.data.ReportID),
          // };
          // const result = await api.ReportDetailV2(data);
          // this.RecodeCopy(this.data, result.Recodes);
          //初期処理
        }
        //データ取得処理

        //init処理
        this.reportSerialNumber = this.$store.state.reportSerialNumber;

        //データ取得
        const report = await this.getReport(1651, 11841); // 巡回レポート通し番号からデータ取得
        if (report.ReportID !== null) {
          this.data = report.Recodes;
        }
        store.commit("clearReportSerialNumber");

        //読み込み終了
        this.isLoading = false;
      } catch (e) {
        console.log(e);
        alert("読み込みに失敗しました");
        this.back();
      }
    },
    RecodeCopy(data, response) {
      for (const [FID] of Object.entries(data.Recodes)) {
        if (typeof response[FID] != "undefined") {
          data.Recodes[FID].value = response[FID].value;
        }
        if (
          typeof response[FID] != "undefined" &&
          typeof response[FID].subReports != "undefined" &&
          response[FID].subReports != null
        ) {
          data.Recodes[FID].subReports = response[FID].subReports;
        }
      }
    },
    getDateTime() {
      let date = new Date();
      let years = date.getFullYear().toString();
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let days = date.getDate().toString().padStart(2, "0");
      let hours = date.getHours().toString().padStart(2, "0");
      let minutes = date.getMinutes().toString().padStart(2, "0");
      let seconds = date.getSeconds().toString().padStart(2, "0");
      //
      let value =
        years +
        "-" +
        month +
        "-" +
        days +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds;
      if (value == " ") {
        return "";
      }
      return value;
    },
    back() {
      store.commit("clearreports");
      if (this.copyFlag) {
        history.go(this.backCount);
      } else {
        history.back();
      }
    },
    // ナビメニューからの遷移用
    moveToPatrolReport() {
      this.$refs.patrolreportCreateSpecial.movePatrolReport();
    },
    moveToMoneyCheck() {
      this.$refs.patrolreportCreateSpecial.moveMoneyCheck();
    },
    moveToStockCheck() {
      this.$refs.patrolreportCreateSpecial.moveStockCheck();
    },
    async getReport(pageId, formId) {
      const data = {
        PageID: pageId,
        orderBy: "",
        search: [],
      };
      let report = { ReportID: null };
      if (!this.reportSerialNumber) {
        return report;
      }
      data.search.push({
        form_id: formId,
        value: this.reportSerialNumber, //巡回レポート通し番号
        option: "match",
      });
      const response = await api.GetMasterV2(data);
      if (response.data.length == 1) {
        response.data[0].PageID = pageId;
        report = response.data[0];
      }
      return report;
    },
  },
};
</script>
<style></style>
