<template>
  <div class="home">
    <p>ユーザ情報設定</p>
    <v-row>
      <v-col align="right"><v-btn @click.once="back">戻る</v-btn></v-col>
    </v-row>
    <v-container cols="12">
      <div v-if="isLoading" align="center">
        <v-progress-circular
          :size="250"
          color="primary"
          indeterminate
          align="center"
        ></v-progress-circular>
      </div>
      <div v-else>
        <v-form ref="form">
          <v-row>
            <v-col>
              <v-text-field
                v-model="data.Recodes.FID0.name"
                :rules="[rules.required]"
                label="社員番号"
                :disabled="disabled || data.ReportID != null"
                type="text"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="false">
            <v-col>
              <v-text-field
                v-model="data.Recodes.FID0.mail"
                label="メールアドレス"
                type="text"
                :disabled="!createFlag"
                :rules="[rules.required, rules.mail]"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="createFlag">
              <v-text-field
                v-model="data.Recodes.FID0.password"
                label="初期パスワード"
                :type="passwordShow ? 'text' : 'password'"
                :rules="[rules.required, rules.password]"
                :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="passwordShow = !passwordShow"
              >
              </v-text-field>
            </v-col>
            <v-col v-else-if="userNameLogin">
              <v-text-field
                v-model="data.Recodes.FID0.password"
                label="パスワードリセット"
                :type="passwordShow ? 'text' : 'password'"
                :rules="[
                  data.Recodes.FID0.password == '' ? true : rules.password,
                ]"
                :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="passwordShow = !passwordShow"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="6" md="4" lg="4">
              <v-text-field
                label="氏名"
                v-model="data.Recodes.FID13317.value"
                background-color="white"
                id="FID13317"
                class="textfield13317"
                :rules="[rules.required]"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-radio-group
              label="管理者権限"
              v-model="data.Recodes.FID0.role"
              row
            >
              <v-radio label="一般" value="0"></v-radio>
              <v-radio label="グループ管理者" value="2"></v-radio>
              <v-radio label="管理者" value="1"></v-radio>
            </v-radio-group>
          </v-row>
          <v-row>
            <v-col cols="6" sm="6" md="4" lg="4">
              <v-text-field
                label="事業部コード"
                v-model="data.Recodes.FID12259.value"
                :rules="[rules.required]"
                background-color="white"
                id="FID12259"
                class="textfield12259"
                outlined
                hide-details="auto"
                hint="全ての事業部を閲覧する場合は「0」を指定してください"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="4" lg="4">
              <v-text-field
                label="現在組織正式名称4"
                v-model="data.Recodes.FID12260.value"
                background-color="white"
                id="FID12260"
                class="textfield12260"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="4" lg="4">
              <v-text-field
                label="現在組織正式名称"
                v-model="data.Recodes.FID13318.value"
                background-color="white"
                id="FID13318"
                class="textfield13318"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="4" lg="4">
              <v-text-field
                label="SSコード"
                v-model="data.Recodes.FID13319.value"
                :rules="[rules.required]"
                background-color="white"
                id="FID13319"
                class="textfield13319"
                outlined
                hide-details="auto"
                hint="全てのSSを閲覧する場合は「0」を指定してください"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="4" lg="4">
              <v-select
                label="役職"
                v-model="data.Recodes.FID12261.value"
                :rules="[rules.required]"
                background-color="white"
                id="FID12261"
                class="searchForm selectBox12261"
                no-data-text="データが見つかりません"
                :items="[
                  {
                    label: 'GM',
                    value: 'GM',
                  },
                  {
                    label: 'SMG',
                    value: 'SMG',
                  },
                  {
                    label: 'MG',
                    value: 'MG',
                  },
                  {
                    label: '直営事業部長',
                    value: '直営事業部長',
                  },
                  {
                    label: '担当事業部長',
                    value: '担当事業部長',
                  }
                ]"
                item-text="label"
                item-value="value"
                outlined
                clearable
                hide-details="auto"
                :disabled="isNotChangeRoleAndDelete"
                :hint="message"
                :persistent-hint="message != ''"
              >
              </v-select>
            </v-col>
            <v-col cols="6" sm="6" md="4" lg="4">
              <v-checkbox
                v-model="data.Recodes.FID13380.value"
                label="人事情報取込時：更新・削除不可"
                value="true"
                :disabled="isNotChangeRoleAndDelete && data.Recodes.FID13380.value == 'true'"
              >
              </v-checkbox>
            </v-col>
          </v-row>

          <v-row>
            <v-col align="left">
              <v-btn
                @click="deleteModal = true"
                :disabled="data.ReportID == null || disabled || isNotChangeRoleAndDelete"
                color="red white--text"
                >削除
              </v-btn>
              <v-dialog v-model="deleteModal" max-width="400">
                <v-card>
                  <v-card-title class="text-h5"> 削除しますか？ </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="mr-3"
                      color="green darken-1 white--text"
                      @click="deleteModal = false"
                      large
                    >
                      いいえ
                    </v-btn>
                    <v-btn color="red darken-1 white--text" @click="del" large>
                      はい
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col align="right">
              <v-btn @click="send" color="primary">送信</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-container>
  </div>
</template>

<script>
import store from "../store/vuex.js";
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");
import { importJobRoles } from "./const";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      isLoading: false,
      escapeValidation: false,
      showChild: true,
      subInit: false,
      disabled: false,
      data: {
        PageID: 1654,
        ReportID: null, //新規ならnull、更新ならidがある
        Recodes: {
          FID0: {
            value: "",
            formType: 21,
            name: "",
            mail: "eneosPatrol@demo.service.kakanai.com",
            role: "0",
            password: "",
            account: "",
            attribute: "",
          },
          FID12259: { value: "0", formType: 1, disabled: false },//事業部コード
          FID12260: { value: "", formType: 1, disabled: false },//現在組織正式名称4
          FID12261: { value: "", formType: 1, disabled: false },//役職
          FID12262: { value: "", formType: 1, disabled: false }, // (非)権限
          FID13317: { value: "", formType: 1, disabled: false },//氏名
          FID13318: { value: "", formType: 1, disabled: false },//現在組織正式名称
          FID13319: { value: "0", formType: 1, disabled: false },//SSコード
          FID13320: { value: "", formType: 1, disabled: false },//社員コード（usernameと同じ、検索用）
          FID13380: { value: "", formType: 14, disabled: false },//人事情報取込時：更新・削除不可
        },
        DeleteSubReports: [],
      },

      rules: {
        required: (value) => !!value || "必須項目です",
        mail: (v) => /.+@.+\..+/.test(v) || "メールアドレスの形式が不正です",
        password: (v) =>
          /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z0-9@!#$%&?*-_\d]{8,100}$/.test(
            v
          ) ||
          "アルファベット大小文字/数字 必須 8文字以上 ※使用可能な記号 @!#$%&?*-_",
      },
      authChange: true,
      createFlag: false,
      userNameLogin: false,
      copyFlag: false,
      deleteModal: false,
      backCount: -1,
      message: "",
      passwordShow: false,
    };
  },
  async created() {
    await this.getInit();
  },
  computed: {
    role() {
      return this.data.Recodes.FID0.role;
    },
    userName() {
      return this.data.Recodes.FID0.name;
    },
    isNotChangeRoleAndDelete() {
      return this.data.Recodes.FID0.name == "MG管理者" || this.data.Recodes.FID0.name == "mg管理者" ? true : false;
    },
    // 役職
    selectedJob() {
      return this.data.Recodes.FID12261.value;
    },
  },
  watch: {
    $route() {
      this.getInit();
    },
    role() {
      let roleName = "";
      switch (this.data.Recodes.FID0.role) {
        case "0":
          roleName = "一般";
          break;
        case "1":
          roleName = "管理者";
          break;
        case "2":
          roleName = "グループ管理者";
          break;
      }
      this.data.Recodes.FID12262.value = roleName;
    },
    userName() {
      this.data.Recodes.FID13320.value = this.userName;
    },
    selectedJob(newValue) {
      if (!importJobRoles[newValue]) return;
      this.data.Recodes.FID0.role = importJobRoles[newValue].role;
    },
  },
  methods: {
    async getInit() {
      try {
        //初期処理
        this.isLoading = true;
        //
        const path = this.$route.path.split("/");
        this.createFlag = path[path.length - 1] == "create" ? true : false;

        if (!this.createFlag) {
          //ユーザ情報の取得
          let data = {
            name: this.$route.params.report_id,
            PageID: this.data.PageID,
          };
          //
          [this.data.Recodes, this.data.ReportID] = await api.UserDetail(
            data,
            this.data.Recodes
          );
          //初期処理
        }
        //自身が管理者で自身のデータは変更できない内容にする
        if (
          typeof this.$store.state.params.FID0 != "undefined" &&
          !this.createFlag &&
          this.data.Recodes.FID0.role == "1" &&
          this.data.Recodes.FID0.name == this.$store.state.params.FID0.name
        ) {
          this.authChange = false;
          this.message = "管理者自身の権限は変更できません";
        }
        // MG管理者は権限変更不可
        if (this.isNotChangeRoleAndDelete) {
          this.authChange = false;
          this.message = "MG管理者は権限変更できません";
        }

        //データ取得処理

        //init処理

        //読み込み終了
        this.isLoading = false;
      } catch (e) {
        console.log(e);
        alert("読み込みに失敗しました");
        this.back();
      }
    },

    checkValidation: async function () {
      let form = this.$refs.form;
      let check = form.validate();
      if (!check) {
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        return check;
      }

      return check;
    },
    async send() {
      const result = this.escapeValidation
        ? true
        : await this.checkValidation();
      if (result) {
        this.isLoading = true;

        switch (this.data.Recodes.FID0.role) {
          case "0":
            this.data.Recodes.FID0.attribute = "general";
            break;
          case "1":
            this.data.Recodes.FID0.attribute = "admin";
            break;
          case "2":
            this.data.Recodes.FID0.attribute = "group_admin";
            break;
        }
        try {
          let response;
          if (this.createFlag) {
            response = await api.CreateEmployee(this.data);
          } else {
            response = await api.UpdateEmployee(this.data);
          }
          console.log("SendPage1654");

          if (response.status == "sucsess") {
            if (this.$route.params.report_id == "create") {
              alert("新規登録完了");
            } else {
              alert("更新完了");
            }
          } else {
            console.log(response.errors);
            alert("登録できません。");
          }
          this.isLoading = false;
          history.back();
        } catch (e) {
          console.log(e);
          this.isLoading = false;
          history.back();
        }
      }
    },
    back() {
      store.commit("clearreports");
      if (this.copyFlag) {
        history.go(this.backCount);
      } else {
        history.back();
      }
    },
    async del() {
      const response = await api.DeleteEmployee(this.data);
      if (response.status == "sucsess") {
        alert("削除完了");
      } else {
        alert("ユーザ削除エラー:" + response.errors);
      }
      history.back();
    },
  },
};
</script>
<style></style>
