<template>
  <div class="menu">
    <v-list dense>
      <v-list-item
        v-for="(item, index) in menuItems"
        :key="index"
        @click="isClickable(item) && move(item.path)"
      >
        <v-list-item-content>
          <v-list-item-title
            v-text="item.title"
            :style="{ color: isCurrentPath(item.path) ? 'gray' : 'black' }"
            style="font-weight: bold;"
          >
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- 点検項目 サブメニュー -->
      <v-list-item
        v-for="subItem in menuItems[3].items"
        :key="subItem.text"
        @click="subItem.path && move(subItem.path, subItem.value)"
      >
        <v-list-item-content>
          <v-list-item-title
            v-text="subItem.text"
            class="ml-4"
            style="font-weight: bold; font-size: 12px;"
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import store from "../store/vuex.js";

export default {
  name: "PatrolReportNavigationMenu",
  data: () => ({
    menuItems: [
      {
        title: "巡回レポート",
        path: "/patrolReport/create",
      },
      {
        title: "金銭点検チェック",
        path: "/moneyCheck/create"
      },
      {
        title: "在庫差異チェック",
        path: "/stockCheck/create",
      },
      {
        title: "点検項目",
        items: [{ path: "/patrolReport/create", text: "全て", value: "0" },
                { path: "/patrolReport/create", text: "1.第一印象・クレンリネス＆掲示物", value: "1" },
                { path: "/patrolReport/create", text: "2.設備", value: "2" },
                { path: "/patrolReport/create", text: "3.マネジメントツール・人材育成＆教育", value: "3" },
                { path: "/patrolReport/create", text: "4.コンプライアンス", value: "4" },
                { path: "/patrolReport/create", text: "5.実査", value: "5" },
                { path: "/patrolReport/create", text: "6.SS併設認証【認証併設SSのみ】", value: "6" },
                { path: "/patrolReport/create", text: "7.「SS管理チェック」実施状況の確認", value: "7" },
                { path: "/patrolReport/create", text: "指導（改善）内容とりまとめ", value: "8" },
        ],
      },
    ],
  }),
  methods: {
    isCurrentPath(path) {
      return this.$route.path.includes(path);
    },
    isClickable(item) {
      if (!item.path) {
        return false;
      }
      if (this.isCurrentPath(item.path)) {
        return false;
      }
      if (this.$route.path.includes("/patrolReport/create") && this.$route.params.report_id == null) {
        return false;
      }
      return true;
    },
    move(path, value) {
      if (path === "/patrolReport/create") {
        store.commit("clearMovePatrolReportInspectionItem");
        if (typeof value != "undefined") {
          store.commit("setMovePatrolReportInspectionItem", value);
        }
        this.$emit("patrolReport");
      } else if (path === "/moneyCheck/create") {
        this.$emit("moneyCheck");
      } else if (path === "/stockCheck/create") {
        this.$emit("stockCheck");
      }
    },
  },
};
</script>
