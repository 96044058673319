<template>
  <v-card class="subPage" color="white">
    <div class="ma-1">
      <p>SMG入力</p>
      <div v-if="isLoading" align="center">
        <v-progress-circular
          :size="250"
          color="primary"
          indeterminate
          align="center"
        ></v-progress-circular>
      </div>
      <div v-else>
        <v-form ref="form">
          <v-row>
            <!-- トライアルで動作しないため使用しない -->
            <!-- <v-col cols="6" sm="6" md="4" lg="4">
              <masterSearchUser
                label="マスタ検索フォーム"
                v-model="data.Recodes.FID13322"
                background-color="white"
                id="FID13322"
                class="masterSearch13322"
                :value="data.Recodes.FID13322.value"
                @click="data.Recodes.FID13322.modal = true"
                v-on:set="setMaster13322"
                hide-details="auto"
              >
              </masterSearchUser>
            </v-col> -->
            <v-col cols="6" sm="6" md="4" lg="4">
              <v-text-field
                label="社員コード"
                v-model="data.Recodes.FID13315.value"
                background-color="white"
                id="FID13315"
                class="textfield13315"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="4" lg="4">
              <v-text-field
                label="氏名"
                v-model="data.Recodes.FID13316.value"
                background-color="white"
                id="FID13316"
                class="textfield13316"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
  </v-card>
</template>

<script>
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");

// import masterSearchUser from "../views/masterSearchUser";

export default {
  name: "Home",
  components: {
    // masterSearchUser,
  },
  props: {
    //値
    Report: Object,
    subindex: Number,
    parentData: {
      type: Object,
      default: () => {},
      required: false,
    },
    parentSelectBoxDatas: {
      type: Object,
      default: () => {},
    },
    myInit: Boolean,
    FID: String,
  },
  data() {
    return {
      isLoading: false,
      escapeValidation: false,
      showChild: true,
      subInit: false,
      disabled: false,
      data: {
        PageID: 1842,
        ReportID: null, //新規ならnull、更新ならidがある
        Recodes: {
          FID13322: { value: "", formType: 41, disabled: false, modal: false },
          FID13315: { value: "", formType: 1, disabled: false },
          FID13316: { value: "", formType: 1, disabled: false },
        },
        DeleteSubReports: [],
      },
      masterData13321: {},
      tmp13321: [""],

      rules: {},
      copyFlag: false,
      noBack: false,
    };
  },
  async created() {
    await this.getInit();
  },
  computed: {},
  watch: {
    $route() {
      this.getInit();
    },
    data: {
      handler: async function (data) {
        //
        if (this.tmp13321[0] != data.Recodes.FID13315.value) {
          this.masterData13321 = await this.getMasterData13321();
        }
        this.tmp13321[0] = data.Recodes.FID13315.value;

        //
        this.$emit("clickSubmit", this.FID, this.subindex, data);
      },
      deep: true,
    },
    masterData13321: {
      deep: true,
      handler: async function (newData) {
        if (
          typeof newData.Recodes.FID13317 != "undefined" &&
          this.data.ReportID == null
        ) {
          this.data.Recodes.FID13316.value = newData.Recodes.FID13317.value;
        }
        if (
          typeof newData.Recodes.FID13320 != "undefined" &&
          this.data.ReportID == null
        ) {
          this.data.Recodes.FID13315.value = newData.Recodes.FID13320.value;
        }
      },
    },
  },
  methods: {
    async getInit() {
      try {
        //初期処理
        this.isLoading = true;
        //
        //データが存在する場合
        if (this.$route.query.copyFlag) {
          this.reportCopy();
        } else if (this.Report.ReportID != null || this.myInit) {
          //サブレポートの値代入
          this.data.ReportID = this.Report.ReportID;
          this.data.Recodes = api.OptionCopy(
            this.data.Recodes,
            this.Report.Recodes
          );
        } else {
          //初期値設定
        }
        //データ取得処理
        [this.masterData13321] = await Promise.all([this.getMasterData13321()]);

        //init処理

        //初回データ反映
        this.$emit("clickSubmit", this.FID, this.subindex, this.data);
        //読み込み終了
        this.isLoading = false;
      } catch (e) {
        console.log(e);
        alert("読み込みに失敗しました");
        this.back();
      }
    },
    RecodeCopy(data, response) {
      for (const [FID] of Object.entries(data.Recodes)) {
        if (typeof response[FID] != "undefined") {
          data.Recodes[FID].value = response[FID].value;
        }
      }
    },
    async send() {
      const result = this.escapeValidation
        ? true
        : await this.checkValidation();
      if (result) {
        console.log("SendPage1842");
      }
    },
    async setMaster13322(master) {
      this.masterData13321 = master;
    },
    async getMasterData13321() {
      //
      let d = {
        PageID: 1654,
        orderBy: "",
        search: [],
      };
      //
      d.search.push({
        form_id: 13320,
        value: this.data.Recodes.FID13315.value,
        option: "match",
      });

      //apiからマスタデータの取得
      if (
        this.data.Recodes.FID13315.value != "" &&
        this.data.Recodes.FID13315.value != null
      ) {
        try {
          let response = await api.GetMasterV2(d);
          if (response.data.length == 1) {
            response.data[0].PageID = 1654;
            return response.data[0];
          } else {
            console.log("マスタが複数見つかりました", response.data);
          }
        } catch (e) {
          console.log("e", e);
        }
      }
      return this.masterData13321;
    },

    checkValidation: async function () {
      let form = this.$refs.form;
      let check = form.validate();
      if (this.escapeValidation) {
        return true;
      }
      if (!check) {
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        return check;
      }
      return check;
    },
    async del() {
      await api.DelReport(this.data);
      alert("削除完了");
      this.back();
    },
  },
};
</script>
<style></style>
