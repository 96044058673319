<template>
  <div>
    <v-row justify="center">
      <v-col cols="auto">
        <v-btn
          @click="movePatrolReport"
          :disabled="disabledMovePatrolReport"
          color="orange white--text"
          style="font-size: 20px; letter-spacing: 10px"
          height="50"
          >&nbsp;&nbsp;巡回レポート&nbsp;&nbsp;
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn
          @click="moveMoneyCheck"
          :disabled="disabledMoveMoneyCheck"
          color="primary white--text"
          style="font-size: 20px; letter-spacing: 10px"
          height="50"
          >金銭点検チェック
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn
          @click="moveStockCheck"
          :disabled="disabledMoveStockCheck"
          color="green white--text"
          style="font-size: 20px; letter-spacing: 10px"
          height="50"
          >在庫差異チェック
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import store from "../store/vuex.js";
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");
import { getMasterData } from "./specialMethod";

const patrolReportPageID = 1651;
const moneyCheckPageID = 1655;
const stockCheckPageID = 1656;
const patrolReportSerialNumberFID = 11841;
const moneyCheckSerialNumberFID = 11842;
const stockCheckSerialNumberFID = 11843;

export default {
  name: "patrolreportCreateSpecial",
  components: {},
  props: {
    data: { default: () => {}, type: Object },
    pageID: { default: null, type: Number },
  },
  data() {
    return {
      isLoading: false,
      escapeValidation: false,
      showChild: true,
      subInit: false,
      disabled: false,
      rules: {
        required: (value) => !!value || "必須項目です",
      },
      deleteModal: false,
      copyFlag: false,
      sendCopyFlag: false,
      noBack: false,
      backCount: -1,
    };
  },
  created() {
    //
  },
  computed: {
    // SSコード
    ssCode() {
      if (this.pageID === patrolReportPageID) {
        return this.data.Recodes.FID11763.value;
      } else if (this.pageID === moneyCheckPageID) {
        return this.data.Recodes.FID11341.value;
      } else if (this.pageID === stockCheckPageID) {
        return this.data.Recodes.FID11707.value;
      }
      return "";
    },
    // SS名
    ssName() {
      if (this.pageID === patrolReportPageID) {
        return this.data.Recodes.FID11767.value;
      } else if (this.pageID === moneyCheckPageID) {
        return this.data.Recodes.FID11342.value;
      } else if (this.pageID === stockCheckPageID) {
        return this.data.Recodes.FID11709.value;
      }
      return "";
    },
    // 責任者
    ssMG() {
      if (this.pageID === patrolReportPageID) {
        return this.data.Recodes.FID11768.value;
      } else if (this.pageID === moneyCheckPageID) {
        return this.data.Recodes.FID11343.value;
      } else if (this.pageID === stockCheckPageID) {
        return this.data.Recodes.FID11870.value;
      }
      return "";
    },
    // 対応者
    personInCharge() {
      if (this.pageID === patrolReportPageID) {
        return this.data.Recodes.FID11770.value;
      } else if (this.pageID === moneyCheckPageID) {
        return this.data.Recodes.FID13154.value;
      } else if (this.pageID === stockCheckPageID) {
        return this.data.Recodes.FID11711.value;
      }
      return "";
    },
    // 直営事業部名
    divisionName() {
      if (this.pageID === patrolReportPageID) {
        return this.data.Recodes.FID11844.value;
      } else if (this.pageID === moneyCheckPageID) {
        return this.data.Recodes.FID11869.value;
      } else if (this.pageID === stockCheckPageID) {
        return this.data.Recodes.FID11708.value;
      }
      return "";
    },
    // 事業部コード
    divisionCode() {
      if (this.pageID === patrolReportPageID) {
        return this.data.Recodes.FID13377.value;
      } else if (this.pageID === moneyCheckPageID) {
        return this.data.Recodes.FID13378.value;
      } else if (this.pageID === stockCheckPageID) {
        return this.data.Recodes.FID13379.value;
      }
      return "";
    },
    // 巡回レポート通し番号
    reportSerialNumber() {
      if (this.pageID === patrolReportPageID) {
        return this.data.Recodes["FID" + patrolReportSerialNumberFID].value;
      } else if (this.pageID === moneyCheckPageID) {
        return this.data.Recodes["FID" + moneyCheckSerialNumberFID].value;
      } else if (this.pageID === stockCheckPageID) {
        return this.data.Recodes["FID" + stockCheckSerialNumberFID].value;
      }
      return "";
    },
    // 年月
    yaerMonth() {
      if (this.pageID === patrolReportPageID) {
        return this.data.Recodes.FID12222.value;
      } else if (this.pageID === moneyCheckPageID) {
        return this.data.Recodes.FID12223.value;
      } else if (this.pageID === stockCheckPageID) {
        return this.data.Recodes.FID12220.value;
      }
      return "";
    },
    // 日付
    yaerMonthDay() {
      if (this.pageID === patrolReportPageID) {
        return this.data.Recodes.FID11758.value;
      } else if (this.pageID === moneyCheckPageID) {
        return this.data.Recodes.FID11340.value;
      } else if (this.pageID === stockCheckPageID) {
        return this.data.Recodes.FID11706.value;
      }
      return "";
    },
    disabledMovePatrolReport() {
      if (this.pageID === patrolReportPageID) {
        return true;
      }
      return false;
    },
    disabledMoveMoneyCheck() {
      if (this.pageID === patrolReportPageID && !this.data.ReportID) {
        return true;
      }
      if (this.pageID === moneyCheckPageID) {
        return true;
      }
      return false;
    },
    disabledMoveStockCheck() {
      if (this.pageID === patrolReportPageID && !this.data.ReportID) {
        return true;
      }
      if (this.pageID === stockCheckPageID) {
        return true;
      }
      return false;
    },
  },
  watch: {
    //
  },
  methods: {
    async getReport(pageId, formId) {
      const data = {
        PageID: pageId,
        orderBy: "",
        search: [],
      };
      let report = { ReportID: null };
      if (!this.reportSerialNumber) {
        return report;
      }
      data.search.push({
        form_id: formId,
        value: this.reportSerialNumber, //巡回レポート通し番号
        option: "match",
      });
      const response = await api.GetMasterV2(data);
      if (response.data.length == 1) {
        response.data[0].PageID = pageId;
        report = response.data[0];
      }
      return report;
    },
    async getMoveReportID(pageId, formId) {
      const report = await this.getReport(pageId, formId);
      return report.ReportID;
    },
    async movePatrolReport() {
      if (this.$store.state.referrerIndexPagePath.includes("patrolReport")) {
        this.$store.commit("clearReferrerPagePath");
        this.$router.go(-1);
      } else {
        const moveReportID = await this.getMoveReportID(
          patrolReportPageID,
          patrolReportSerialNumberFID
        );
        let url = "/patrolReport/create";
        if (moveReportID) {
          url += "/" + moveReportID;
        }
        if (!this.$store.state.referrerPagePath) {
          this.$store.commit(
            "setReferrerPagePath",
            this.$router.history.current.path
          );
          this.$router.push({ path: url });
        } else {
          this.$router.replace({ path: url });
        }
      }
    },
    // 金銭点検チェックへ
    async moveMoneyCheck() {
      if (this.$store.state.referrerIndexPagePath.includes("moneyCheck")) {
        this.$store.commit("clearReferrerPagePath");
        this.$router.go(-1);
      } else {
        const moveReportID = await this.getMoveReportID(
          moneyCheckPageID,
          moneyCheckSerialNumberFID
        );
        let url = "/moneyCheck/create";
        if (moveReportID) {
          url += "/" + moveReportID;
        } else {
          localStorage.setItem("kakanaiValueFID11763", this.ssCode); // SSコード
          localStorage.setItem("kakanaiValueFID11767", this.ssName); // SS名
          localStorage.setItem("kakanaiValueFID11768", this.ssMG); // 責任者
          localStorage.setItem("kakanaiValueFID11770", this.personInCharge); // 対応者
          localStorage.setItem("kakanaiValueFID11844", this.divisionName); // 直営事業部名
          localStorage.setItem("kakanaiValueFID13155", this.divisionCode); // 事業部コード
          localStorage.setItem("kakanaiValueFID11841", this.reportSerialNumber); // 巡回レポート通し番号
          localStorage.setItem("kakanaiValueFID12222", this.yaerMonth); // 年月
          localStorage.setItem("kakanaiValueFID11758", this.yaerMonthDay); // 日付
        }
        if (!this.$store.state.referrerPagePath) {
          this.$store.commit(
            "setReferrerPagePath",
            this.$router.history.current.path
          );
          this.$router.push({ path: url });
        } else {
          this.$router.push({ path: url });
        }
      }
    },
    // 在庫差異チェックへ
    async moveStockCheck() {
      if (this.$store.state.referrerIndexPagePath.includes("stockCheck")) {
        this.$store.commit("clearReferrerPagePath");
        this.$router.go(-1);
      } else {
        const moveReportID = await this.getMoveReportID(
          stockCheckPageID,
          stockCheckSerialNumberFID
        );
        let url = "/stockCheck/create";
        if (moveReportID) {
          url += "/" + moveReportID;
        } else {
          localStorage.setItem("kakanaiValueFID11763", this.ssCode); // SSコード
          localStorage.setItem("kakanaiValueFID11767", this.ssName); // SS名
          localStorage.setItem("kakanaiValueFID11768", this.ssMG); // 責任者
          localStorage.setItem("kakanaiValueFID11770", this.personInCharge); // 対応者
          localStorage.setItem("kakanaiValueFID11844", this.divisionName); // 直営事業部名
          localStorage.setItem("kakanaiValueFID13155", this.divisionCode); // 事業部コード
          localStorage.setItem("kakanaiValueFID11841", this.reportSerialNumber); // 巡回レポート通し番号
          localStorage.setItem("kakanaiValueFID12222", this.yaerMonth); // 年月
          localStorage.setItem("kakanaiValueFID11758", this.yaerMonthDay); // 日付
          const reports = await getMasterData.stockReports(
            this.ssCode,
            this.yaerMonth
          );
          // データがない場合は専用画面に遷移
          if (reports.length == 0) {
            console.log("在庫差異チェックのデータがありません");
            url = "/stockCheck/before/create";
            this.$store.commit("setReportSerialNumber", this.reportSerialNumber); // ナビメニュー遷移用に巡回レポート通し番号を保持
          }
        }
        if (!this.$store.state.referrerPagePath) {
          this.$store.commit(
            "setReferrerPagePath",
            this.$router.history.current.path
          );
          this.$router.push({ path: url });
        } else {
          this.$router.push({ path: url });
        }
      }
    },
  },
};
</script>
<style></style>
