<template>
  <div class="home">
    <p>エクセルデータ取り込み：在庫データ</p>
    <v-col align="right"><v-btn @click.once="back">戻る</v-btn></v-col>
    <div v-if="isLoading" align="center">
      <v-progress-circular
        :size="250"
        color="primary"
        indeterminate
        align="center"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-container cols="12">
        <v-form ref="form">
          <v-row>
            <v-checkbox
              v-model="header_flag"
              label="ヘッダー有"
            ></v-checkbox> </v-row
          ><v-row
            ><v-file-input
              v-model="uploadfile.file"
              type="file"
              label="エクセル取り込み(xlsx)"
              prepend-icon="mdi-image"
              ref="rfafile"
              accept=".xlsx,.xlsm"
              @change="chargeExcel"
              :rules="[rules.fileSize]"
              show-size
            ></v-file-input
          ></v-row>
          <v-row class="ma-0 mt-3">
              <v-col cols="12" class="ma-0 pa-0">★取込ファイルのヘッダー情報</v-col>
              <v-col cols="12" class="ma-0 pa-0">
                【A列】対象年月,【B列】会社コード,【C列】SSコード,【D列】商品コード,【E列】荷姿コード,【F列】商品名,【G列】分類コード,【H列】前月繰越,【I列】現時点在庫,【J列】日平均,【K列】残日数,【L列】入荷累計,【M列】出荷累計
              </v-col>
            </v-row>
          <v-row>
            <v-col align="right">
              <v-btn @click="send" color="primary" :disabled="!sendFlag"
                >送信</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="">
              <v-data-table
                :headers="headers"
                :items="reports"
                :item-key="reports.ID"
                :header-props="{
                  'sort-by-text': '並び順',
                }"
                :footer-props="{
                  'items-per-page-text': '行/ページ:',
                }"
                no-data-text="データが存在しません"
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </div>

    <!-- <loading :active.sync="isLoading" :is-full-page="fullPage"></loading> -->
  </div>
</template>

<script>
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");
import  * as XLSX from "xlsx";
import { getMasterData } from "./specialMethod";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      isLoading: false,
      fullPage: false,
      headerText: ",", //`$$headerText`,
      valid: true,
      data: {
        PageID: 1665, //
        Page_id: 1665, //送信に使っているが本来は変換する
        Report_id: null, //
        UpdateType: "update",
        KeyID: [11488, 11486],
        KeyMap: {
          FID11488: 0,
          FID11486: 2,
        },
        Recodes: {
          FID11488: { value: "", formType: 3 },
          FID11486: { value: "", formType: 1 },
          FID11489: { value: "", formType: 1 },
          FID11502: { value: "", formType: 1 },
          FID11493: { value: "1666", formType: 17, subReports: [] },
          FID11501: { value: "", formType: 20 },
        },
      },
      subReport1666: {
        PageID: 1666, //
        Page_id: 1666, //送信に使っているが本来は変換する
        Report_id: null, //
        UpdateType: "update",
        KeyID: [],
        KeyMap: {},
        Recodes: {
          FID11494: { value: "", formType: 1 },
          FID11495: { value: "", formType: 1 },
          FID11496: { value: "0", formType: 1 },
          FID11497: { value: "0", formType: 1 },
          FID11498: { value: "0", formType: 1 },
          FID11499: { value: "0", formType: 1 },
          FID11819: { value: "", formType: 2 },
        },
      },

      //
      sendFlag: false,
      uploadfile: { file: {}, url: "", data: { name: "", size: 0, type: "" } },
      header_flag: true,
      reports: [],
      rules: {
        fileSize: (value) => {
          return (
            value == null ||
            value.size < 15000000 ||
            "ファイルサイズを15MB未満にしてください"
          );
        },
      },
    };
  },
  async created() {
    await this.getInit();
  },
  computed: {
    // TODO: サブレポートをテーブルに表示する
    headers() {
      return [
        { text: "対象年月", value: "Recodes.FID11488.value", sortable: true },
        { text: "SSコード", value: "Recodes.FID11486.value", sortable: true },
        {
          text: "商品コード",
          value: "Recodes.FID11493.subReports[0].Recodes.FID11494.value",
          sortable: true,
        },
        {
          text: "商品名",
          value: "Recodes.FID11493.subReports[0].Recodes.FID11495.value",
          sortable: true,
        },
        {
          text: "前月繰越",
          value: "Recodes.FID11493.subReports[0].Recodes.FID11496.value",
          sortable: true,
        },
        {
          text: "現在時点在庫",
          value: "Recodes.FID11493.subReports[0].Recodes.FID11499.value",
          sortable: true,
        },
        {
          text: "入荷累計",
          value: "Recodes.FID11493.subReports[0].Recodes.FID11497.value",
          sortable: true,
        },
        {
          text: "出荷累計",
          value: "Recodes.FID11493.subReports[0].Recodes.FID11498.value",
          sortable: true,
        },
      ];
    },
  },
  methods: {
    async getInit() {
      //初期処理
      this.isLoading = true;
      //読み込み終了
      this.isLoading = false;
    },
    /*-----------------------------*/
    //文字コードのチェック
    charCodeConvert(file) {
      const Encoding = require("encoding-japanese");
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          //文字列をバイト列に変換
          var sjisArray = new Uint8Array(reader.result);
          const encoding = this.detectEncoding(reader.result);
          switch (encoding) {
            case "SJIS":
              var unicodeArray = Encoding.convert(sjisArray, {
                to: "UNICODE",
                from: "SJIS",
                type: "string",
              });
              resolve(unicodeArray);
              break;
            case "UTF8":
              var unicodeArray2 = Encoding.convert(sjisArray, {
                to: "UNICODE",
                from: "AUTO",
                type: "string",
              });
              resolve(unicodeArray2);
              break;
            default:
              alert("文字コードが対応していません:" + encoding);
          }
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      });
    },
    detectEncoding(buffer) {
      const uint8Array = new Uint8Array(buffer);
      if (
        uint8Array[0] === 0xef &&
        uint8Array[1] === 0xbb &&
        uint8Array[2] === 0xbf
      ) {
        return "UTF8";
      } else if (uint8Array[0] === 0xfe && uint8Array[1] === 0xff) {
        return "UTF16BE";
      } else if (uint8Array[0] === 0xff && uint8Array[1] === 0xfe) {
        return "UTF16LE";
      } else if (
        uint8Array[0] === 0 &&
        uint8Array[1] === 0 &&
        uint8Array[2] === 0xfe &&
        uint8Array[3] === 0xff
      ) {
        return "UTF32BE";
      } else if (
        uint8Array[0] === 0xff &&
        uint8Array[1] === 0xfe &&
        uint8Array[2] === 0 &&
        uint8Array[3] === 0
      ) {
        return "UTF32LE";
      } else if (
        uint8Array[0] === 0 &&
        uint8Array[1] === 0 &&
        uint8Array[2] === 0 &&
        uint8Array[3] !== 0
      ) {
        return "UTF32BE-noBOM";
      } else if (
        uint8Array[0] !== 0 &&
        uint8Array[1] === 0 &&
        uint8Array[2] === 0 &&
        uint8Array[3] === 0
      ) {
        return "UTF32LE-noBOM";
      } else {
        return "SJIS";
      }
    },
    /*ダブルクォーテーション内のカンマは分割しない*/
    csvSplit(line) {
      let c = "";
      let s = new String();
      let data = new Array();
      let singleQuoteFlg = false;

      for (let i = 0; i < line.length; i++) {
        c = line.charAt(i);
        if (c == "," && !singleQuoteFlg) {
          data.push(s.toString());
          s = "";
        } else if (c == "," && singleQuoteFlg) {
          s = s + c;
        } else if (c == '"') {
          singleQuoteFlg = !singleQuoteFlg;
        } else {
          s = s + c;
        }
      }
      //最後のデータを追加
      data.push(s.toString());
      return data;
    },
    //現在のレポートとCSVの主キーが同じか確認する
    sameKeyCheck(data, csv) {
      let flag = true;
      Object.keys(data.KeyMap).map((key) => {
        const x = key;
        const y = data.KeyMap[key];
        if (data.Recodes[x].value == "" || csv[y] == "") {
          flag = false;
        }
        if (data.Recodes[x].value != csv[y]) {
          flag = false;
        }
      });
      return flag;
    },
    setValue(report, csv, to, from, tag) {
      if (typeof tag == "undefined") {
        if (typeof csv[from] != "undefined" && csv[from] != "") {
          report.Recodes[to].value = String(csv[from]).trim();
        }
        return;
      } else {
        switch (tag) {
          case "add":
            report.Recodes[to].value += String(csv[from]).trim();
            break;
        }
      }
    },
    async chargeExcel(event) {
      this.isLoading = true;
      try {
        const file = event;
        this.isLoading = false;
        if (file) {
          const reader = new FileReader();
          reader.onload = async (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]]; //シート番号
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            //先頭削除
            jsonData.shift();
            //データ処理
            const result = this.convertReports(jsonData);
            if (!result) {
              this.isLoading = false;
              return
            }
            this.reports = result;
            this.sendFlag = true;
            //バリデーションチェック
            this.isLoading = false;
            await this.$nextTick();
            await this.checkValidation();
          };
          reader.readAsArrayBuffer(file);
        }
        this.isLoading = false;
      } catch (e) {
        console.log("chargeExcel", e);
      }
    },
    convertReports(csv) {
      let data = [];
      let mainReport = JSON.parse(JSON.stringify(this.data));
      let result = true;
      //
      csv.forEach((c, index) => {
        if (!result) {
          return;
        }
        if (c.length > 1) {
          //主キーチェック
          // TODO: 連続したデータでなくてもサブレポートを纏められるようにする
          if (!this.sameKeyCheck(mainReport, c)) {
            if (index > 0) {
              data.push(mainReport);
            }
            mainReport = JSON.parse(JSON.stringify(this.data));
            this.setValue(mainReport, c, "FID11488", 0); //対象年月
            this.setValue(mainReport, c, "FID11486", 2); //SSコード
          }
          //サブレポート宣言処理
          let subReport1666 = JSON.parse(JSON.stringify(this.subReport1666));

          //CSV読み込み時のデータ対応
          //サブレポートの追加処理
          if (c[3] != "") {
            this.setValue(subReport1666, c, "FID11494", 3); //商品コード
          }
          if (c[5] != "") {
            this.setValue(subReport1666, c, "FID11495", 5); //商品名
          }
          if (c[7] != "") {
            this.setValue(subReport1666, c, "FID11496", 7); //前月繰越
          }
          if (c[8] != "") {
            this.setValue(subReport1666, c, "FID11499", 8); //現在時点在庫
          }
          if (c[11] != "") {
            this.setValue(subReport1666, c, "FID11497", 11); //入荷累計
          }
          if (c[12] != "") {
            this.setValue(subReport1666, c, "FID11498", 12); //出荷累計
          }
          mainReport.Recodes.FID11493.subReports.push(subReport1666);
        }
      });
      data.push(mainReport);
      //
      return data;
    },
    // 既存の在庫マスタデータを取得
    async getReports() {
      const data = {
        PageID: this.data.PageID,
        orderBy: "",
        search: [],
      };
      const reports = await api.ReportSearch(data);
      if (reports != null) {
        // PageIDを設定, FID0を削除
        reports.forEach((report) => {
          report.PageID = this.data.PageID;
          if (report.Recodes.FID0) {
            delete report.Recodes.FID0;
          }
        });
        return reports;
      }
      return [];
    },
    // 既存のSSマスタデータを取得
    async getSSReports() {
      const PageID = 1661;
      const data = {
        PageID: PageID,
        orderBy: "",
        search: [],
      };
      const reports = await api.ReportSearch(data);
      if (reports != null) {
        // PageIDを設定, FID0を削除
        reports.forEach((report) => {
          report.PageID = PageID;
          if (report.Recodes.FID0) {
            delete report.Recodes.FID0;
          }
        });
        return reports;
      }
      return [];
    },
    // 既存の商品マスタデータとCSVデータを比較して、更新、新規、削除のデータを取得
    getSendReports(reports, csvReports, primaryKeys, getSSReports, getProductReports) {
      const result = { updateReports: [], createReports: [], deleteReports: [], result: true};
      csvReports.forEach((csvReport) => {
        if (!result.result) {
          return;
        }
        // 年月の形式チェックと変換
        const yyyymm = csvReport.Recodes["FID11488"].value;
        if (yyyymm.length != 6 || isNaN(yyyymm)) {
          result.result = false;
          alert(`対象年月の形式が正しくありません：${yyyymm}\nYYYYMMで入力してください`);
          return;
        }
        const yyyy = yyyymm.slice(0, 4);
        const mm = yyyymm.slice(-2);
        if (Number(yyyy) < 2024 || Number(yyyy) > 2200) {
          result.result = false;
          alert(`対象年月の形式が正しくありません：${yyyymm}\nYYYYは2024～2200で入力してください`);
          return;
        }
        if (Number(mm) < 1 || Number(mm) > 12) {
          result.result = false;
          alert(`対象年月の形式が正しくありません：${yyyymm}\nMMは01～12で入力してください`);
          return;
        }
        csvReport.Recodes["FID11488"].value = yyyy + "-" + mm;
        // SSコード検索とSS名の設定：なければエラー
        const ssReport = getSSReports.find((report) => {
          return report.Recodes.FID11265.value == csvReport.Recodes.FID11486.value;
        });
        if (!ssReport) {
          alert("SSコードが存在しません：" + csvReport.Recodes.FID11486.value);
          result.result = false;
          return;
        }
        csvReport.Recodes.FID11489.value = ssReport.Recodes.FID11265.value; // SS名
        // OTBがタイヤ,バッテリーのみ取込対象とする
        csvReport.Recodes.FID11493.subReports = this.filterProductOtb(
          csvReport.Recodes.FID11493.subReports,
          getProductReports
        );
        // 商品コード検索とOTBの設定：なければエラー
        csvReport.Recodes.FID11493.subReports.forEach((subReport) => {
          if (!result.result) {
            return;
          }
          const productReport = getProductReports.find((report) => {
            return report.Recodes.FID11896.value == subReport.Recodes.FID11494.value; // 商品コード
          });
          if (!productReport) {
            alert("商品コードが存在しません：" + subReport.Recodes.FID11494.value);
            result.result = false;
            return
          }
          subReport.Recodes.FID11819.value = productReport.Recodes.FID11898.value; // OTB設定
        });
        // TODO: 全体的に数字かどうかのチェックが必要
        // TODO: 登録日時の登録

        // 年月とSSコードが同じデータが既にあるかチェック
        const report = reports.find((report) => {
          return this.isSameKey(report, csvReport, primaryKeys);
        });
        if (!report) {
          result.createReports.push(csvReport);
        }
      });
      if (!result.result) {
        return result;
      }
      // 既存のデータとCSVデータを比較して、更新、削除のデータを取得
      // 更新 = 削除して新規登録
      reports.forEach((report) => {
        const csvReport = csvReports.find((csvReport) => {
          return this.isSameKey(report, csvReport, primaryKeys);
        });
        if (csvReport) {
          // TODO: 既に同じレポートがある場合の処理（複数csvReportが登録される恐れあり）
          result.updateReports.push(csvReport);
          result.deleteReports.push(report);
        }
      });
      return result;
    },
    isSameKey(report, csvReport, primaryKeys) {
      return primaryKeys.every((key) => {
        return report.Recodes[key].value == csvReport.Recodes[key].value;
      });
    },
    getUpdateReport(report, csvReport) {
      const updateReport = JSON.parse(JSON.stringify(report));
      Object.keys(csvReport.Recodes).forEach((key) => {
        updateReport.Recodes[key].value = csvReport.Recodes[key].value;
      });
      return updateReport;
    },
    filterProductOtb(subReports, getProductReports) {
      return subReports.filter((csvReport) => {
        return getProductReports.find((report) =>
            report.Recodes.FID11896.value == csvReport.Recodes.FID11494.value
              && (report.Recodes.FID11898.value == "タイヤ" || report.Recodes.FID11898.value == "バッテリー")
        );
      });
    },
    async send() {
      const isCorrectValue = this.checkValidation();
      if (isCorrectValue == false) return;
      this.isLoading = true;
      const [getReports, getSSReports, getProductReports] = await Promise.all([
        this.getReports(),
        this.getSSReports(),
        getMasterData.productReports(),
      ]);
      this.isLoading = false;
      const primaryKeys = this.data.KeyID.map((key) => "FID" + key);
      const getSendReports = this.getSendReports(getReports, this.reports, primaryKeys, getSSReports, getProductReports);
      if (!getSendReports.result) {
        this.isLoading = false;
        return;
      }
      const createCount = getSendReports.createReports.length;
      const updateCount = getSendReports.updateReports.length;
      // TODO: サブレポートの数もカウントして表示する
      await this.$nextTick();
      if (confirm(`データを登録しますか？\n新規登録：${createCount}件のSSデータ\n更新：${updateCount}件のSSデータ`) == false) {
        return;
      }
      this.isLoading = true;
      for (const createReport of getSendReports.createReports) {
        const result = await api.SendReport(createReport);
        if (result == false || result.result == "Fail") {
          alert("登録失敗[Error0830-1]");
          this.isLoading = false;
          return;
        }
      }
      for (const updateReport of getSendReports.updateReports) {
        const result = await api.SendReport(updateReport);
        if (result == false || result.result == "Fail") {
          alert("登録失敗[Error0830-1]");
          this.isLoading = false;
          return;
        }
      }
      for (const deleteReport of getSendReports.deleteReports) {
        const result = await api.DelReport(deleteReport);
        if (result == false || result.result == "Fail") {
          alert("登録失敗[Error0830-1]");
          this.isLoading = false;
          return;
        }
      }
      alert("登録完了");

      this.isLoading = false;
      history.back();
    },
    checkValidation: function () {
      let form = this.$refs.form;
      let check = form.validate();

      return check;
    },
    back() {
      history.back();
    },
  },
};
</script>
