import { RepositoryFactory } from "../api/RepositoryFactory";
import { patrolReport } from "./const";
const api = RepositoryFactory.get("seisei_back");

export const special = {
  //data型の比較計算
  dateTosecond(value1,value2){
    let t1 = String(value1);
    let t2 = String(value2);
    let tt1 = new Date(t1.replace(/-/g, "/"));
    let tt2 = new Date(t2.replace(/-/g, "/"));
    return tt1 - tt2;
  },
  convertDay(num) {
    num /= 1000; //秒単位に変換
    var timeD = Math.floor(num / (24 * 60 * 60));
    var timeH = Math.floor((num % (24 * 60 * 60)) / (60 * 60));
    var timeM = Math.floor(((num % (24 * 60 * 60)) % (60 * 60)) / 60);
    //var timeS = ((num % (24 * 60 * 60)) % (60 * 60)) % 60;
    var date = "";
    if (timeD > 0) {
      date += timeD + "";
    }
    if (timeH > 0) {
      date += timeH + "時間";
    }
    if (timeM >= 0) {
      date += timeM + "分";
    }
    /*
    if (timeS > 0) {
      //date += timeS + ":";//"秒";
      date += "00"
    }else{
      date += "00"
    }
    */
    return date;
  },
  convertDayCSV(num){
    num /= 1000; //秒単位に変換
    var timeD = Math.floor(num / (24 * 60 * 60));
    var timeH = Math.floor((num % (24 * 60 * 60)) / (60 * 60));
    var timeM = Math.floor(((num % (24 * 60 * 60)) % (60 * 60)) / 60);
    //var timeS = ((num % (24 * 60 * 60)) % (60 * 60)) % 60;
    var date = "";
    if (timeD > 0) {
      //date += timeD + "";
      timeH = timeD * 24;  //1日をHに換算
    }
    if (timeH > 0) {
      date += timeH + ":";//"時間";
    }else{
      date += "00:"
    }
    if (timeM > 0) {
      date += timeM + ":";//"分";
    }else{
      date += "00"
    }
    //秒は捨てる
    return date;
  },
}
//巡回レポート.ステータスの設定
//流れ：""➡一時保存➡指導中(巡回終了～改善予定日3日前)➡指導中(改善予定日3日前～改善予定日当日)➡GM確認待ちor期限切れ➡完了
//GM確認待ち➡再度指導中(2個目の改善予定日)に戻るorGM確認済完了待ち
export function statusCheck(_, Recodes) {
  let status = ""
  // const today = new Date();
  // const stop = Recodes.FID11864.value; // 情報入力完了のフォームID
  const end = Recodes.FID11772.value; // 登録のフォームID
  // const improvementComplete1 = Recodes.FID11903.value; // 改善完了1のフォームID
  // const improvementComplete2 = Recodes.FID11907.value; // 改善完了2のフォームID
  // const glConfirmation1 = Recodes.FID11904.value; // GM確認1のフォームID
  // const glConfirmation2 = Recodes.FID11908.value; // GM確認2のフォームID
  // const improvementPlanDate1 = new Date(Recodes.FID11887.value); // 改善予定日1のフォームID
  // const improvementPlanDate2 = new Date(Recodes.FID11906.value); // 改善予定日2のフォームID
  // const daysRemaining1 = Math.ceil((improvementPlanDate1 - today) / (1000 * 60 * 60 * 24)); //改善予定日1と本日の差異
  // const daysRemaining2 = Math.ceil((improvementPlanDate2 - today) / (1000 * 60 * 60 * 24)); //改善予定日2と本日の差異
  // if (
  //   end !== "" ||
  //   (improvementComplete1 !== "" && glConfirmation1 !== "") ||
  //   (improvementComplete2 !== "" && glConfirmation2 !== "")
  // ) {
  //   status = "完了"
  // //GM確認待ち
  // } else if (
  //   (improvementComplete1 !== "" && glConfirmation1 === "") ||
  //   (improvementComplete2 !== "" && glConfirmation2 === "")
  // ) {
  //   status = "GM確認待"
  // //期限切れ
  // } else if (
  //   (improvementPlanDate1 !== "" && improvementPlanDate1.getTime() < today.getTime()) ||
  //   (improvementPlanDate2 !== "" && improvementPlanDate2.getTime() < today.getTime())
  // ) {
  //   status = "期限切れ"
  // //指導中（黄色）
  // } else if (
  //   (improvementPlanDate1 !== "" && daysRemaining1 <= 3 && daysRemaining1 >= 0) ||
  //   (improvementPlanDate2 !== "" && daysRemaining2 <= 3 && daysRemaining2 >= 0)
  // ) {
  //   status = "指導中(改善予定日3日前～改善予定日当日)"
  // //指導中（緑）
  // } else if (
  //   (improvementPlanDate1 !== "" && improvementPlanDate1.getTime() > today.getTime()) ||
  //   (improvementPlanDate2 !== "" && improvementPlanDate2.getTime() > today.getTime())
  // ) {
  //   status = "指導中（巡回終了～改善予定日3日前）"
  // //一時保存
  // } else if (stop !== "") {
  //   status = "一時保存"
  // }
  if (end !== "") {
    status = "完了"
  } else {
    status = "一時保存"
  }
  return status
}

// //巡回レポート一覧ステータス検索の追加
// export function searchCheck() {
//   return api.FormDetail(11873); //状態フォームのフォームID
// }

//金銭点検ステータス設定
export function moneyStatusCheck(_, Recodes) {
  let status = "";
  const end = Recodes.FID11678.value; //登録のフォームID
  if (end !== "") {
    status = "完了";
  } else {
    status = "一時保存";
  }
  return status;
}

//金銭点検一覧ステータス検索の追加
export function moneySearchCheck() {
  return api.FormDetail(11931); //状態フォームのフォームID
}

//在庫差異ステータス設定
export function stockStatusCheck(_, Recodes) {
  let status = "";
  const end = Recodes.FID11756.value; //登録のフォームID
  if (end !== "") {
    status = "完了";
  } else {
    status = "一時保存";
  }
  return status;
}

//在庫差異一覧ステータス検索の追加
export function stockSearchCheck() {
  return api.FormDetail(11932); //状態フォームのフォームID
}

//巡回レポート点検時刻表示（時間のみ）
export function watchFromID11761(_, Recodes) {
  let value = Recodes.FID11759.value
  if (!value) {
    return '';
  }
  const timeOnly = value.split(/:|\s/);
  const hour = timeOnly[1];
  const minute = timeOnly[2];
  return `${hour}:${minute}`;
}

export function watchFromID11762(_, Recodes) {
  let value = Recodes.FID11760.value
  if (!value) {
    return '';
  }
  const timeOnly = value.split(/:|\s/);
  const hour = timeOnly[1];
  const minute = timeOnly[2];
  return `${hour}:${minute}`;
}

// 巡回レポートに金銭点検チェック、在庫差異チェックを紐付け設定
export async function updatePatrolreport(data) {
  let reports = {}
  let searchData = {
    PageID: 1651,
    orderBy: "",
    search: [],
  };
  if (data.Recodes.FID11678) { // 金銭点検チェック
    searchData.search.push({ form_id: 11841, value: data.Recodes.FID11842.value })
    reports = await api.ReportSearch(searchData);
    if (data.Recodes.FID11678.value) {
      reports[0].Recodes.FID11954.value = "〇" // 登録
    } else {
      reports[0].Recodes.FID11954.value = "〇" // 一時保存
    }
  } else { // 在庫差異チェック
    searchData.search.push({ form_id: 11841, value: data.Recodes.FID11843.value })
    reports = await api.ReportSearch(searchData);
    if (data.Recodes.FID11756.value) {
      reports[0].Recodes.FID11955.value = "〇" // 登録
    } else {
      reports[0].Recodes.FID11955.value = "〇" // 一時保存
    }
  }
  const sendData = {
    PageID: 1651,
    ReportID: reports[0].ReportID,
    DeleteSubReports: [],
    Recodes: reports[0].Recodes
  }
  await api.SendReport(sendData);
}

// 【巡回レポート】「今回」の入力可能・不可の判定を返す true: 入力不可 false: 入力可能
export function disabledNow(data) {
  if (!data || !data.Recodes) {
    return true;
  }
  // 「改善予定日確定」ボタンに値がある場合は押下不可
  if (data.Recodes.FID12023 && data.Recodes.FID12023.value) {
    return true;
  }
  // 「改善完了報告」ボタンに値がある場合は押下不可
  if (data.Recodes.FID11988 && data.Recodes.FID11988.value) {
    return true;
  }
  // 「GM確認ボタン」に値がある場合は押下不可
  if (data.Recodes.FID11985 && data.Recodes.FID11985.value) {
    return true;
  }
  // 指導内容が複数ある場合は押下不可
  if (data.Recodes.FID11984 && data.Recodes.FID11984.subReports && data.Recodes.FID11984.subReports.length > 1) {
    return true;
  }
  return false;
}

// 【巡回レポート】「改善予定日確定」ボタン押下可能・不可の判定を返す true: 押下不可 false: 押下可能
export function disabledFID12023(data) {
  if (!data || !data.Recodes) {
    return true;
  }
  // 「改善予定日確定」ボタンに値がある場合は押下不可
  if (data.Recodes.FID12023 && data.Recodes.FID12023.value) {
    return true;
  }
  // 「GM確認」ボタンに値がある場合は押下不可
  if (data.Recodes.FID11985 && data.Recodes.FID11985.value) {
    return true;
  }
  // 全ての「今回」の中で「×」がない場合は押下不可
  if (!isNotAllCheckNowOK(data)) {
    return true;
  }
  return false;
}
// 【巡回レポート】改善予定日確定ボタン押下時の処理
export function collBtn12023No0(data) {
  return onClickFID12023(data);
}
// 【巡回レポート】改善予定日確定ボタン押下時の処理
function onClickFID12023(data) {
  if (!data || !data.Recodes) {
    return false;
  }
  //最後の改善予定日が入力されていない場合はエラーアラートを表示
  if (!isInputedPlannedImprovementDate(data)) {
    alert("改善予定日が入力されていません。");
    return false;
  }
  // 改善予定日が当日、または前回GM確認日より1ヶ月を超えているか確認
  if (!isValidDayAfter(data)) {
    return false
  }
  if (!confirm("改善予定日を確定しますか？")) {
    return false;
  }
  // 「改善予定日確定」ボタンに日時を保持して処理を終える
  const now = getDateTime();
  data.Recodes.FID12023.value = now;
  return true;
}

// 【巡回レポート】「改善完了報告」ボタン押下可能・不可の判定を返す true: 押下不可 false: 押下可能
export function disabledFID11988(data) {
  if (!data || !data.Recodes) {
    return true;
  }
  // 「改善予定日確定」ボタンに値がない場合は押下不可
  if (!data.Recodes.FID12023 || !data.Recodes.FID12023.value) {
    return true;
  }
  // 「改善完了報告」ボタンに値がある場合は押下不可
  if (data.Recodes.FID11988 && data.Recodes.FID11988.value) {
    return true;
  }
  // 「GM確認」ボタンに値がある場合は押下不可
  if (data.Recodes.FID11985 && data.Recodes.FID11985.value) {
    return true;
  }
  // 「今回」がすべて「〇」「該当なし」の場合は押下不可
  if (isAllCheckNowOk(data)) {
    return true;
  }
  // 指導内容(サブレポート)がない場合は押下不可
  if (!data.Recodes.FID11984 || !data.Recodes.FID11984.subReports || !data.Recodes.FID11984.subReports.length) {
    return true;
  }
  const lastSubReport = data.Recodes.FID11984.subReports[data.Recodes.FID11984.subReports.length - 1];
  // 指導内容の最後の要素が不正な値の場合は押下不可
  if (!lastSubReport.Recodes) {
    return true;
  }
  // 指導内容の最後の要素の「改善予定日」の値がない場合は押下不可
  if (!lastSubReport.Recodes.FID11981 || !lastSubReport.Recodes.FID11981.value) {
    return true;
  }
  // 指導内容の最後の要素の「改善完了報告」の値がある場合は押下不可
  if (!lastSubReport.Recodes.FID11982 || lastSubReport.Recodes.FID11982.value) {
    return true;
  }
  // 指導内容の最後の要素の「GM確認日時」の値がある場合は押下不可
  if (!lastSubReport.Recodes.FID11983 || lastSubReport.Recodes.FID11983.value) {
    return true;
  }
  return false;
}

// 【巡回レポート】「改善完了報告」ボタン押下時の処理
export function collBtn11988No0(data) {
  return onClickFID11988(data);
}
// 【巡回レポート】「改善完了報告」ボタン押下時の処理
function onClickFID11988(data) {
  if (!data || !data.Recodes) {
    return false;
  }
  // 「改善確認」が「×」のものをブランクにする
  patrolReport.checkFIDs.forEach((fid) => {
    if (data.Recodes[fid.improvement] && data.Recodes[fid.improvement].value == patrolReport.checkValue.ng) {
      data.Recodes[fid.improvement].value = "";
    }
  });
  // 「改善完了報告」ボタンに日時を保持して処理を終える
  const now = getDateTime();
  data.Recodes.FID11988.value = now;
  return true;
}

// 【巡回レポート】「GM確認」ボタン押下可能・不可の判定を返す true: 押下不可 false: 押下可能
export function disabledFID11985(data) {
  if (!data || !data.Recodes) {
    return true;
  }
  // GM確認ボタンに値がある場合は押下不可
  if (data.Recodes.FID11985 && data.Recodes.FID11985.value) {
    return true;
  }
  // 全ての「今回」が「〇」「該当なし」の場合は押下可能
  if (isAllCheckNowOk(data)) {
    return false;
  }
  // 改善完了報告ボタンに値がある場合は押下可能
  if (data.Recodes.FID11988 && data.Recodes.FID11988.value) {
    return false;
  }
  return true;
  // // 指導内容(サブレポート)がない場合は押下不可
  // if (!data.Recodes.FID11984 || !data.Recodes.FID11984.subReports || !data.Recodes.FID11984.subReports.length) {
  //   return true;
  // }
  // const lastSubReport = data.Recodes.FID11984.subReports[data.Recodes.FID11984.subReports.length - 1];
  // // 指導内容の最後の要素が不正な値の場合は押下不可
  // if (!lastSubReport.Recodes) {
  //   return true;
  // }
  // // 指導内容の最後の要素の「改善予定日」の値がない場合は押下不可
  // if (!lastSubReport.Recodes.FID11981 || !lastSubReport.Recodes.FID11981.value) {
  //   return true;
  // }
  // // 指導内容の最後の要素の「改善完了報告」の値がない場合は押下不可
  // if (!lastSubReport.Recodes.FID11982 || !lastSubReport.Recodes.FID11982.value) {
  //   return true;
  // }
  // // 指導内容の最後の要素の「GM確認日時」の値がある場合は押下不可
  // if (!lastSubReport.Recodes.FID11983 || lastSubReport.Recodes.FID11983.value) {
  //   return true;
  // }
}
// 【巡回レポート】「GM確認」ボタン押下時の処理
export function collBtn11985No0(data) {
  return onClickFID11985(data);
}
// 【巡回レポート】「GM確認」ボタン押下時の処理
function onClickFID11985(data) {
  if (!data || !data.Recodes) {
    return false;
  }
  //
  if (!isAllCheckInputed(data)) {
    alert("「今回」、または「改善確認」で未入力の項目があります。");
    return false;
  }
  // 全ての確認項目が確認済みの場合
  if (isAllCheckOk(data)) {
    if (!data.Recodes.FID11775 || !data.Recodes.FID11775.value) {
      alert("対応者（面談者）が未入力です。");
      return false;
    }
    if (!confirm("GM確認を完了しますか？")) {
      return false;
    }
    // 「GM確認ボタン」と「指導（改善）完了日」に日時を保持して処理を終える
    data.Recodes.FID11985.value = getDateTime();
    data.Recodes.FID11774.value = getDate();
    return true;
  }
  if (!confirm("「改善確認」が × の項目がありますが、GM確認を完了しますか？")) {
    return false;
  }
  // 「×」の「改善確認」がある場合、「×」の確認項目の改善確認回数をカウントアップ
  patrolReport.checkFIDs.forEach(fid => {
    if (!data.Recodes[fid.improvement]) {
      return;
    }
    if (data.Recodes[fid.improvement].value == patrolReport.checkValue.ng) {
      if (data.Recodes[fid.count]) {
        let count = parseInt(data.Recodes[fid.count].value) || 0;
        count++;
        data.Recodes[fid.count].value = count.toString();
      }
    }
  });
  return true;
}

// 【巡回レポート】全ての確認項目が入力済みか判定 true: 入力済 false: 未入力有
export function isAllCheckInputed(data) {
  if (!data || !data.Recodes) {
    return false;
  }
  return patrolReport.checkFIDs.every((fid) => {
    // 「今回」が「〇」「該当なし」の場合
    if (data.Recodes[fid.now] && (data.Recodes[fid.now].value == patrolReport.checkValue.ok || data.Recodes[fid.now].value == patrolReport.checkValue.none)) {
      return true;
    // 「今回」が「〇」「該当なし」ではなく、「改善確認」が未入力の場合
    } else if (!data.Recodes[fid.improvement] || !data.Recodes[fid.improvement].value) {
      return false;
    }
    return true;
  });
}

// 【巡回レポート】全ての確認項目がOKか判定 true: OK false: NG
export function isAllCheckOk(data) {
  if (!data || !data.Recodes) {
    return false;
  }
  return patrolReport.checkFIDs.every((fid) => {
    // 「今回」が「〇」「該当なし」の場合
    if (data.Recodes[fid.now] && (data.Recodes[fid.now].value == patrolReport.checkValue.ok || data.Recodes[fid.now].value == patrolReport.checkValue.none)) {
      return true;
    // 「今回」が「〇」「該当なし」ではなく、「改善確認」が「〇」の場合
    } else if (data.Recodes[fid.improvement] && data.Recodes[fid.improvement].value == patrolReport.checkValue.ok) {
      return true;
    }
    return false;
  });
}

// 【巡回レポート】全ての「今回」が「〇」、または「該当なし」か判定 true: 確認済み false: 未確認
export function isAllCheckNowOk(data) {
  if (!data || !data.Recodes) {
    return false;
  }
  return patrolReport.checkFIDs.every((fid) => {
    // 「今回」が「〇」、または「該当なし」の場合
    return data.Recodes[fid.now] &&
      (data.Recodes[fid.now].value == patrolReport.checkValue.ok || data.Recodes[fid.now].value == patrolReport.checkValue.none);
  });
}

// 【巡回レポート】全ての「今回」の中で×があるか判定 true: ×有 false: ×無
export function isNotAllCheckNowOK(data) {
  if (!data || !data.Recodes) {
    return false;
  }
  return patrolReport.checkFIDs.some((fid) => {
    // 「今回」が「×」の場合
    return data.Recodes[fid.now] && data.Recodes[fid.now].value == patrolReport.checkValue.ng;
  });
}

// 【巡回レポート】全ての「今回」が入力されているか判定 true: 入力済 false: 未入力有
export function isAllInputedNow(data) {
  if (!data || !data.Recodes) {
    return false;
  }
  return patrolReport.checkFIDs.every((fid) => {
    // 「今回」が「〇」「×」「該当なし」の場合
    return data.Recodes[fid.now] &&
      (
        data.Recodes[fid.now].value == patrolReport.checkValue.ok ||
        data.Recodes[fid.now].value == patrolReport.checkValue.ng ||
        data.Recodes[fid.now].value == patrolReport.checkValue.none
      );
  });
}

// 【巡回レポート】指導内容の最後の要素の改善予定日が入力されているか判定 true: 入力済 false: 未入力
export function isInputedPlannedImprovementDate(data) {
  if (!data || !data.Recodes) {
    return false;
  }
  // 指導内容(サブレポート)がない場合
  if (!data.Recodes.FID11984 || !data.Recodes.FID11984.subReports || !data.Recodes.FID11984.subReports.length) {
    return false;
  }
  const lastSubReport = data.Recodes.FID11984.subReports[data.Recodes.FID11984.subReports.length - 1];
  // 指導内容の最後の要素が不正な値の場合
  if (!lastSubReport.Recodes) {
    return false;
  }
  // 指導内容の最後の要素の「改善予定日」の値がない場合
  if (!lastSubReport.Recodes.FID11981 || !lastSubReport.Recodes.FID11981.value) {
    return false;
  }
  return true;
}

// 【巡回レポート】改善予定日の指定日が当日、または前回GM確認日より1ヶ月を超える場合、その旨をアラートで表示して、「キャンセル」や「OK」などを選択できるようにする
export function isValidDayAfter(data) {
  const lastSubReport = data.Recodes.FID11984.subReports[data.Recodes.FID11984.subReports.length - 1];
  const value = lastSubReport.Recodes.FID11981.value
  // 本日の日付を取得
  const today = new Date()
  // UTCに9時間足してJSTへ変換
  today.setHours(today.getHours() + 9);
  // // 本日の日付を文字列に変換 YYYY-MM-DD
  // const todayStr = today.toISOString().match(/^\d{4}-\d{2}-\d{2}/g).pop();
  // 基準となる日付を定義
  const lastGlConfirmedDate = lastSubReport.Recodes.FID12027 ? lastSubReport.Recodes.FID12027.value : "";
  let targetDate = lastGlConfirmedDate ? new Date(lastGlConfirmedDate) : today;
  // 基準から1ヶ月後の日付を文字列で取得 YYYY-MM-DD
  const nextMonthStr = new Date(
    targetDate.getFullYear(),
    targetDate.getMonth(),
    targetDate.getDate() + 31,
    targetDate.getHours(),
  )
    .toISOString()
    .match(/^\d{4}-\d{2}-\d{2}/g)
    .pop();
  // if (value < todayStr) {
  //   return "本日以降のみ指定可能";
  // }
  if (value > nextMonthStr) {
    let message = "";
    if (lastGlConfirmedDate) {
      message = "前回GM確認日(" + targetDate.toISOString().match(/^\d{4}-\d{2}-\d{2}/g).pop() + ")";
    } else {
      message = "本日";
    }
    if (!confirm("改善予定日が" + message + "より1ヶ月(31日)を超えていますがよろしいですか？")) {
      return false;
    }
  }
  return true;
}

// 【巡回レポート】ステータスの設定
export function patrolReportStatus(report) {
  const result = { value: "一時保存", color: "amber", textColor: "", search: "一時保存"};
  if (!report || !report.Recodes) {
    return result;
  }
  if (
    typeof report.Recodes.FID11985 != "undefined" &&
    report.Recodes.FID11985.value != "" &&
    report.Recodes.FID11985.value != null
  ) {
    result.value = "完了";
    result.color = "grey";
    result.search = "完了";
    return result;
  }
  if (
    typeof report.Recodes.FID11988 != "undefined" &&
    report.Recodes.FID11988.value != "" &&
    report.Recodes.FID11988.value != null
  ) {
    result.value = "GM確認待";
    result.color = "deep-orange";
    result.search = "GM確認待";
    return result;
  }
  if (
    typeof report.Recodes.FID12023 != "undefined" &&
    report.Recodes.FID12023.value != "" &&
    report.Recodes.FID12023.value != null
  ) {
    if (
      report.Recodes.FID11984 &&
      report.Recodes.FID11984.subReports &&
      report.Recodes.FID11984.subReports.length
    ) {
      const lastSub = report.Recodes.FID11984.subReports[report.Recodes.FID11984.subReports.length - 1];
      if (
        lastSub.Recodes.FID11981 &&
        lastSub.Recodes.FID11981.value
      ) {
        const todayStr = getDate();
        const today7Str = getDate(7);
        if (lastSub.Recodes.FID11981.value < todayStr) {
          result.value = "期限切れ";
          result.color = "red";
          result.search = "期限切れ";
          return result;
        } else if (lastSub.Recodes.FID11981.value <= today7Str) {
          //指導中（黄色）
          result.value = "指導中";
          result.color = "yellow";
          result.search = "指導中(1週間前)";
          return result;
        }
      }
      //指導中（緑）
      result.value = "指導中";
      result.color = "green";
      result.search = "指導中";
      return result;
    }
  }
  if (
    typeof report.Recodes.FID12204 != "undefined" &&
    report.Recodes.FID12204.value != "" &&
    report.Recodes.FID12204.value != null
  ) {
    result.value = "改善予定日未確定";
    result.color = "red";
    result.search = "改善予定日未確定";
    return result;
  }
  return result;
}

//現金過不足計算
export function cashdifference(_, Recodes) {
  if (Recodes.FID11671.value == "0") {
    return "－";
  }
  return "有";
}

export function getDate(num = 0) {
  const date = new Date();
  date.setDate(date.getDate() + num)
  const years = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const days = date.getDate().toString().padStart(2, "0");
  const value = years + "-" + month + "-" + days;
  return value;
}

export function getDateTime() {
  let date = new Date();
  let years = date.getFullYear().toString();
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let days = date.getDate().toString().padStart(2, "0");
  let hours = date.getHours().toString().padStart(2, "0");
  let minutes = date.getMinutes().toString().padStart(2, "0");
  let seconds = date.getSeconds().toString().padStart(2, "0");
  let value =
    years +
    "-" +
    month +
    "-" +
    days +
    " " +
    hours +
    ":" +
    minutes +
    ":" +
    seconds;
  return value;
}

export const getMasterData = {
  // 既存の商品マスタデータを取得
  async productReports(search = []) {
    const PageID = 1676;
    const data = {
      PageID: PageID,
      orderBy: "",
      search,
    };
    const reports = await api.ReportSearch(data);
    if (reports != null) {
      // PageIDを設定, FID0を削除
      reports.forEach((report) => {
        report.PageID = PageID;
        if (report.Recodes.FID0) {
          delete report.Recodes.FID0;
        }
      });
      return reports;
    }
    return [];
  },
  // 既存の在庫マスタデータを取得
  async stockReports(ssCode, date) {
    const PageID = 1665;
    const data = {
      PageID: PageID,
      orderBy: "",
      search: [],
    };
    if (date) {
      data.search.push({
        form_id: 11488,
        value: date,
        option: "match",
      });
    }
    if (ssCode) {
      data.search.push({
        form_id: 11486,
        value: ssCode,
        option: "match",
      });
    }
    const reports = await api.ReportSearch(data);
    if (reports != null) {
      // PageIDを設定, FID0を削除
      reports.forEach((report) => {
        report.PageID = PageID;
        if (report.Recodes.FID0) {
          delete report.Recodes.FID0;
        }
      });
      return reports;
    }
    return [];
  },
}

// メールアドレスのバリデーション
export function validMail(mail) {
  if (!mail) return true;
  const reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9._%+-]+\.[a-zA-Z]{2,}$/;
  return reg.test(mail) || "メールアドレスの形式が正しくありません";
}

export function spInputColor(data, field, disabled) {
  if (field.disabled || disabled) return "white"
  if ((
      // 完了していないかどうか
      data.Recodes.FID11772.value == "" ||
      data.Recodes.FID11772.value == null
    ) && (
      field.value == "" ||
      field.value == null
    )
  ) {
    return '#FFF8E1';
  }
  return 'white';
}
export function makePatrolReportCSV(reports) {
  let header =
    "キー,巡回日,巡回開始時刻,巡回終了時刻,SSコード,担当GM,グループ,GM（巡回者）,SS名,MG,対応者名（面談者名）,1.テキスト,1.今回,1.改善確認,2.テキスト,2.今回,2.改善確認,3.テキスト,3.今回,3.改善確認,4.テキスト,4.今回,4.改善確認,5.①現金差異,5.①現金差異（円）,5.①今回,5.①改善確認,5.②釣銭機ナンバー,5.②釣銭機差異,5.②釣銭機差異（円）,5.②今回,5.②改善確認,5.③SSログ（当月累計回数）,5.③管理者ログ1（詳細）,5.③SSログ（詳細）,5.③今回,5.③改善確認,5.④先売上,5.④商品名,5.④金額,5.④今回,5.④改善確認,5.④規定外保管物,5.④名称,5.④その他金券類,5.④受払表作成,5.④今回,5.④改善確認,5.⑤タイヤ差異,5.⑤差異個数,5.⑤簿外在庫,5.⑤今回,5.⑤改善確認,5.⑤バッテリー差異,5.⑤差異個数,5.⑤簿外在庫,5.⑤今回,5.⑤改善確認,5.⑥計量機内の点検（油漏れ）,5.⑥油水分離槽清掃状況,5.⑥マンホール内の滞水,5.⑥消火器の期限・破損,5.⑥今回,5.⑥改善確認,6.⑦分解整備料金表,6.⑦分解整備工具,6.⑦今回,6.⑦改善確認,6.⑧分解記録簿件数（件）,6.⑧分解記録簿件数（件）,6.⑧分解整備記録簿記載,6.⑧今回,6.⑧改善確認,6.⑨ステッカー番号記載,6.⑨自署,6.⑨シフト出勤状況,6.⑨今回,6.⑨改善確認,7.シート運用,7.不備項目,7.再発防止策の運用,7.今回,7.改善確認,指導（改善）内容とりまとめ,改善予定日,改善予定日確定,改善完了報告,GM確認,指導（改善）完了日,対応者名（面談者）,SSからの要望・提案,対応・処理予定日,ステータス,";
  let csv = "\ufeff" + header + "\n";
  reports.map((report) => {
    let line = "";
    line += '"' + getValue(report.Recodes.FID11841) + '",'; //キー
    line += '"' + getValue(report.Recodes.FID11758) + '",'; //巡回日
    // line += '"' + getValue(report.Recodes.FID11227) + '",'; //行動指針
    line += '"' + getValue(report.Recodes.FID11761) + '",'; //巡回開始時刻
    line += '"' + getValue(report.Recodes.FID11762) + '",'; //巡回終了時刻
    line += '"' + getValue(report.Recodes.FID11763) + '",'; //SSコード
    line += '"' + getValue(report.Recodes.FID11764) + '",'; //担当GM
    line += '"' + getValue(report.Recodes.FID11765) + '",'; //グループ
    line += '"' + getValue(report.Recodes.FID11766) + '",'; //GM（巡回者）
    line += '"' + getValue(report.Recodes.FID11767) + '",'; //SS名
    line += '"' + getValue(report.Recodes.FID11768) + '",'; //MG
    line += '"' + getValue(report.Recodes.FID11770) + '",'; //対応者名（面談者名）
    line += '"' + getValue(report.Recodes.FID11787) + '",'; //1.テキスト
    line += '"' + getValue(report.Recodes.FID11788) + '",'; //1.今回
    line += '"' + getValue(report.Recodes.FID11789) + '",'; //1.改善確認
    line += '"' + getValue(report.Recodes.FID11792) + '",'; //2.テキスト
    line += '"' + getValue(report.Recodes.FID11793) + '",'; //2.今回
    line += '"' + getValue(report.Recodes.FID11794) + '",'; //2.改善確認
    line += '"' + getValue(report.Recodes.FID11797) + '",'; //3.テキスト
    line += '"' + getValue(report.Recodes.FID11798) + '",'; //3.今回
    line += '"' + getValue(report.Recodes.FID11799) + '",'; //3.改善確認
    line += '"' + getValue(report.Recodes.FID11802) + '",'; //4.テキスト
    line += '"' + getValue(report.Recodes.FID11803) + '",'; //4.今回
    line += '"' + getValue(report.Recodes.FID11804) + '",'; //4.改善確認
    line += '"' + getValue(report.Recodes.FID12032) + '",'; //5.①現金差異
    line += '"' + getValue(report.Recodes.FID12033) + '",'; //5.①現金差異（円）
    line += '"' + getValue(report.Recodes.FID12035) + '",'; //5.①今回
    line += '"' + getValue(report.Recodes.FID12037) + '",'; //5.①改善確認
    line += '"' + getValue(report.Recodes.FID12043) + '",'; //5.②釣銭機ナンバー
    line += '"' + getValue(report.Recodes.FID12045) + '",'; //5.②釣銭機差異
    line += '"' + getValue(report.Recodes.FID12042) + '",'; //5.②釣銭機差異（円）
    line += '"' + getValue(report.Recodes.FID12047) + '",'; //5.②今回
    line += '"' + getValue(report.Recodes.FID12049) + '",'; //5.②改善確認
    line += '"' + getValue(report.Recodes.FID12055) + '",'; //5.③SSログ（当月累計回数）
    line += '"' + getValue(report.Recodes.FID12058) + '",'; //5.③管理者ログ1（詳細）
    line += '"' + getValue(report.Recodes.FID12061) + '",'; //5.③SSログ（詳細）
    line += '"' + getValue(report.Recodes.FID12063) + '",'; //5.③今回
    line += '"' + getValue(report.Recodes.FID12065) + '",'; //5.③改善確認
    line += '"' + getValue(report.Recodes.FID12070) + '",'; //5.④先売上
    line += '"' + getValue(report.Recodes.FID12075) + '",'; //5.④商品名
    line += '"' + getValue(report.Recodes.FID12076) + '",'; //5.④金額
    line += '"' + getValue(report.Recodes.FID12072) + '",'; //5.④今回
    line += '"' + getValue(report.Recodes.FID12074) + '",'; //5.④改善確認
    line += '"' + getValue(report.Recodes.FID12079) + '",'; //5.④規定外保管物
    line += '"' + getValue(report.Recodes.FID12080) + '",'; //5.④名称
    line += '"' + getValue(report.Recodes.FID12082) + '",'; //5.④その他金券類
    line += '"' + getValue(report.Recodes.FID12084) + '",'; //5.④受払表作成
    line += '"' + getValue(report.Recodes.FID12086) + '",'; //5.④今回
    line += '"' + getValue(report.Recodes.FID12088) + '",'; //5.④改善確認
    line += '"' + getValue(report.Recodes.FID12094) + '",'; //5.⑤タイヤ差異
    line += '"' + getValue(report.Recodes.FID12095) + '",'; //5.⑤差異個数
    line += '"' + getValue(report.Recodes.FID12097) + '",'; //5.⑤簿外在庫
    line += '"' + getValue(report.Recodes.FID12099) + '",'; //5.⑤今回
    line += '"' + getValue(report.Recodes.FID12101) + '",'; //5.⑤改善確認
    line += '"' + getValue(report.Recodes.FID12108) + '",'; //5.⑤バッテリー差異
    line += '"' + getValue(report.Recodes.FID12109) + '",'; //5.⑤差異個数
    line += '"' + getValue(report.Recodes.FID12111) + '",'; //5.⑤簿外在庫
    line += '"' + getValue(report.Recodes.FID12113) + '",'; //5.⑤今回
    line += '"' + getValue(report.Recodes.FID12115) + '",'; //5.⑤改善確認
    line += '"' + getValue(report.Recodes.FID12120) + '",'; //5.⑥計量機内の点検（油漏れ）
    line += '"' + getValue(report.Recodes.FID12122) + '",'; //5.⑥油水分離槽清掃状況
    line += '"' + getValue(report.Recodes.FID12124) + '",'; //5.⑥マンホール内の滞水
    line += '"' + getValue(report.Recodes.FID12126) + '",'; //5.⑥消火器の期限・破損
    line += '"' + getValue(report.Recodes.FID12128) + '",'; //5.⑥今回
    line += '"' + getValue(report.Recodes.FID12130) + '",'; //5.⑥改善確認
    line += '"' + getValue(report.Recodes.FID12136) + '",'; //6.⑦分解整備料金表
    line += '"' + getValue(report.Recodes.FID12138) + '",'; //6.⑦分解整備工具
    line += '"' + getValue(report.Recodes.FID12140) + '",'; //6.⑦今回
    line += '"' + getValue(report.Recodes.FID12142) + '",'; //6.⑦改善確認
    line += '"' + getValue(report.Recodes.FID12148) + '",'; //6.⑧分解記録簿件数（件）
    line += '"' + getValue(report.Recodes.FID12150) + '",'; //6.⑧分解記録簿件数（件）
    line += '"' + getValue(report.Recodes.FID12152) + '",'; //6.⑧分解整備記録簿記載
    line += '"' + getValue(report.Recodes.FID12154) + '",'; //6.⑧今回
    line += '"' + getValue(report.Recodes.FID12156) + '",'; //6.⑧改善確認
    line += '"' + getValue(report.Recodes.FID12161) + '",'; //6.⑨ステッカー番号記載
    line += '"' + getValue(report.Recodes.FID12163) + '",'; //6.⑨自署
    line += '"' + getValue(report.Recodes.FID12165) + '",'; //6.⑨シフト出勤状況
    line += '"' + getValue(report.Recodes.FID12167) + '",'; //6.⑨今回
    line += '"' + getValue(report.Recodes.FID12169) + '",'; //6.⑨改善確認
    line += '"' + getValue(report.Recodes.FID12175) + '",'; //7.シート運用
    line += '"' + getValue(report.Recodes.FID12177) + '",'; //7.不備項目
    line += '"' + getValue(report.Recodes.FID12179) + '",'; //7.再発防止策の運用
    line += '"' + getValue(report.Recodes.FID12181) + '",'; //7.今回
    line += '"' + getValue(report.Recodes.FID12183) + '",'; //7.改善確認
    if (report.Recodes.FID11984.subReports != null) {
      const length = report.Recodes.FID11984.subReports.length;
      line += '"' + getValue(report.Recodes.FID11984.subReports[length - 1].Recodes.FID11980) + '",'; //指導（改善）内容とりまとめ
      line += '"' + getValue(report.Recodes.FID11984.subReports[length - 1].Recodes.FID11981) + '",'; //改善予定日
    } else {
      line += ','; //指導（改善）内容とりまとめ
      line += ','; //改善予定日
    }
    line += '"' + getValue(report.Recodes.FID12023) + '",'; //改善予定日確定
    line += '"' + getValue(report.Recodes.FID11988) + '",'; //改善完了報告
    line += '"' + getValue(report.Recodes.FID11985) + '",'; //GM確認
    line += '"' + getValue(report.Recodes.FID11774) + '",'; //指導（改善）完了日
    line += '"' + getValue(report.Recodes.FID11775) + '",'; //対応者名（面談者）
    line += '"' + getValue(report.Recodes.FID11910) + '",'; //テキスト
    line += '"' + getValue(report.Recodes.FID11927) + '",'; //対応・処理予定日FID11874
    line += '"' + patrolReportStatus(report).value + '",'; //ステータス
    line += "\n";
    csv += line;
  });
  return csv;
}
export function makeMoneyCheckCSV(reports) {
  let header =
    "キー,日付,SSコード,SS名,責任者名,点検時刻,※GM（巡回者）参照,レジ合計,金庫合計,夜間金庫合計,aレジ合計,b金庫合計,c夜間金庫合計,BNA内,釣銭機内,セルフ洗浄機釣銭,POS現金残高,現金過不足,釣銭取り忘れ,登録,";
  let csv = "\ufeff" + header + "\n";
  reports.map((report) => {
    let line = "";
    line += getValue(report.Recodes.FID11842) + ","; //キー
    line += getValue(report.Recodes.FID11340) + ","; //日付
    line += getValue(report.Recodes.FID11341) + ","; //SSコード
    line += getValue(report.Recodes.FID11342) + ","; //SS名
    line += getValue(report.Recodes.FID11343) + ","; //責任者名
    line += getValue(report.Recodes.FID11346) + ","; //点検時刻
    line += getValue(report.Recodes.FID11349) + ","; //※GM（巡回者）参照
    line += getValue(report.Recodes.FID11483) + ","; //レジ合計
    line += getValue(report.Recodes.FID11521) + ","; //金庫合計
    line += getValue(report.Recodes.FID11538) + ","; //夜間金庫合計
    line += getValue(report.Recodes.FID11541) + ","; //aレジ合計
    line += getValue(report.Recodes.FID11657) + ","; //b金庫合計
    line += getValue(report.Recodes.FID11659) + ","; //c夜間金庫合計
    line += getValue(report.Recodes.FID11661) + ","; //BNA内
    line += getValue(report.Recodes.FID11663) + ","; //釣銭機内
    line += getValue(report.Recodes.FID11665) + ","; //セルフ洗浄機釣銭
    line += getValue(report.Recodes.FID11668) + ","; //POS現金残高
    line += getValue(report.Recodes.FID11671) + ","; //現金過不足
    line += getValue(report.Recodes.FID11674) + ","; //釣銭取り忘れ
    line += getValue(report.Recodes.FID11678) + ","; //登録
    line += "\n";
    csv += line;
  });
  return csv;
}
export function makeStockCheckCSV(reports, products) {
  let header =
    "キー,実施日,SSコード,直営事業部名,SS名,確認者名,対応者名,登録,分類コード,商品コード,商品名,前月繰越,入荷累計,出荷累計,現時点在庫,実在庫,差異,判定,入荷,出荷,備考,";
  let csv = "\ufeff" + header + "\n";
  reports.map((report) => {
    if (report.Recodes.FID11753.subReports != null) {
      report.Recodes.FID11753.subReports.map((sub) => {
        const found = products.find((elem) => elem.code == getValue(sub.Recodes.FID11739))
        const class_code = found ? found.class_code + "00" : ""
        let line = "";
        line += getValue(report.Recodes.FID11843) + ","; //キー
        line += getValue(report.Recodes.FID11706) + ","; //実施日
        line += getValue(report.Recodes.FID11707) + ","; //SSコード
        line += getValue(report.Recodes.FID11708) + ","; //直営事業部名
        line += getValue(report.Recodes.FID11709) + ","; //SS名
        line += getValue(report.Recodes.FID11710) + ","; //確認者名
        line += getValue(report.Recodes.FID11711) + ","; //対応者名
        line += getValue(report.Recodes.FID11756) + ","; //登録
        line += class_code + ","; //分類コード
        line += getValue(sub.Recodes.FID11739) + ","; //商品コード
        line += getValue(sub.Recodes.FID11740) + ","; //商品名
        line += getValue(sub.Recodes.FID11747) + ","; //前月繰越
        line += getValue(sub.Recodes.FID11748) + ","; //入荷累計
        line += getValue(sub.Recodes.FID11749) + ","; //出荷累計
        line += getValue(sub.Recodes.FID11741) + ","; //現時点在庫
        line += getValue(sub.Recodes.FID11742) + ","; //実在庫
        line += getValue(sub.Recodes.FID11750) + ","; //差異
        line += getValue(sub.Recodes.FID11743) + ","; //判定
        line += getValue(sub.Recodes.FID11744) + ","; //入荷
        line += getValue(sub.Recodes.FID11745) + ","; //出荷
        line += getValue(sub.Recodes.FID11746) + ","; //備考
        line += "\n";
        csv += line;
      });
    }
  });
  return csv;
}
export function getValue(v) {
  if (typeof v == "undefined") {
    return "";
  }
  return v.value.replace(/\r?\n/g,'');
}
