<template>
  <div class="body">
    <p align="center" class="title">ユーザ情報一覧</p>
    <v-row>
      <v-col align="left" class="button"
        ><v-btn @click="create">新規作成</v-btn></v-col
      >

      <v-col align="right" class="button">
        <v-btn @click.once="back">戻る</v-btn>
      </v-col>
    </v-row>
    <div v-if="isLoading" align="center">
      <v-progress-circular
        :size="250"
        color="primary"
        indeterminate
        align="center"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-form ref="form" v-model="valid">
        <v-row> </v-row>
        <v-col cols="">
          <v-data-table
            :headers="headers"
            :items="reports"
            :item-key="reports.ID"
            :page="$store.state.pageNum == undefined ? 1 : $store.state.pageNum"
            @update:page="updatePageNum"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            @click:row="move"
            :header-props="{
              'sort-by-text': '並び順',
            }"
            :footer-props="{
              'items-per-page-text': '行/ページ:',
            }"
            no-data-text="データが存在しません"
            :items-per-page="displayResults"
            @update:sort-by="updateSortBy"
            @update:sort-desc="updateSortDesc"
            :custom-sort="customSort"
          >
          </v-data-table>
        </v-col>
      </v-form>
    </div>
  </div>
</template>

<script>
import store from "../store/vuex.js";
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");

export default {
  name: "ReportIndex",
  components: {},
  data() {
    return {
      valid: true,
      initEscape: false,
      isLoading: false,
      fullPage: false,
      displayResults: 15, //5,10,15のいずれか
      height: 100,
      width: 250,
      path: "",
      sortBy: null,
      sortDesc: null,
      //
      PageID: 1654,
      reports: [],
      rules: {
        required: (value) => !!value || "必須項目です",
        requiredArray: (value) => (value.length > 0 ? true : "必須項目です"),
        fullWidth: (value) => {
          return /^[^ -~｡-ﾟ]*$/.test(value) || "全角のみ入力可能です";
        },
      },
    };
  },
  async created() {
    await this.getInit();
  },
  methods: {
    async getInit() {
      //タイトルをデフォルトに戻す
      if (typeof process.env.VUE_APP_BASE_TITLE != "undefined") {
        document.title = process.env.VUE_APP_BASE_TITLE;
      }
      //初期処理
      this.isLoading = true;
      //ユーザ情報の一覧取得
      const reports = await api.UserIndex(this.PageID);
      reports.forEach((report) => {
        if (report.Recodes.FID0.auth == "その他") {
          report.Recodes.FID0.auth = "グループ管理者";
        }
      });
      this.reports = reports;
      //
      this.isLoading = false;
    },
    getKeyWord(data) {
      if (data.PageID == this.PageID) {
        data.search.forEach((data) => {
          console.log(data);
        });
      }
    },
    getSearchWord() {
      let data = {
        PageID: this.PageID,
        orderBy: "",
        search: [],
      };

      return data;
    },
    async search() {
      if (this.initEscape == false) {
        this.initEscape = true;
        this.isLoading = false;
        await this.$nextTick();
      }
      const ck = await this.checkValidation();
      if (!ck && !this.isLoading) {
        return;
      }
      //初期処理
      this.isLoading = true;
      //データ追加
      let data = await this.getSearchWord();
      //api
      const reports = await api.ReportSearch(data);
      //SearchTag
      if (reports != null) {
        this.reports = reports;
        store.commit("setreports", reports);
      }
      store.commit("setkeyWord", data);
      this.isLoading = false;
    },

    create() {
      this.$router.push({ path: "/user/setting/" + "create" });
    },
    move(data) {
      this.isLoading = true;
      this.$router.push({ path: "/user/setting/" + data.Recodes.FID0.name });
      this.isLoading = false;
    },
    back() {
      store.commit("clearkeyWord");
      store.commit("clearreports");
      store.commit("clearPageNum");
      store.commit("clearTableSort");
      //
      history.back();
    },
    updatePageNum(value) {
      store.commit("setPageNum", value);
    },
    updateSortBy(value) {
      store.commit("setTableSortBy", value);
    },
    updateSortDesc(value) {
      store.commit("setTableSortDesc", value);
    },
    customSort(items, index, isDesc) {
      //デフォルト
      if (index.length == 0 || items.length == 0) {
        return items;
      }
      const regex = /[^0-9]/g;
      const order = isDesc[0] ? 1 : -1;
      const pos = parseInt(index[0].replace(regex, ""));
      items.sort((a, b) => {
        //アイコンソートは特殊処理
        if (index == "target") {
          return 0;
        }
        //
        return this.sort(a, b, order, pos);
      });
      //
      return items;
    },
    sort(a, b, order, pos) {
      if (a.Value[pos] == b.Value[pos]) return 0;
      if (a.Value[pos] === "") return 1;
      if (b.Value[pos] === "") return -1;
      if (!isNaN(a.Value[pos]) && !isNaN(b.Value[pos])) {
        return Number(a.Value[pos]) == Number(b.Value[pos])
          ? 0
          : Number(a.Value[pos]) > Number(b.Value[pos])
          ? -1 * order
          : order;
      }
      return a.Value[pos].localeCompare(b.Value[pos], "jp") * -1 * order;
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "社員番号",
          value: "Recodes.FID0.name",
          align: "start",
          sortable: true,
        },
        { text: "氏名", value: "Recodes.FID13317.value", sortable: true },
        //{ text: "メールアドレス", value: "Recodes.FID0.mail", sortable: false },
        { text: "権限", value: "Recodes.FID0.auth", sortable: false },
        {
          text: "事業部コード",
          value: "Recodes.FID12259.value",
          align: "start",
          sortable: true,
        },
        { text: "現在組織正式名称4", value: "Recodes.FID12260.value", sortable: true },
        { text: "現在組織正式名称", value: "Recodes.FID13318.value", sortable: true },
        { text: "SSコード", value: "Recodes.FID13319.value", sortable: true },
        { text: "役職", value: "Recodes.FID12261.value", sortable: true },
      ];
    },
  },
};
</script>
